import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {formatFEIN} from '../../../helpers';


import { history } from '../../../Routes'

import UploadFile from '../../UI/UploadFile/UploadFile'

import { 
  Typography,
  Dialog,
  DialogActions,
  DialogTitle, 
  DialogContent,
  DialogContentText, 
  Button,
  TextField,
  Divider,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '9px',
    marginRight: '20px',
    '& span': {
      color: '#fff !important'
    },
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  dialog_title: {
    '& h2': {
      fontSize: '20px',
      lineHeight: '30px',
    }
  },
  dialog_text: {
    '& span': {
      fontSize: '16px',
      lineHeight: '20px',
      color: theme.palette.black
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '20px',
      color: theme.palette.black
    }
  },
  dialog_button: {
    '& span': {
      fontSize: '16px',
      lineHeight: '20px'
    }
  },
  input: {
    display: 'none',
  },
  upload_processed_text: {
    fontSize: '20px'
  }
}));

const MerchantSubscriptionDialog = props => {
  const { 
    openDialog,
    onCloseDialog,
    accountId,
    onAddSingleMerchantSubscription,
    page,
    per_page,
    search
  } = props;
  const classes = useStyles();
  const [uploadComplete, setUploadComplete] = useState(false)
  const [fein, setFein] = useState('')
  const [singleSubscriptionHasError, setSingleSubscriptionHasError] = useState(false)
  const [singleSubscriptionErrorMessage, setSingleSubscriptionErrorMessage] = useState(null)
  
  const handleDialogClose = () => {
    setUploadComplete(false)
    onCloseDialog()
  };

  const handleChooseContinue = () => {
    handleDialogClose()
    history.push(`/merchant_subscription_imports`)
  };

  const handleFileUploadComplete = () => {
    setUploadComplete(true)
  };

  const handleInputChange = (event, field) => {
    switch(field){
      case 'fein':
        setFein(formatFEIN(event.target.value))
        break
      default:
        return null
    }
  };

  const onAddSingleMerchantSubscriptionClick = (event) => {
    event.preventDefault();

    if (fein.length != 10){
      setSingleSubscriptionErrorMessage("EIN must be 11 characters")
      setSingleSubscriptionHasError(true)
    } else {
      onAddSingleMerchantSubscription(fein, accountId, page, per_page, search)
    }
  }

  useEffect(() => {
   setFein('')
   setSingleSubscriptionErrorMessage(null)
   setSingleSubscriptionHasError(false)
  }, [openDialog])

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.dialog_title}
      >
        {"Add Merchant Subscriptions"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialog_text} component="div">
          <p><b>Add Multiple Subscriptions</b></p>
          <span>Upload a CSV file containing the EINs you would like to subscribe to.  The file should only contain EINs and must be in CSV format.</span>
          <UploadFile 
            accountId={accountId}
            uploadType="merchant_subscriptions"
            onUploadComplete={() => handleFileUploadComplete()}
            accept=".csv"
          />
          {uploadComplete && 
            <Typography variant="subtitle2" className={`upload-message`}>
              <p className={classes.upload_processed_text}>Your file has been uploaded and will now be processed!</p><br></br><p>You will be notified by email when your merchant subscriptions have been created.</p><br></br><p>You can check the status of your pending merchant subscriptions by clicking on the <b>Uploads</b> tab of the <b>Merchant Subscriptions</b> page.</p>
            </Typography>
          }
          <br></br>
          <hr></hr>
          <br></br>
          <p><b>Add Single Subscription</b></p>
          <Grid
            container
            spacing={3}
          >
            
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <TextField
                    error={singleSubscriptionHasError}
                    fullWidth
                    label="EIN"
                    margin="dense"
                    name="fein"
                    onChange={(event) => handleInputChange(event, 'fein')}
                    required
                    value={fein || ''}
                    variant="outlined"
                    helperText={singleSubscriptionErrorMessage}
                    inputProps={{
                      'maxLength': '10',
                      'minLength': '10',
                      'pattern':'.{10,10}'
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    // startIcon={<AddIcon />}
                    onClick={(event) => onAddSingleMerchantSubscriptionClick(event)}
                  >
                    Add Subscription
                  </Button>
                </Grid>
              
           
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {!uploadComplete &&
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        }
        {uploadComplete &&
          <Button
            onClick={handleChooseContinue}
            variant="contained"
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    openDialog: state.merchantSubscription.merchant_subscription_dialog_open
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseDialog: () => dispatch(actions.closeMerchantSubscriptionDialog()),
    onAddSingleMerchantSubscription: (fein, account_id, page, per_page, search) => dispatch(actions.addMerchantSubscription(fein, account_id, page, per_page, search))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSubscriptionDialog)
