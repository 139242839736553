import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { AccountForm } from '../../../components/Account';
import { Membership } from '../../../components/Account';
import { UserList } from '../../../components/User';
import { InvoicesList, PaymentMethod } from '../../../components/Account';
import {addToast} from '../../../store/actions/toasts' 

import { 
  CircularProgress,
  Backdrop,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography
} from '@material-ui/core';
import { TrendingUpSharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  backdrop: {
    zIndex: '100'
  },
  members: {
    // marginTop: '30px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AccountView = props => {
  const classes = useStyles();
  const [api_access, setApiAccess] = useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [value, setValue] = React.useState(0);

  const { 
    onFetchAccount,
    account,
    onAccountSave,
    onShowPaymentMethodUpdatedToast,
    onCancelMembership,
    token,
    current_user,
    id,
    is_dm_admin,
    plan_level,
    onUpgradeMembership,
    onDowngradeMembership
  } = props
  
  useEffect(() => {
    onFetchAccount(token, id);
    handleTabNav();
  }, [onFetchAccount, token, id])

  useEffect(() => {
    if(account != null){
      setApiAccess(account.can_access_api)
    }
  }, [account, api_access])

  const handleTabNav = () => {
    if (window.location.href.indexOf("#billing_updated") > -1) {
      setValue(3);
      onShowPaymentMethodUpdatedToast({
        message: 'Your payment method has been updated.',
        severity: 'success',
        duration: 10000
      })
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitClick = (event, account) => {
    event.preventDefault();
  
    const accountData = {
      account: {
        id: account.id,
        name: account.name,
        website: account.website,
        primaryContactEmail: account.primaryContactEmail,
        merchant_subscription_enabled: account.merchant_subscription_enabled,
        merchant_subscription_notification_email: account.merchant_subscription_notification_email,
        merchant_subscription_notification_webhook: account.merchant_subscription_notification_webhook
      }
    }
    onAccountSave(token, accountData, id)
  };

  const handleCancelMembershipClick = () => {
    setOpenDialog(true);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOk = () => {
    onCancelMembership(id, current_user)
    setOpenDialog(false);
  };

  const handleUpgradeMembershipClick = () => {
    onUpgradeMembership(id, true)
  }

  const handleDowngradeMembershipClick = () => {
    onDowngradeMembership(id, true)
  }

  

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving){
    spinner = null
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary">
            <Tab label="Settings" />
            <Tab label="Account Members" />
            <Tab label="Membership" />
            <Tab label="Billing" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AccountForm account={account} onSubmitClick={handleSubmitClick} account_view is_dm_admin={is_dm_admin} plan_level={plan_level} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserList className={classes.members} accountId={id} api_access={api_access} account_view />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Membership onCancelMembershipClick={handleCancelMembershipClick} account={account} account_view onUpgradeMembershipClick={handleUpgradeMembershipClick} onDowngradeMembershipClick={handleDowngradeMembershipClick} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PaymentMethod accountId={id} account_view />
          <InvoicesList className={classes.members} accountId={id} account_view />
        </TabPanel>
        {spinner}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancel Membership"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel membership?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleOk} variant="contained" color="primary" autoFocus>
            Yes, cancel membership
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    id: state.auth.user.account_resource_id,
    saving: state.account.saving,
    token: state.auth.token,
    account: state.account.account,
    current_user: state.auth.user,
    is_dm_admin: state.auth.user.is_dm_admin,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAccount: (token, id) => dispatch(actions.fetchAccount(token, id)),
    onAccountSave: (token, accountData, id) => dispatch(actions.updateAccount(token, accountData, id)),
    onCancelMembership: (id, current_user) => dispatch(actions.subscriptionCancel(id, current_user)),
    onShowPaymentMethodUpdatedToast: (options) => dispatch(actions.addToast(options)),
    onUpgradeMembership: (id, account_view) => dispatch(actions.membershipUpgrade(id, account_view)),
    onDowngradeMembership: (id, account_view) => dispatch(actions.membershipDowngrade(id, account_view))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AccountView, axios))