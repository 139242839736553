import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'
import {authHeaders} from '../../../helpers'

import { SimpleAccountsTable } from '../../Account';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const TopContributors = props => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true)
  const [funders, setFunders] = useState([])
  const [pagination, setPagination] = useState(0)
  const [page, setPage] = useState(0)
  const [per_page, setPerPage] = useState(10)
  
  useEffect(() => {
    setLoading(true)
    let url = `/widgets/top_contributors?page=${page + 1}&per_page=${per_page}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedFunders = []
        for(let key in response.data.data){
          fetchedFunders.push({
            ...response.data.data[key],
            id: key
          })
        }
        setLoading(false)
        setPagination(response.data.pagination)
        setFunders(fetchedFunders)
      }
      else {
        setLoading(false)
      }
    })
    .catch(error => {
      setLoading(false)
    })
    
    return () => {
      source.cancel()
    }
  }, [page, per_page])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value)
  }

  let fundersTable = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    fundersTable = (
      <SimpleAccountsTable
        accounts={funders}
        pagination={pagination}
        onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        onPerPageChange={handlePerPageChange}
      />
    )
  }
  
  return (
    <Card>
      <CardHeader
        title={`Top Contributors`}
      />
      <Divider />
      {fundersTable}
    </Card>
  );
};

export default withErrorHandler(TopContributors, axios)