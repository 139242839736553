import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders, getDeviceInfo} from '../../helpers'
import {addToast} from './toasts'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, user, redirect) => {
  localStorage.setItem('token', token)
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user: user,
    attemptedRoute: redirect
  }
  // return dispatch => dispatch({
  //   type: 'LOGIN_ATTEMPT',
  //   token: { token }
  // })
}


export const authMfaResetSuccess = (user) => {
  return {
    type: actionTypes.AUTH_RESET_MFA_SUCCESS,
    user: user
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}

export const startLogoutSaga = () => {
  return {
    type: actionTypes.LOGOUT_USER
  }
}

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

export const setToken = (token) => {
  return dispatch => dispatch(authSuccess(token))
}

export const auth = (authData) => {
  let ip, uag
  
  return dispatch => {
    dispatch(authStart())
    let url = 'auth/login'
    getDeviceInfo(ip, uag).then((res) => {
      authData.ip = res.ip
      authData.ua = res.uag

      console.log(authData)
      
      axios.post(url, authData)
      .then(({ data }) => {
        const token = data.data.jwt || ''
        const user = data.data.user || {
          account_resource_id: null,
          first_name: null,
          last_name: null,
          account_name: null,
          is_admin: false,
          is_dm_admin: false,
          account_merchant_subscription_enabled: false,
          plan_level: 'BASIC',
          account_merchant_subscription_notification_email: null,
          account_merchant_subscription_notification_webhook_url: null
        }
        dispatch(authSuccess(token, user))
        dispatch(addToast({
          message: 'You are now logged into DataMerch',
          severity: 'success'
        }))
      })
      .catch(error => {
        dispatch(authFail(error.response.data.errors))
        dispatch(addToast({
          message: 'error.response.data.errors',
          severity: 'error',
          duration: null
        }))
      })
    })
  }
}

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token')
    if (!token){
      dispatch(logout())
      dispatch(addToast({
        message: 'Please log back into DataMerch.',
        severity: 'error',
        duration: null
      })) 
    } else {
      dispatch(authSuccess(token))
      dispatch(addToast({
        message: 'You are now logged into DataMerch',
        severity: 'success'
      }))
    }
  }
}

export const autoLogin = (token, redirect) => {
  return dispatch => {
    dispatch(authStart())
    let url = 'auth/auto_login'
    let config = authHeaders()
    axios.get(url, config)
    .then(({ data }) => {
      const token = data.data.jwt || ''
      const user = data.data.user || {
        account_resource_id: null,
        first_name: null,
        last_name: null,
        account_name: null,
        is_admin: false,
        is_dm_admin: false,
        account_merchant_subscription_enabled: false,
        plan_level: 'BASIC',
        account_merchant_subscription_notification_email: null,
        account_merchant_subscription_notification_webhook_url: null
        
      }
      dispatch(authSuccess(token, user, redirect))
      dispatch(addToast({
        message: 'You are now logged into DataMerch',
        severity: 'success'
      }))
    })
    .catch(error => {
      dispatch(authFail(error.response.data.errors))
      dispatch(addToast({
        message: 'error.response.data.errors',
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const updateProfileStart = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_START
  }
}

export const updateProfileSuccess = (userData) => {
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    userData: userData
  }
}

export const updateProfileFail = (error) => {
  return {
    type: actionTypes.UPDATE_PROFILE_FAIL,
    error: error
  }
}

export const updateProfile = (token, userData, id) => {
  const modifiedUserData = {
    user: {
      first_name: userData.user.firstName,
      last_name: userData.user.lastName,
      email: userData.user.email
    }
  }
  
  return dispatch => {
    dispatch(updateProfileStart())
    let url = `users/${id}`
    let config = authHeaders()
    axios.put(url, modifiedUserData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateProfileSuccess(data))
        dispatch(addToast({
          message: 'Profile has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(updateProfileFail('An error occurred while attempting to update profile.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update profile.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateProfileFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const passwordResetStart = () => {
  return {
    type: actionTypes.PASSWORD_RESET_START
  }
}

export const passwordResetSuccess = (message) => {
  return {
    type: actionTypes.PASSWORD_RESET_SUCCESS,
    message: message
  }
}

export const passwordResetFail = (error) => {
  return {
    type: actionTypes.PASSWORD_RESET_FAIL,
    error: error
  }
}

export const passwordReset = (data) => {
  return dispatch => {
    dispatch(passwordResetStart())
    let url = `auth/password_reset`
    axios.post(url, data)
    .then(response => {
      if (response){
        const message = response.data.data.message || ''
        dispatch(passwordResetSuccess(message))
        dispatch(addToast({
          message: 'Password has been updated.',
          severity: 'success'
        }))
      } else {
        const message = response.data.errors || 'An error occurred while attempting to update password.'
        dispatch(passwordResetFail(message))
        dispatch(addToast({
          message: message,
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(passwordResetFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const forgotPasswordStart = () => {
  return {
    type: actionTypes.PASSWORD_RESET_START
  }
}

export const forgotPasswordSuccess = (message) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    message: message
  }
}

export const forgotPasswordFail = (error) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_FAIL,
    error: error
  }
}

export const forgotPassword = (data) => {
  return dispatch => {
    dispatch(forgotPasswordStart())
    let url = `auth/forgot_password`
    axios.post(url, data)
    .then(response => {
      if (response){
        const message = response.data.data.message || ''
        dispatch(forgotPasswordSuccess(message))
        dispatch(addToast({
          message: 'Your forgot password request has been submitted.',
          severity: 'success'
        }))
      } else {
        dispatch(forgotPasswordFail('An error occurred while attempting to process request.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to submit your forgot password request.  Please try again.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(forgotPasswordFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const agreeToTermsStart = () => {
  return {
    type: actionTypes.AGREE_TO_TERMS_START
  }
}

export const agreeToTermsSuccess = (user) => {
  return {
    type: actionTypes.AGREE_TO_TERMS_SUCCESS,
    user: user
  }
}

export const agreeToTermsFail = (error) => {
  return {
    type: actionTypes.AGREE_TO_TERMS_FAIL
  }
}

export const agreedToTerms = () => {
  return {
    type: actionTypes.AGREED_TO_TERMS
  }
}

export const agreeToTerms = (id) => {
  let url = `users/${id}/agree_to_terms`
  
  return dispatch => {
    dispatch(agreeToTermsStart())
    let config = authHeaders()
    axios.put(url, null, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(agreeToTermsSuccess(data))
        dispatch(agreedToTerms())
        dispatch(addToast({
          message: 'You have successfully agreed to the DataMerch terms of use.',
          severity: 'success'
        }))
      } else {
        dispatch(agreeToTermsFail('An error occurred while attempting to process request.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to process your request.  Please try again.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(agreeToTermsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const subscriptionCreateStart = () => {
  return {
    type: actionTypes.SUBSCRIPTION_CREATE_START
  }
}

export const subscriptionCreateSuccess = (user) => {
  return {
    type: actionTypes.SUBSCRIPTION_CREATE_SUCCESS,
    user: user
  }
}

export const subscriptionCreateFail = (error) => {
  return {
    type: actionTypes.SUBSCRIPTION_CREATE_FAIL,
    error: error
  }
}

export const subscriptionCreated = () => {
  return {
    type: actionTypes.SUBSCRIPTION_CREATED
  }
}

export const subscriptionCreate = (token, id, plan) => {
  const subscriptionData = {
    stripeToken: token.id,
    stripeEmail: token.email,
    stripePlanName: plan
  }

  return dispatch => {
    dispatch(subscriptionCreateStart())
    let url = `/accounts/${id}/subscription`
    let config = authHeaders()
    axios.post(url, subscriptionData, config)
    .then(response => {
      if (response){
        const data = response.data.data
      dispatch(subscriptionCreateSuccess(data))
      dispatch(subscriptionCreated())
      dispatch(addToast({
        message: 'Congratulations! Your subscription has been created.',
        severity: 'success'
      }))
      } else {
        dispatch(subscriptionCreateFail('An error occurred while attempting to create subscription.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to create subscription.  Please try again.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      console.log(error.response.status)
      dispatch(subscriptionCreateFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const subscriptionCancelStart = () => {
  return {
    type: actionTypes.SUBSCRIPTION_CANCEL_START
  }
}

export const subscriptionCancelSuccess = () => {
  return {
    type: actionTypes.SUBSCRIPTION_CANCEL_SUCCESS,
    user: {
      account_resource_id: null,
      first_name: null,
      last_name: null,
      account_name: null,
      is_admin: false,
      is_dm_admin: false
    }
  }
}

export const subscriptionCancelFail = (error) => {
  return {
    type: actionTypes.SUBSCRIPTION_CANCEL_FAIL,
    error: error
  }
}

export const subscriptionCanceled = () => {
  return {
    type: actionTypes.SUBSCRIPTION_CANCELED
  }
}

export const subscriptionCancel = (id, user) => {
  return dispatch => {
    dispatch(subscriptionCancelStart())
    let url = `/accounts/${id}/subscription/cancel`
    let config = authHeaders()
    axios.post(url, id, config)
    .then(response => {
      if (response){
        dispatch(subscriptionCancelSuccess())
        if (!user.is_dm_admin){
          dispatch(subscriptionCanceled())
        }
        dispatch(addToast({
          message: 'Subscription has been cancelled.',
          severity: 'success'
        }))
      } else {
        dispatch(subscriptionCancelFail('An error occurred while attempting to cancel membership.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to cancel subscription.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(subscriptionCancelFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchDashboardUpdatesSuccess = (user) => {
  return {
    type: actionTypes.FETCH_DASHBOARDUPDATES_SUCCESS,
    user: user
  }
}

export const fetchDashboardUpdatesFail = (error) => {
  return {
    type: actionTypes.FETCH_DASHBOARDUPDATES_FAIL,
    error: error
  }
  // return {
  //   type: actionTypes.LOGOUT_USER
  // }
}

export const fetchDashboardUpdatesStart = () => {
  return {
    type: actionTypes.FETCH_DASHBOARDUPDATES_START
  }
}

export const fetchDashboardUpdates = (user) => {
  const payload = {
    resource_id: user.resource_id
  }
  
  return dispatch => {
    dispatch(fetchDashboardUpdatesStart())
    let url = `/auth/dashboard_updates`
    let config = authHeaders()
    axios.post(url, payload, config)
    .then(({ data }) => {
      const user = data.data.user || {
        account_resource_id: null,
        first_name: null,
        last_name: null,
        account_name: null,
        is_admin: false,
        is_dm_admin: false
      }
      if (user) {
        dispatch(fetchDashboardUpdatesSuccess(user))
      } else {
        dispatch(fetchDashboardUpdatesFail('An error occurred while attempting to retrieve updates.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve updates.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      if (error) {
        console.log(error)
        dispatch(fetchDashboardUpdatesFail())
      }
      else {
        dispatch(fetchDashboardUpdatesFail())
      }
    })
  }
}




export const fetchOtpUrlSuccess = (url) => {
  return {
    type: actionTypes.FETCH_OTPURL_SUCCESS,
    url: url
  }
}

export const fetchOtpUrlFail = (error) => {
  return {
    type: actionTypes.FETCH_OTPURL_FAIL,
    error: error
  }
}

export const fetchOtpUrlStart = () => {
  return {
    type: actionTypes.FETCH_OTPURL_START
  }
}

export const fetchOtpUrl = () => { 
  return dispatch => {
    dispatch(fetchOtpUrlStart())
    let url = `/auth/otp/new`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        dispatch(fetchOtpUrlSuccess(response.data.data))
      }
      else {
        dispatch(fetchOtpUrlFail('Unable to get URL.'))
        // dispatch(addToast({
        //   message: 'An error occurred while attempting to retrieve merchants.',
        //   severity: 'error',
        //   duration: null
        // }))
      }
    })
    .catch(error => {
      dispatch(fetchOtpUrlFail(error.response.data.errors))
      // dispatch(addToast({
      //   message: error.response.data.errors,
      //   severity: 'error',
      //   duration: null
      // }))
    })
  }
}

export const newOtpStart = () => {
  return {
    type: actionTypes.NEW_OTP_START
  }
}

export const newOtpSuccess = () => {
  return {
    type: actionTypes.NEW_OTP_SUCCESS,
  }
}

export const newOtpFail = (error) => {
  return {
    type: actionTypes.NEW_OTP_FAIL,
    error: error
  }
}

export const newOtp = (token, otp_secret, data) => {
  return dispatch => {
    dispatch(newOtpStart())

    getDeviceInfo().then((res) => {
      const otpData = {
        otp: {
          otp_secret: otp_secret,
          code: data.code,
          ip: res.ip,
          ua: res.uag,
          remember_device: data.remember
        }
      }

      let url = `/auth/otp/`
      let config = authHeaders()
      axios.post(url, otpData, config)
      .then(response => {
        if (response){
          dispatch(newOtpSuccess())
          dispatch(verifyOtpResult())
          // dispatch(addToast({
          //   message: 'Success',
          //   severity: 'success',
          //   duration: null
          // }))
        } else {
          dispatch(newOtpFail())
          dispatch(addToast({
            message: 'Failed',
            severity: 'error',
            duration: 10
          }))
        }
      })
      .catch(error => {
        dispatch(newOtpFail())
        dispatch(addToast({
          message: 'Failed',
          severity: 'error',
          duration: 10
        }))
      })
    })
  }
}

export const verifyOtpStart = () => {
  return {
    type: actionTypes.VERIFY_OTP_START
  }
}

export const verifyOtpSuccess = (token, user, redirect) => {
  return {
    type: actionTypes.VERIFY_OTP_SUCCESS
  }
}

export const verifyOtpFail = () => {
  return {
    type: actionTypes.VERIFY_OTP_FAIL
  }
}

export const verifyOtpResult = () => {
  return {
    type: actionTypes.VERIFY_OTP_RESULT
  }
}

export const verifyOtp = (token, data) => {
  return dispatch => {
    dispatch(verifyOtpStart())

    getDeviceInfo().then((res) => {
      const otpData = {
        otp: {
          code: data.code,
          ip: res.ip,
          ua: res.uag,
          remember_device: data.remember
        }
      }

      let url = `/auth/otp/verify`
      let config = authHeaders()
      axios.post(url, otpData, config)
      .then(response => {
        if (response){
          // dispatch(verifyOtpSuccess()).then(() => {
          //   dispatch(verifyOtpResult())
          // })
          dispatch(verifyOtpSuccess())
          dispatch(verifyOtpResult())
          // dispatch(addToast({
          //   message: 'Success',
          //   severity: 'success',
          //   duration: null
          // }))
        } else {
          // dispatch(verifyOtpFail()).then(() => {
          //   dispatch(verifyOtpResult())
          // })
          dispatch(verifyOtpFail())
          // dispatch(verifyOtpResult())
          dispatch(addToast({
            message: 'Failed',
            severity: 'error',
            duration: 10000
          }))
        }
      })
      .catch(error => {
        // console.log(error)
        dispatch(addToast({
          message: 'Failed',
          severity: 'error',
          duration: 10000
        }))
        dispatch(verifyOtpFail())
      })
    })
  }
}
