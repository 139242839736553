import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { DeletedNotesTable } from '../../Note';
import { CircularProgress, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const DeletedNoteList = props => {
  const classes = useStyles();

  const { 
    onFetchNotes,
    onNoteRestore,
    onNoteArchive,
    onNoteAdd,
    onNoteSave,
    onNoteCancel,
    token,
    merchantId,
    className,
    loading,
    notes,
    ...rest
  } = props
  
  useEffect(() => {
    onFetchNotes(token, merchantId);
  }, [onFetchNotes, token, merchantId])

  const handleArchiveClick = (event, noteId) => {
    event.preventDefault();

    onNoteArchive(token, noteId)
  }

  const handleSubmitNoteClick = (event, note) => {
    event.preventDefault();

    let noteData = {
      note: {
        merchant_id: merchantId,
        category: note.category,
        note: note.note
      }
    }

    if (note.id){
      noteData = {
        note: {
          id: note.id,
          merchant_id: merchantId,
          category: note.category,
          note: note.note
        }
      }
    }

    if (note.id){
      onNoteSave(noteData, note.id)
    } else {
      onNoteAdd(noteData)
    }
  };

  const handleCancelNoteClick = (event) => {
    event.preventDefault();
    onNoteCancel()
  };

  let active_notes = <CircularProgress />
  if(!loading){
    active_notes = (
      <DeletedNotesTable
        notes={notes}
        onArchiveClick={(event, noteId) => handleArchiveClick(event, noteId)}
        merchant_id={merchantId}
        onSubmitNoteClick={(event, note) => handleSubmitNoteClick(event, note)}
        onCancelNoteClick={(event) => handleCancelNoteClick(event)}
      />
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Notes"
      />
      <Divider />
      {active_notes}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    notes: state.deletedNote.notes,
    loading: state.deletedNote.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchNotes: (token, merchantId) => dispatch(actions.fetchDeletedNotes(token, merchantId)),
    onNoteArchive: (token, noteId) => dispatch(actions.archiveNote(token, noteId)),
    onNoteAdd: (noteData) => dispatch(actions.addNote(noteData)),
    onNoteSave: (noteData, id) => dispatch(actions.updateNote(noteData, id)),
    onNoteCancel: () => dispatch(actions.closeNoteModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(DeletedNoteList, axios))