import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import {authHeaders} from '../../../helpers'

import { CircularProgress, Backdrop, Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const TotalMembers = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const [kpiValue, setKpiValue] = useState(0)
  
  useEffect(() => {
    setLoading(true)
    let url = `/widgets/total_members`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        setLoading(false)
        setKpiValue(response.data.data)
      }
      else {
        setLoading(false)
      }
    })
    .catch(error => {
      setLoading(false)
    })
    
    return () => {
      source.cancel()
    }
  }, [])

  let kpi = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    kpi = (
      <Card>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                TOTAL MEMBERS
              </Typography>
              <Typography variant="h3"><NumberFormat value={kpiValue.count-1} displayType={'text'} thousandSeparator={true} /></Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                BASIC/PRO
              </Typography>
              <Typography variant="h3">{`${kpiValue.basic} / ${kpiValue.pro-1}`}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <PeopleIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
  
  return (
    <React.Fragment>
      {kpi}
    </React.Fragment>
  );
};

export default withErrorHandler(TotalMembers, axios)