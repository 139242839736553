import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import InputIcon from '@material-ui/icons/Input';
import TuneIcon from '@material-ui/icons/Tune';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { SearchInput } from '../../../../components/';
import { Hidden, Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 250,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
    backgroundColor: theme.palette.blue
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.blue
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  searchInput: {
    flexBasis: 0,
    marginBottom: '15px'
  },
  button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    padding: '10px 5px'
  },
  logout: {
    marginTop: 'auto'
  },
  signOutButton: {
    color: theme.palette.white,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  signOutIcon: {
    color: theme.palette.white,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  noUnderline: {
    textDecoration: 'none'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, onMerchantSearchClick, onLogoutClick, is_admin, is_dm_admin, user, account_merchant_subscription_enabled } = props;

  const classes = useStyles();

  let pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Analytics',
      href: '/analytics',
      icon: <EqualizerIcon />
    },
    {
      title: 'Our Members',
      href: '/members',
      icon: <PeopleIcon />
    }
  ];
  
  if (is_admin) {
    pages = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <DashboardIcon />
      },
      {
        title: 'Analytics',
        href: '/analytics',
        icon: <EqualizerIcon />
      },
      {
        title: 'Our Members',
        href: '/members',
        icon: <PeopleIcon />
      }
    ]
  }

  let account_pages = [
    {
      title: 'Profile',
      href: '/profile',
      icon: <AccountBoxIcon />
    }
  ]

  if (is_admin && account_merchant_subscription_enabled && user.plan_level === 'PRO') {
    account_pages = [
      {
        title: 'Merchant Subscriptions',
        href: '/merchant_subscriptions',
        icon: <AlarmAddIcon />
      },
      {
        title: 'Profile',
        href: '/profile',
        icon: <AccountBoxIcon />
      },
      {
        title: 'Account',
        href: '/account',
        icon: <SettingsIcon />
      }
    ]
  }

  if (is_admin && (!account_merchant_subscription_enabled || user.plan_level != 'PRO')) {
    account_pages = [
      {
        title: 'Profile',
        href: '/profile',
        icon: <AccountBoxIcon />
      },
      {
        title: 'Account',
        href: '/account',
        icon: <SettingsIcon />
      }
    ]
  }

  let admin_pages = [];

  if (is_dm_admin) {
    admin_pages = [
      {
        title: 'Funders',
        href: '/funders',
        icon: <AttachMoneyIcon />
      },
      {
        title: 'Merchants',
        href: '/merchants',
        icon: <ShoppingBasketIcon />
      }
    ]
  }

  let dm_admin_pages = [];

  if (is_dm_admin) {
    dm_admin_pages = [
      {
        title: 'Settings',
        href: '/settings',
        icon: <TuneIcon />
      }
    ]
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        
        <SearchInput
          className={classes.searchInput}
          placeholder="Full EIN/legal name search"
          onSearchClick={onMerchantSearchClick}
          user={user}
        />
        <RouterLink to="/merchants/new" className={classes.noUnderline}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<AddIcon />}
            fullWidth={true}
          >
            Add Merchant
          </Button>
        </RouterLink>
        <Divider className={classes.divider} />
        
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        {props.is_admin ? 
          <SidebarNav
            className={classes.nav}
            pages={admin_pages}
          /> : null }
        <SidebarNav
          className={classes.nav}
          pages={account_pages}
        />
        <div className={classes.logout}>
          <Divider className={classes.divider} />
          {props.is_dm_admin ? 
          <SidebarNav
            className={classes.nav}
            pages={dm_admin_pages}
          /> : null }
          <Button
            className={classes.signOutButton}
            onClick={onLogoutClick}
          >
            <div className={classes.signOutIcon}><InputIcon /></div>
            Logout
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    is_admin: state.auth.user.is_admin,
    is_dm_admin: state.auth.user.is_dm_admin,
    account_merchant_subscription_enabled: state.auth.user.account_merchant_subscription_enabled,
    user: state.auth.user
  }
}

export default connect(mapStateToProps, null)(Sidebar)