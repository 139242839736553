import React, { useState } from 'react';
import {connect} from 'react-redux'
import moment from 'moment';

import * as actions from '../../../store/actions'

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  Typography
} from '@material-ui/core';

import { NoteDialog } from '../../Note';
import CategoryChip from '../../UI/CategoryChip'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  note_date: {
    fontWeight: 'bold',
    fontSize: '1.25em'
  },
  note_creator: {
    fontWeight: 'bold',
    fontSize: '1.25em'
  },
  note_copy: {
    paddingTop: '10px'
  }
}));

const DeletedNotesTable = props => {
  const { 
    notes,
    onArchiveClick,
    merchant_id,
    onSubmitNoteClick,
    onCancelNoteClick,
    modalOpen,
    onOpenNoteModal,
    current_user
  } = props;

  const classes = useStyles();

  const [note, setNote] = useState({
    id: null,
    merchant_id: merchant_id,
    category: '',
    note: ''
  })

  const [noteMode, setNoteMode] = useState('add')
  
  const handleAddNoteClick = () => {
    setNote({
      id: null,
      merchant_id: merchant_id,
      category: '',
      note: ''
    })
    setNoteMode('add')
    onOpenNoteModal()
  }

  const handleEditNoteClick = (event, note) => {
    setNote({
      id: note.resource_id,
      merchant_id: merchant_id,
      category: note.category,
      note: note.note
    })
    setNoteMode('edit')
    onOpenNoteModal()
  }

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableBody>
                {notes.map(note => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={note.id}
                  >
                    <TableCell colSpan={2}>
                      <CategoryChip category={note.category} />
                    </TableCell>
                    <TableCell colSpan={10}>
                      <span
                        className={classes.note_date}
                      >
                        {moment(note.created_at).format('MM/DD/YYYY')}
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span
                        className={classes.note_creator}
                      >
                        {note.added_by}
                      </span>
                      <p className={classes.note_copy}>{note.note}</p>                 
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <NoteDialog open={modalOpen} mode={noteMode} onSubmitClick={onSubmitNoteClick} onCancelClick={onCancelNoteClick} merchantNote={note} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    modalOpen: state.note.modalOpen,
    current_user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onOpenNoteModal: () => dispatch(actions.openNoteModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletedNotesTable)
