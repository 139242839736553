import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux'
import { BrowserRouter, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import withErrorHandler from './hocs/withErrorHandler'
import axios from './httpClients/axios-datamerch'
import * as actions from './store/actions'

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import Toasts from './components/UI/Toasts'

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = props => {
  const { 
    onAutoLogin,
    token
  } = props

  // useEffect(() => {
  //   const local_storage_token = localStorage.getItem("token")
  //   const redirect = props.location.path
  //   if((local_storage_token && !token) || (local_storage_token && token && (local_storage_token !== token))){
  //     onAutoLogin(local_storage_token, redirect)
  //   }
  // }, [onAutoLogin, token])

  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <Toasts />
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAutoLogin: (token, redirect) => dispatch(actions.autoLogin(token, redirect))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
