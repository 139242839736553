import axios from 'axios'

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT_URL,
  // cancelToken: source.token
})

// instance.isCancel = axios.isCancel;



export default instance