import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  terms: {
    height: '400px',
    overflow: 'scroll'
  }
}));

const Terms  = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography
        variant="h4"
      >
        AGREEMENT BETWEEN USER AND WWW.DATAMERCH.COM
      </Typography>
      <Typography
        variant="subtitle2"
      >
        Welcome to www.datamerch.com. The www.datamerch.com website (the "Site") is comprised of various web pages operated by DataMerch LLC ("DataMerch LLC") which provides information services, information, and other content. In addition, third parties provide information, data, software and other content which may be accessed over the website. www.datamerch.com is offered to Members (“Member(s)”, “user”, “you”, “your” conditioned on their acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of www.datamerch.com constitutes your agreement to all such Terms. Please read these terms carefully and keep a copy of them for your reference. If you do not agree to these Terms, you may not use the DataMerch LLC Site. These Terms are in addition to, and do not override, the terms and conditions that apply to the specific services offered by DataMerch LLC.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        DataMerch LLC or its members do not constitute a "consumer report" as that term is defined in the federal Fair Credit Reporting Act, 15 USC 1681 et seq. (FCRA). DataMerch LLC only accepts, stores, and distributes data on commercial entities. User is prohibited from providing information on individual persons or consumers. Information listed on DataMerch LLC may not be used in whole or in part as a factor in determining eligibility for personal credit, insurance, employment or another permissible purpose under the FCRA. It is the policy of DataMerch LLC to view sole-proprietors, single-shareholder corporations, single-member partnerships and single-member companies as commercial entities and never as individuals or consumers.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        www.datamerch.com is an Information Site that shares data on merchants in the alternative business financing field.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        ELECTRONIC COMMUNICATIONS
      </Typography>
      <Typography
        variant="subtitle2"
      >
        Visiting www.datamerch.com or sending emails to DataMerch LLC constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        YOUR ACCOUNT
      </Typography>
      <Typography
        variant="subtitle2"
      >
        If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password and to indemnify DataMerch LLC for any damages caused by the use of your account, password or computer. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that DataMerch LLC is not responsible for third party access to your account that results from theft or misappropriation of your account. DataMerch LLC and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        User acknowledges and agrees that by providing DataMerch LLC with any user information through the DataMerch LLC Site, user consents to the transmission of such user information over state, federal, and international borders as necessary for processing in accordance with DataMerch LLC’s standard business practices. User should be aware that linked sites may contain transmission of user data provisions that differ from the provisions provided herein. DataMerch LLC is not responsible for such provisions, and expressly disclaims any and all liability related to such provisions.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        By posting your content you are granting DataMerch LLC a non-exclusive, royalty-free, perpetual, and worldwide license to use your content in connection with the operation of the services, including, without limitation, the license rights to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your content, and/or to incorporate in into a collective work.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        You grant DataMerch LLC permission to use your name for attribution purposes. You, likewise, agree to represent yourself accurately. You acknowledge that misrepresentation may lead DataMerch LLC, at its sole discretion, to cancel your use of the services and delete any of your content.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Access to and use of password protected and/or secure areas of the DataMerch LLC Site is restricted to authorized users only. Unauthorized individuals attempting to access these areas of the DataMerch LLC Site may be subject to prosecution.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        DataMerch LLC does not knowingly collect, either online or offline, information from persons under the age of eighteen. If you are under 18, you may use www.datamerch.com only with permission of a parent or guardian.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        CANCELLATION/REFUND POLICY
      </Typography>
      <Typography
        variant="subtitle2"
      >
        You may cancel your subscription at any time. Please contact us at support@datamerch.com with any questions.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        LINKS TO THIRD PARTY SITES/THIRD PARTY SERVICES
      </Typography>
      <Typography
        variant="subtitle2"
      >
        www.datamerch.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of DataMerch LLC and DataMerch LLC is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. DataMerch LLC is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by DataMerch LLC of the site or any association with its operators.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Certain services made available via www.datamerch.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the www.datamerch.com domain, you hereby acknowledge and consent that DataMerch LLC may share such information and data with any third party with whom DataMerch LLC has a contractual relationship to provide the requested product, service or functionality on behalf of www.datamerch.com users and customers.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        NO UNLAWFUL OR PROHIBITED USE/INTELLECTUAL PROPERTY
      </Typography>
      <Typography
        variant="subtitle2"
      >
        You are granted a non-exclusive, non-transferable, revocable license to access and use www.datamerch.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to DataMerch LLC that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        The services, including, but not limited to, data and programs, available on or through the DataMerch LLC Site are the proprietary, copyrighted works of DataMerch LLC and comprise: 1- original works of authorship, including compiled information containing DataMerch LLC selection, arrangement and coordination and expressions of such information or pre-existing material it has created, gathered or assembled; and 2- trade secret and other confidential information.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of DataMerch LLC or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. DataMerch LLC content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your business use, and will make no other use of the content without the express written permission of DataMerch LLC and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of DataMerch LLC or our licensors except as expressly authorized by these Terms.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        USE OF COMMUNICATION SERVICES
      </Typography>
      <Typography
        variant="subtitle2"
      >
        The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, sexually offensive, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        DataMerch LLC has no obligation to monitor the Communication Services. However, DataMerch LLC reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. DataMerch LLC reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        DataMerch LLC reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in DataMerch LLC's sole discretion.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. DataMerch LLC does not control or endorse the content, messages or information found in any Communication Service and, therefore, DataMerch LLC specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized DataMerch LLC spokespersons, and their views do not necessarily reflect those of DataMerch LLC.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        MATERIALS PROVIDED TO WWW.DATAMERCH.COM OR POSTED ON ANY DATAMERCH LLC WEB PAGE
      </Typography>
      <Typography
        variant="subtitle2"
      >
        DataMerch LLC does not claim ownership of the materials you provide to www.datamerch.com (including feedback and suggestions) or post, upload, input or submit to any DataMerch LLC Site or our associated services (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting DataMerch LLC, our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        No compensation will be paid with respect to the use of your Submission, as provided herein. DataMerch LLC is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in DataMerch LLC's sole discretion.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        User agrees to maintain independent original records of all information or data posted or uploaded to the www.datamerch.com and will submit the same to DataMerch LLC upon request. In the event a third party challenges the accuracy or validity of the information or data posted or uploaded by User or alleges damages as a result of the information or data posted or uploaded by User, then User agrees to fully cooperate with and indemnify DataMerch LLC as referenced below under “Indemnification”. Cooperation includes, but is not limited to, communicating with DataMerch LLC regarding the challenged information or data and providing proof of the accuracy of the challenged information or data.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        User agrees not to use the facilities and capabilities of the website to conduct any business or activity or solicit the performance of any activity that is prohibited by law or to solicit users to become users of other competitive information services.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        INTERNATIONAL USERS
      </Typography>
      <Typography
        variant="subtitle2"
      >
        The Service is controlled, operated and administered by DataMerch LLC from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the DataMerch LLC Content accessed through www.datamerch.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        INDEMNIFICATION
      </Typography>
      <Typography
        variant="subtitle2"
      >
        You agree to indemnify, defend and hold harmless DataMerch LLC, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. DataMerch LLC reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with DataMerch LLC in asserting any available defenses. Without limiting the forgoing, you may not settle, compromise or in any other manner dispose of any claim without the consent of Datamerch LLC.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        LIABILITY DISCLAIMER
      </Typography>
      <Typography
        variant="subtitle2"
      >
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. DATAMERCH LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        DATAMERCH LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. DATAMERCH LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. DATAMERCH, LLC OR ITS SUPPLIERS DISCLAIMS ANY OBLIGATION TO MAINTAIN THE CONFIDENTIALITY OF INFORMATION, ALTHOUGH ITS CURRENT PRACTICE IS TO UTILIZE REASONABLE EFFORTS TO MAINTAIN CONFIDENTIALITY. DATAMERCH, LLC WILL NOT BE RESPONSIBLE FOR ANY DAMAGE USER SUFFERS FROM USE OF THE WEBSITE. USE OF ANY INFORMATION OBTAINED VIA THE WEBSITE IS AT YOUR OWN RISK.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DATAMERCH LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA PROFITS, BUSINESS REPUTATION OR GOODWILL, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF DATAMERCH LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        DDATAMERCH LLC WILL NOT BE LIABLE FOR FAILING TO PERFORM UNDER THESE TERMS AND CONDITIONS BY THE OCCURRENCE OF ANY EVENT BEYOND ITS REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, A LABOR DISTURBANCE, AN INTERNET OUTAGEOR INTERRUPTION OF SERVICES, COMMUNICATIONS OUTAGE OR FAILURE DUE TO FIRE, TERRORISM, NATURAL DISASTER OR WAR.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        TERMINATION/ACCESS RESTRICTION
      </Typography>
      <Typography
        variant="subtitle2"
      >
        DataMerch LLC reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Florida and you hereby consent to the exclusive jurisdiction and venue of courts in Florida in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        You agree that no joint venture, partnership, employment, or agency relationship exists between you and DataMerch LLC as a result of this agreement or use of the Site. DataMerch LLC's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of DataMerch LLC's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by DataMerch LLC with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and DataMerch LLC with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and DataMerch LLC with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        CHANGES TO TERMS
      </Typography>
      <Typography
        variant="subtitle2"
      >
        DataMerch LLC reserves the right, in its sole discretion, to change the Terms under which www.datamerch.com is offered. The most current version of the Terms will supersede all previous versions. DataMerch LLC encourages you to periodically review the Terms to stay informed of our updates.
      </Typography>
      <Typography
        className={classes.title}
        variant="h4"
      >
        CONTACT US
      </Typography>
      <Typography
        variant="subtitle2"
      >
        DataMerch LLC welcomes your questions or comments regarding the Terms:
      </Typography>
      <Typography
        variant="subtitle2"
      >
        DataMerch LLC
      </Typography>
      <Typography
        variant="subtitle2"
      >
        23110 State Road 54, #247
      </Typography>
      <Typography
        variant="subtitle2"
      >
        Lutz, FL 33549
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Email Address:
      </Typography>
      <Typography
        variant="subtitle2"
      >
        <a href="mailto:support@datamerch.com">support@datamerch.com</a>
      </Typography>
      <Typography
        className={classes.title}
        variant="subtitle2"
      >
        Telephone number:
      </Typography>
      <Typography
        variant="subtitle2"
      >
        (866)549-3114
      </Typography>
    </React.Fragment>
  )
}

export default Terms;
