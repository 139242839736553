import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Divider, CardHeader } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { store } from '../../Store'
import axios from '../../httpClients/axios-datamerch'

import * as actions from '../../store/actions'

import {RecentMerchantsByCategory} from '../../components'
import {TotalMembers} from '../../components'
import {TotalUsers} from '../../components'
import {TotalMerchants} from '../../components'
import {TotalNotes} from '../../components'
import {TotalMonthlyRevenue} from '../../components'
import {TopContributors} from '../../components'
import {BottomContributors} from '../../components'
import {SearchesByDay} from '../../components'
import {AdditionsByDay} from '../../components'
import {SearchesBySource} from '../../components'
import {HitsByCategory} from '../../components'
import {MessageBar} from '../../components'

import {SearchInput} from './../../components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    padding: '13px 5px 13px 5px'
  },
  divider: {
    margin: '40px 0'
  },
  dividerMinHeight: {
    margin: '0px 0 10px 0'
  },
  noUnderline: {
    textDecoration: 'none'
  }
}));

const DashboardOld = (props) => {
  const { 
    onMerchantSearchClick,
    onFetchDashboardUpdates,
    token,
    is_dm_admin,
    account_id,
    onLoadAppSettings,
    dashboard_message_enabled,
    dashboard_message_severity,
    dashboard_message_title,
    dashboard_message,
    user
  } = props
  const classes = useStyles();

  useEffect(() => {
    onFetchDashboardUpdates(user);
  }, [])

  const merchantSearch = (search, token) => {
    return { type: 'SEARCH_MERCHANTS', search: search, token: token }
  };

  const handleMerchantSearchClick = (event, search) => {
    event.preventDefault();
    store.dispatch(merchantSearch(search, token))
  }

  const logoutUser = () => {
    return { type: 'LOGOUT_USER' }
  };

  // if (!user.account_total_ninety_days_contribution_percentage){
  //   store.dispatch(logoutUser())
  // }

  return (
    <div>
      {dashboard_message_enabled ?
        <MessageBar
          severity={dashboard_message_severity}
          title={dashboard_message_title}
          message={dashboard_message}
        />
      : null }
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={6}
            sm={12}
            xl={6}
            xs={12}
          >
            <CardHeader title="Search for a Merchant" />
            <Divider  className={classes.dividerMinHeight} />
            <SearchInput
              className={classes.searchInput}
              placeholder="Search for merchant by full EIN or legal name"
              onSearchClick={(event, search) => handleMerchantSearchClick(event, search)}
              user={user}
            />
          </Grid>
          <Grid
            item
            lg={6}
            sm={12}
            xl={6}
            xs={12}
          >
            <CardHeader title="Add a New Merchant" />
            <Divider  className={classes.dividerMinHeight} />
            <RouterLink to="/merchants/new" className={classes.noUnderline}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<AddIcon />}
                fullWidth={true}
              >
                Add Merchant
              </Button>
            </RouterLink>
          </Grid>
        </Grid>
        <Divider  className={classes.divider} />
        <Grid
          container
          spacing={4}
        >
          {is_dm_admin ? 
            <React.Fragment>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={12}
              >
                <TotalMerchants />
              </Grid>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={12}
              >
                <TotalNotes />
              </Grid>
              <Grid
                item
                lg={4}
                sm={6}
                xl={4}
                xs={12}
              >
                <TotalMembers />
              </Grid>
              <Grid
                item
                lg={4}
                sm={6}
                xl={4}
                xs={12}
              >
                <TotalUsers />
              </Grid>
              <Grid
                item
                lg={4}
                sm={6}
                xl={4}
                xs={12}
              >
                <TotalMonthlyRevenue />
              </Grid>
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <SearchesByDay />
              </Grid>
              <Grid
                item
                lg={3}
                sm={12}
                xl={3}
                xs={12}
              >
                <SearchesBySource />
              </Grid>
              <Grid
                item
                lg={9}
                sm={12}
                xl={9}
                xs={12}
              >
                <HitsByCategory />
              </Grid>
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <AdditionsByDay />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <TopContributors />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <BottomContributors />
              </Grid>
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <RecentMerchantsByCategory category="All" perpage="200" />
              </Grid>
            </React.Fragment>
          : null}
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <RecentMerchantsByCategory category="Suspicious Activity" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    is_admin: state.auth.user.is_admin,
    is_dm_admin: state.auth.user.is_dm_admin,
    account_id: state.auth.user.account_id,
    dashboard_message_enabled: state.appSettings.dashboard_message_enabled,
    dashboard_message_severity: state.appSettings.dashboard_message_severity,
    dashboard_message_title: state.appSettings.dashboard_message_title,
    dashboard_message: state.appSettings.dashboard_message,
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardUpdates: (user) => dispatch(actions.fetchDashboardUpdates(user)),
    onMerchantSearch: (token, search, user) => dispatch(actions.searchMerchants(token, search, user)),
    onMerchantSearchReset: (token) => dispatch(actions.searchMerchantsReset(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOld, axios)

