import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../../store/actions'
import validate from 'validate.js';
import queryString from 'query-string'
import QRCode from "react-qr-code";

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  CircularProgress,
  Backdrop
} from '@material-ui/core';

const schema = {
  agree: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  terms: {
    height: '400px',
    overflow: 'scroll'
  }
}));

const MFARegistration  = (props) => {
  const { 
    onMFARegistrationSubmit,
    error,
    id,
    token,
    otp_secret,
    otp_url,
    onFetchOTPURL
  } = props

  const [code, setCode] = useState('')
  const [remember, setRemember] = useState('')


  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    onFetchOTPURL(token);
    setCode(null)
    setRemember(true)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [onFetchOTPURL, formState.values]);

  const handleInputChange = (event, field) => {
    switch(field){
      case 'code':
        setCode(event.target.value)
        break
      default:
        return null
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // const handleChange = event => {
  //   event.persist();

  //   setFormState(formState => ({
  //     ...formState,
  //     values: {
  //       ...formState.values,
  //       [event.target.name]:
  //         event.target.type === 'checkbox'
  //           ? event.target.checked
  //           : event.target.value
  //     },
  //     touched: {
  //       ...formState.touched,
  //       [event.target.name]: true
  //     }
  //   }));
  // };

  const handleChange = event => {
    event.persist();

    setRemember(event.target.checked)
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // let agree = event.target.agree.checked;
    
    // if(agree)

    let valid = (code && code.length == 6);
    
    if(valid){
      let data = {
        code: code,
        remember: remember
      }
  
      onMFARegistrationSubmit(token, otp_secret, data);
    } else {
      alert("Code must be 6 digits")
    }
  }

  let qrCode = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(otp_url){
    qrCode = (
      <QRCode value={otp_url} />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Setup Two-Factor Authentication" />
          <Divider />
          <CardContent>
            <Typography variant="subtitle2">
              User your two-factor authentication app to scan the QR code
            </Typography>
            {qrCode}
            <Typography variant="subtitle2">
              Enter 6-digit code from your two factor authenticator app.
            </Typography>
            <TextField
              
              margin="dense"
              name="code"
              onChange={(event) => handleInputChange(event, 'code')}
              required
              value={code || ''}
              variant="outlined"
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={remember}
                    value="remember"
                    name="remember"
                    color="primary"
                  />
                }
                label="Remember this device"
              />
            </FormGroup>
          </CardContent>
          <Divider />
          <CardActions>
            <form
              onSubmit={handleSubmit}
            >
              <Button
                className={classes.signInButton}
                color="primary"
                // disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Confirm and Activate
              </Button>
            </form>
          </CardActions>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    id: state.auth.user.resource_id,
    otp_secret: state.auth.otp_secret,
    otp_url: state.auth.otp_url
  }
}

const mapDispatchToProps = dispatch => ({
  onMFARegistrationSubmit: (token, otp_secret, data) => dispatch(actions.newOtp(token, otp_secret, data)),
  onFetchOTPURL: (token) => dispatch(actions.fetchOtpUrl(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(MFARegistration);
