import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import StripeCheckout from 'react-stripe-checkout'

import * as actions from '../../../store/actions'
import validate from 'validate.js';
import queryString from 'query-string'

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CornerRibbon from '../../../components/UI/CornerRibbon'

const schema = {
  agree: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  planLevel: {
    fontSize: '34px',
    paddingTop: 10,
    paddingBottom: 20
  },
  planLevelMembership: {
    fontSize: '24px',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    fontWeight: 'normal'
  },
  planLevelPrice: {
    fontSize: '36px',
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 20,
    marginBottom: 20,
    marginLeft: 10
  },
  planLevelPricePer: {
    fontSize: '14px',
    fontWeight: 'normal',
    paddingTop: 5
  },
  featureFalse: {
    color: theme.palette.error.main
  },
  featureNeutral: {
    color: theme.palette.warning.main
  },
  featureTrue: {
    color: theme.palette.success.main
  },
  planPosition: {
    position: 'relative'
  }
}));

const SignUpNew  = (props) => {
  const { 
    onSubscriptionCreate,
    error,
    id,
    account_id
  } = props

  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (token, plan) => {
    onSubscriptionCreate(token, account_id, plan)
  }

  const handleSubmitBasic = (token, plan) => {
    onSubscriptionCreate(token, account_id, "DataMerch Basic Subscription")
  }

  const handleSubmitPro = (token, plan) => {
    onSubscriptionCreate(token, account_id, "DataMerch Pro Subscription")
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/rPxwRSO_aK8?si=iuHIAKUGT88Wc00D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={3} xl={2} className={classes.planPosition}>
            <Card>
              <CardContent>
                <Typography
                variant="h3"
                align="center"
                >
                  <div className={classes.planLevel}>BASIC<span className={classes.planLevelMembership}>Membership</span></div>
                </Typography>
                <Divider />
                <StripeCheckout
                  stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                  token={handleSubmitBasic}
                  name="DataMerch" // the pop-in header title
                  description="Basic Membership" // the pop-in header subtitle
                  ComponentClass="div"
                  panelLabel="Pay" // prepended to the amount in the bottom pay button
                  amount={69500} // cents
                  currency="USD"
                  allowRememberMe={false}
                >
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    size="large"
                    type="button"
                    variant="contained"
                  >
                    Start Basic Membership
                  </Button>
                </StripeCheckout>
                <Typography
                  variant="h5"
                >
                  <div className={classes.planLevelPrice}>$695<div className={classes.planLevelPricePer}>/month</div></div>
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Website Access" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Search for Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Add New Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Add Notes to Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureNeutral} />
                    </ListItemIcon>
                    <ListItemText primary="Limited API Access" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <IndeterminateCheckBoxIcon className={classes.featureNeutral} />
                    </ListItemIcon>
                    <ListItemText primary="Up to 3000 Searches Per Day" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <IndeterminateCheckBoxIcon className={classes.featureNeutral} />
                    </ListItemIcon>
                    <ListItemText primary="Keyword search returns up to 5 matches" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CancelIcon className={classes.featureFalse} />
                    </ListItemIcon>
                    <ListItemText primary="Merchant Subscriptions" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CancelIcon className={classes.featureFalse} />
                    </ListItemIcon>
                    <ListItemText primary="View submission source data" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CancelIcon className={classes.featureFalse} />
                    </ListItemIcon>
                    <ListItemText primary="View EIN search history" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={4} xl={3} className={classes.planPosition}>
            <Card>
            <CardContent>
                <CornerRibbon />
                <Typography
                variant="h3"
                align="center"
                >
                  <div className={classes.planLevel}><b>PRO</b><span className={classes.planLevelMembership}>Membership</span></div>
                </Typography>
                <Divider />
                <StripeCheckout
                  stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                  token={handleSubmitPro}
                  name="DataMerch" // the pop-in header title
                  description="Pro Membership" // the pop-in header subtitle
                  ComponentClass="div"
                  panelLabel="Pay" // prepended to the amount in the bottom pay button
                  amount={99500} // cents
                  currency="USD"
                  allowRememberMe={false}
                >
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    size="large"
                    type="button"
                    variant="contained"
                  >
                    Start Pro Membership
                  </Button>
                </StripeCheckout>
                <Typography
                  variant="h5"
                >
                  <div className={classes.planLevelPrice}>$945<div className={classes.planLevelPricePer}>/month</div></div>
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Website Access" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Search for Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Add New Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Add Notes to Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="API Access" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="UNLIMITED Searches Per Day" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Keyword search returns up to 25 matches" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Merchant Subscriptions"
                      secondary="Be notified automatically when updates occur"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="View submission source data" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="View EIN search history" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Additional Details" />
          <Divider />
          <CardContent>
            <Typography
              className={classes.title}
              variant="h6"
            >
              Do I have to install anything or talk to IT?
            </Typography>
            <Typography
              variant="body2"
            >
              Nope! You can do all your DataMerch activity through your web browser. You are also welcome to set upautomated searches and additions to our system using our API if you choose.
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
            >
              What if I change my mind?
            </Typography>
            <Typography
              variant="body2"
            >
              No problem, you can cancel anytime, all on your own, no questions asked.
            </Typography>
            <Typography
              className={classes.title}
              variant="body2"
            >
              If you have any other questions, we’d be <a href="mailto:support@datamerch.com">happy to answer them.</a>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    id: state.auth.user.resource_id,
    account_id: state.auth.user.account_resource_id
  }
}

const mapDispatchToProps = dispatch => ({
  onSubscriptionCreate: (token, id, plan) => dispatch(actions.subscriptionCreate(token, id, plan))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpNew);
