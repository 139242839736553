import React from 'react';
import {connect} from 'react-redux'
import { Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { store } from './Store'
import { RouteWithLayout } from './components';
import { PrivateRoute, AdminRoute, DmAdminRoute } from './hocs'
import { Main as MainLayout, Minimal as MinimalLayout, Auth as AuthLayout } from './layouts';

import {
  Analytics as AnalyticsView,
  Dashboard as DashboardView,
  DashboardOld as DashboardOldView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  AccountList as AccountListView,
  AccountAdd as AccountAddView,
  AccountEdit as AccountEditView,
  AccountView,
  UserAdd as UserAddView,
  UserEdit as UserEditView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  MerchantList as MerchantListView,
  DeletedMerchantList as DeletedMerchantListView,
  MerchantAdd as MerchantAddView,
  MerchantEdit as MerchantEditView,
  MerchantView as MerchantViewView,
  DeletedMerchantView as DeletedMerchantView,
  SearchView,
  LoginView,
  PasswordResetView,
  ForgotPasswordView,
  Profile as ProfileView,
  Members as MembersView,
  AgreeToTerms as AgreeToTermsView,
  MembershipSignUp as MembershipSignUpView,
  MembershipSignUpNew as MembershipSignUpViewNew,
  TermsOfUse as TermsOfUseView,
  PrivacyPolicy as PrivacyPolicyView,
  DisputeProcess as DisputeProcessView,
  Developers as DevelopersView,
  Api as ApiV1View,
  DisputeAdd as DisputeAddView,
  MFARegistration as MFARegistrationView,
  MFAVerification as MFAVerificationView,
  MerchantSubscriptionList as MerchantSubscriptionListView,
  MerchantSubscriptionImportList as MerchantSubscriptionImportListView,
  MerchantSubscriptionWebhookLogs as MerchantSubscriptionWebhookLogsView,
  Pro as ProView
} from './views';

import Gateway from './views/Auth/Login/Gateway.js';

// export const history = createBrowserHistory()
export const history = createBrowserHistory()

let currentRoute = history.location
history.listen((nextRoute) => {
  if (currentRoute.pathname === nextRoute.pathname &&
    currentRoute.search === nextRoute.search &&
    currentRoute.hash === nextRoute.hash) return
  currentRoute = nextRoute
  store.dispatch({ type: 'ROUTE_CHANGE' })
})

const Routes = props => {
  const { is_dm_admin, is_admin} = props

  let MerchantShowView = (<PrivateRoute
    component={MerchantViewView}
    exact
    layout={MainLayout}
    path="/merchants/:id"
  />)
  
  if (is_dm_admin){
    MerchantShowView = (<PrivateRoute
      component={MerchantEditView}
      exact
      layout={MainLayout}
      path="/merchants/:id"
    />)
  }
  
  return (
    <Router history={history}>
      <Switch>
        {/* <Redirect
          exact
          from="/"
          to="/login"
        /> */}
        <RouteWithLayout
          component={LoginView}
          exact
          layout={AuthLayout}
          path="/login"
        />
        <RouteWithLayout
          component={Gateway}
          exact
          layout={AuthLayout}
          path="/gateway"
        />
        <RouteWithLayout
          component={PasswordResetView}
          exact
          layout={AuthLayout}
          path="/password_reset"
        />
        <RouteWithLayout
          component={ForgotPasswordView}
          exact
          layout={AuthLayout}
          path="/forgot_password"
        />
         <RouteWithLayout
          component={TermsOfUseView}
          exact
          layout={MinimalLayout}
          path="/terms_of_use"
        />
        <RouteWithLayout
          component={PrivacyPolicyView}
          exact
          layout={MinimalLayout}
          path="/privacy_policy"
        />
        <RouteWithLayout
          component={DisputeProcessView}
          exact
          layout={MainLayout}
          path="/dispute_process"
        />
        <RouteWithLayout
          component={DevelopersView}
          exact
          layout={MainLayout}
          path="/developers"
        />
        <RouteWithLayout
          component={ProView}
          exact
          layout={MinimalLayout}
          path="/pro"
        />
        {/* <RouteWithLayout
          component={ApiV1View}
          exact
          layout={MainLayout}
          path="/developers/v1/api"
        /> */}
        <RouteWithLayout
          component={DisputeAddView}
          exact
          layout={AuthLayout}
          path="/dispute"
        />
        <PrivateRoute
          component={AgreeToTermsView}
          exact
          layout={MinimalLayout}
          path="/agree_to_terms"
        />
        <PrivateRoute
          component={MFARegistrationView}
          exact
          layout={MinimalLayout}
          path="/mfa_registration"
        />
        <PrivateRoute
          component={MFAVerificationView}
          exact
          layout={MinimalLayout}
          path="/mfa_verification"
        />
        {/* <PrivateRoute
          component={MembershipSignUpView}
          exact
          layout={MinimalLayout}
          path="/membership"
        /> */}
        <PrivateRoute
          component={MembershipSignUpViewNew}
          exact
          layout={MinimalLayout}
          path="/membership"
        />
        <PrivateRoute
          component={DashboardView}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
        <PrivateRoute
          component={DashboardOldView}
          exact
          layout={MainLayout}
          path="/dashboardold"
        />
        <PrivateRoute
          component={AnalyticsView}
          exact
          layout={MainLayout}
          path="/analytics"
        />
        <PrivateRoute
          component={MembersView}
          exact
          layout={MainLayout}
          path="/members"
        />
        <DmAdminRoute
          component={AccountListView}
          exact
          layout={MainLayout}
          path="/funders"
        />
        <DmAdminRoute
          component={AccountAddView}
          exact
          layout={MainLayout}
          path="/funders/new"
        />
        <DmAdminRoute
          component={AccountEditView}
          exact
          layout={MainLayout}
          path="/funders/:id"
        />
        <DmAdminRoute
          component={UserAddView}
          exact
          layout={MainLayout}
          path="/funders/:funder_id/members/new"
        />
        <DmAdminRoute
          component={UserEditView}
          exact
          layout={MainLayout}
          path="/funders/:funder_id/members/:id"
        />
        <DmAdminRoute
          component={MerchantListView}
          exact
          layout={MainLayout}
          path="/merchants"
        />
        <DmAdminRoute
          component={DeletedMerchantListView}
          exact
          layout={MainLayout}
          path="/deleted-merchants"
        />
        <DmAdminRoute
          component={DeletedMerchantView}
          exact
          layout={MainLayout}
          path="/deleted-merchants/:id"
        />
        <PrivateRoute
          component={MerchantAddView}
          exact
          layout={MainLayout}
          path="/merchants/new"
        />
        {MerchantShowView}
        <AdminRoute
          component={AccountView}
          exact
          layout={MainLayout}
          path="/account"
        />
        <AdminRoute
          component={UserAddView}
          exact
          layout={MainLayout}
          path="/account/members/new"
        />
        <AdminRoute
          component={UserEditView}
          exact
          layout={MainLayout}
          account_view
          path="/account/members/:id"
        />
        <PrivateRoute
          component={ProfileView}
          exact
          layout={MainLayout}
          path="/profile"
        />
        <PrivateRoute
          component={SearchView}
          exact
          layout={MainLayout}
          path="/search"
        />
        <DmAdminRoute
          component={SettingsView}
          exact
          layout={MainLayout}
          path="/settings"
        />
        <PrivateRoute
          component={MerchantSubscriptionListView}
          exact
          layout={MainLayout}
          path="/merchant_subscriptions"
        />
        <PrivateRoute
          component={MerchantSubscriptionImportListView}
          exact
          layout={MainLayout}
          path="/merchant_subscription_imports"
        />
        <PrivateRoute
          component={MerchantSubscriptionWebhookLogsView}
          exact
          layout={MainLayout}
          path="/merchant_subscription_webhook_logs"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <Redirect to="/dashboard" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    is_admin: state.auth.user.is_admin,
    is_dm_admin: state.auth.user.is_dm_admin,
    token: state.auth.token 
  }
}

export default connect(mapStateToProps)(Routes)
