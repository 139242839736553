import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { MerchantsTable } from '../../../components/Merchant';
import { CircularProgress, Backdrop, Card, CardHeader, Divider, AppBar, Tabs, Tab } from '@material-ui/core';
import { MerchantDeleteDialog } from '../../../components/Merchant';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  },
  active_tab: {
    color: '#fff!important',
    background: theme.palette.blue
  }
}));

const MerchantList = props => {
  const classes = useStyles();
  const { 
    onFetchMerchants,
    onMerchantArchive,
    merchants,
    token,
    loading,
    pagination,
    onOpenMerchantDeleteModal,
    onCloseMerchantDeleteModal,
    modalOpen,
    onMerchantDeleteSubmit
  } = props

  const [page, setPage] = useState(0)
  const [per_page, setPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [delete_id, setDeleteId] = useState('')
  const [reason, setReason] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    if (search) {
      setIsFiltered(true)
    }
    onFetchMerchants(page, per_page, search);
    
    return () => {
      source.cancel()
    }
  }, [onFetchMerchants, page, per_page, search])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value)
  }

  const handleArchiveClick = (event, merchantId) => {
    event.preventDefault();
    setDeleteId(merchantId);
    setReason('');
    onOpenMerchantDeleteModal(merchantId)
    
    //onMerchantArchive(token, merchantId)
  }

  const handleCancelArchiveClick = (event) => {
    onCloseMerchantDeleteModal()
  }

  const handleSubmitMerchantDeleteClick = (event, merchantDeleteData) => {
    onMerchantDeleteSubmit(token, merchantDeleteData)
  }

  const handleSearchClick = (event, search) => {
    setIsFiltered(true)
    setSearch(search)
  }

  const handleSearchCancelClick = (event) => {
    setIsFiltered(false)
    setSearch('')
  }

  let merchantsTable = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!loading){
    merchantsTable = (
      <MerchantsTable
        merchants={merchants}
        onArchiveClick={(event, merchantId) => handleArchiveClick(event, merchantId)}
        pagination={pagination}
        onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        onPerPageChange={handlePerPageChange}
        onSearchClick={(event, search) => handleSearchClick(event, search)}
        onSearchCancelClick={handleSearchCancelClick}
        currentSearch={search}
        isFiltered={isFiltered}
      />
    )
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
      <AppBar position="static" color="default">
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value="0"
        >
          <Tab label="Merchants" href="" className={classes.active_tab} value="0"/>
          <Tab label="Deleted Merchants" href="deleted-merchants" />
        </Tabs>
      </AppBar>
        
        <Card>
          <Divider />
          {merchantsTable}
          <MerchantDeleteDialog
            open={modalOpen}
            onCloseClick={(event) => handleCancelArchiveClick(event)}
            onSubmitClick={handleSubmitMerchantDeleteClick}
            merchantId={delete_id}
            reason={reason}
          />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchants: state.merchant.merchants,
    loading: state.merchant.loading,
    token: state.auth.token,
    pagination: state.merchant.pagination,
    modalOpen: state.merchant.merchant_delete_modal_open
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchMerchants: (page, per_page, search) => dispatch(actions.fetchMerchants(page, per_page, search)),
    onMerchantArchive: (token, merchantId) => dispatch(actions.archiveMerchant(token, merchantId)),
    onOpenMerchantDeleteModal: (merchantId) => dispatch(actions.openMerchantDeleteModal()),
    onCloseMerchantDeleteModal: () => dispatch(actions.closeMerchantDeleteModal()),
    onMerchantDeleteSubmit: (token, merchantDeleteData) => dispatch(actions.archiveMerchant(token, merchantDeleteData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantList, axios))