import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button,
  CardHeader,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

const useStyles = makeStyles(() => ({
  root: {}
}));

const NoteDisputeForm = props => {
  const { className, reason, onChange, account_view, ...rest } = props;
  
  let title = "Note or Record Delete Request"
  if (account_view) {
    title = "Note or Record Delete Request"
  }

  const classes = useStyles();

  

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        md={12}
        xs={12}
      >
        <TextField
          fullWidth
          label="Why should this note or record be deleted?"
          margin="dense"
          name="reason"
          onChange={(event) => onChange(event, 'reason')}
          required
          value={reason || ''}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};


export default withErrorHandler(NoteDisputeForm, axios)