import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {clearNotes} from './note'
import { history } from '../../Routes'
import {addToast} from './toasts'

export const clearDeletedMerchants = () => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANTS_SUCCESS,
    merchants: []
  }
}

export const clearDeletedMerchant = () => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANT_SUCCESS,
    merchant: {
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      state: '',
      business_phone: '',
      business_startdate: '',
      industry: '',
      category: '',
      note: '',
      reason: ''
    }
  }
}

export const fetchDeletedMerchantsSuccess = (merchants, pagination) => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANTS_SUCCESS,
    merchants: merchants,
    pagination: pagination
  }
}

export const fetchDeletedMerchantsFail = (error) => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANTS_FAIL,
    error: error
  }
}

export const fetchDeletedMerchantsStart = () => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANTS_START
  }
}

export const fetchDeletedMerchants = (page, per_page, search) => { 
  return dispatch => {
    dispatch(fetchDeletedMerchantsStart())
    let url = `/deleted_merchants?page=${page + 1}&per_page=${per_page}&search=${search}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchants = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedMerchants.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(clearNotes())
        dispatch(clearDeletedMerchant())
        dispatch(fetchDeletedMerchantsSuccess(fetchedMerchants, pagination))
      }
      else {
        dispatch(fetchDeletedMerchantsFail('Unable to load merchants.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchants.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchDeletedMerchantsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchDeletedMerchantSuccess = (merchant) => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANT_SUCCESS,
    merchant: merchant
  }
}

export const fetchDeletedMerchantFail = (error) => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANT_FAIL,
    error: error
  }
}

export const fetchDeletedMerchantStart = () => {
  return {
    type: actionTypes.FETCH_DELETED_MERCHANT_START
  }
}

export const fetchDeletedMerchant = (token, id) => {
  return dispatch => {
    dispatch(fetchDeletedMerchantStart())
    let url = `/deleted_merchants/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchant = {...response.data.data}
        dispatch(fetchDeletedMerchantSuccess(fetchedMerchant))
      } else {
        dispatch(fetchDeletedMerchantFail('An error occurred while attempting to fetch merchant.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchant.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchDeletedMerchantFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}


export const searchDeletedMerchantsSuccess = (merchants) => {
  return {
    type: actionTypes.SEARCH_DELETED_MERCHANTS_SUCCESS,
    merchants: merchants
  }
}

export const searchDeletedMerchantsFail = (error) => {
  return {
    type: actionTypes.SEARCH_DELETED_MERCHANTS_FAIL,
    error: error
  }
}

export const searchDeletedMerchantsStart = () => {
  return {
    type: actionTypes.SEARCH_DELETED_MERCHANTS_START
  }
}

export const searchDeletedMerchantsReset = () => {
  return {
    type: actionTypes.SEARCH_DELETED_MERCHANTS_RESET
  }
}

export const searchDeletedMerchantsResults = () => {
  return {
    type: actionTypes.SEARCH_DELETED_MERCHANTS_RESULTS
  }
}

export const searchDeletedMerchants = (token, search) => {
  return dispatch => {
    dispatch(searchDeletedMerchantsReset())
    dispatch(searchDeletedMerchantsStart())
    let url = `/search/deleted_merchants?search=${search}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const searchedMerchants = []
        for(let key in response.data.data){
          searchedMerchants.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(searchDeletedMerchantsSuccess(searchedMerchants))
        dispatch({ type: actionTypes.SEARCH_DELETED_MERCHANTS_RESULTS, merchants: searchedMerchants })
      } else {
        //dispatch(openMerchantNotFoundDialog())
      }
    })
    .catch(error => {
      dispatch(searchDeletedMerchantsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}