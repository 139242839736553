let id = 0;

const defaultOptions = {
  severity: 'success',
  duration: 6000,
  horizontal: 'center',
  vertical: 'top'
};

export default function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: id++
  }
}