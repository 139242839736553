import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  disputes: [],
  dispute: {
    first_name: '',
    last_name: '',
    email: '',
    fein: '',
    legal_name: '',
    dba: '',
    city: '',
    business_state: '',
    reason: ''
  },
  id: null,
  dialog_id: null,
  saving: false,
  error: null,
  flash: null,
  pagination: {
    current_page: 0,
    per_page: 25,
    total_pages: 1,
    total_count: 0
  },
  searched: false
}

const addDisputeStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const addDisputeSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const addDisputeFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const initializeAddDispute = (state, action) => {
  return updateObject(state, {
    dispute_not_found_dialog_open: false,
    dispute_exists_dialog_open: false,
    dispute: {
      first_name: '',
      last_name: '',
      email: '',
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      business_state: '',
      reason: ''
    },
    disputes: [],
    id: null,
    dialog_id: null,
    loading: false,
    saving: false
  })
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.ADD_DISPUTE_START:
      return addDisputeStart(state, action)
    case actionTypes.ADD_DISPUTE_SUCCESS:
      return addDisputeSuccess(state, action)
    case actionTypes.ADD_DISPUTE_FAIL:
      return addDisputeFail(state, action)
    case actionTypes.INITIALIZE_ADD_DISPUTE:
      return initializeAddDispute(state, action)
    default:
      return state
  }
}
    
export default reducer
