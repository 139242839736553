import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import PasswordInfo from '../PasswordInfo'

const useStyles = makeStyles(() => ({
  root: {}
}));

const PasswordDialog = React.memo(props => {
  const { className, userId, open, onCloseClick, onSubmitClick, profile } = props;
  
  let schema = {
    newPassword: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 64
      }
    },
    confirmPassword: {
      equality: "newPassword",
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 64
      },
    }
  };
  
  if (profile==='abc') {
    schema = {
      oldPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
          maximum: 64
        }
      },
      newPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
          maximum: 64
        }
      },
      confirmPassword: {
        equality: "newPassword",
        presence: { allowEmpty: false, message: 'is required' },
        length: {
          maximum: 64
        },
      }
    };
  }
  
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: false,
      errors: {},
      values: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    }));
  }, [open])

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let oldPassword = null
    if (profile==='abc')
      oldPassword = event.target.oldPassword.value;
    
    let newPassword = event.target.newPassword.value;
    let confirmPassword = event.target.confirmPassword.value;
    
    const data = {
      user_id: userId,
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    };

    if(formState.isValid)
      onSubmitClick(event, data);
  }

  return (
    <Dialog
      className={clsx(classes.root, className)}
      open={open}
      onClose={onCloseClick}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <Divider />
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <DialogContent>
          <PasswordInfo
            user_id={userId}
            onChange={(event) => handleChange(event)}
            profile={profile}
            formState={formState}
            hasError={(field) => hasError(field)}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Update Password
          </Button>
          <Button
            color="secondary"
            onClick={(event) => onCloseClick(event)}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});


export default withErrorHandler(PasswordDialog, axios)