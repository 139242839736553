import React from 'react';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';

const AccountMerchantSubscriptionSettings = props => {
  const {
    onChange,
    name,
    website,
    primaryContactEmail,
    merchant_subscription_notification_email,
    merchant_subscription_notification_webhook,
    merchant_subscription_enabled,
    account_view,
    is_dm_admin,
    onMerchantSubscriptionEnabledToggle
  } = props;
  
  let title = "Merchant Subscription Settings"
  
  return (
    <React.Fragment>
      <CardHeader
        title={title}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          { is_dm_admin && (
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
              control={<Switch checked={merchant_subscription_enabled} onChange={(event) => onChange(event, 'merchant_subscription_enabled')} name="merchant_subscription_enabled" color="primary" />}
              label="Merchant Subscriptions Enabled"
            />
            </Grid>
          )}
          { merchant_subscription_enabled && (
            <React.Fragment>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Subscription Notification Email"
                  margin="dense"
                  name="merchant_subscription_notification_email"
                  onChange={(event) => onChange(event, 'merchant_subscription_notification_email')}
                  value={merchant_subscription_notification_email || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Subscription Notification Webhook URL"
                  margin="dense"
                  name="merchant_subscription_notification_webhook"
                  onChange={(event) => onChange(event, 'merchant_subscription_notification_webhook')}
                  value={merchant_subscription_notification_webhook || ''}
                  variant="outlined"
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
    </React.Fragment>
  );
};

export default withErrorHandler(AccountMerchantSubscriptionSettings, axios)