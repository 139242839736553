import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const clearMerchantSubscriptionWebhooks = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_SUCCESS,
    merchant_subscription_webhooks: []
  }
}

export const fetchMerchantSubscriptionWebhooksSuccess = (merchant_subscription_webhooks) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_SUCCESS,
    merchant_subscription_webhooks: merchant_subscription_webhooks
  }
}

export const fetchMerchantSubscriptionWebhooksFail = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_FAIL,
    error: error
  }
}

export const fetchMerchantSubscriptionWebhooksStart = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_START
  }
}

export const fetchMerchantSubscriptionWebhooks = (account_id) => { 
  return dispatch => {
    dispatch(fetchMerchantSubscriptionWebhooksStart())
    let url = `accounts/${account_id}/merchant_subscription_webhooks`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchantSubscriptionWebhooks = []
        for(let key in response.data.data){
          fetchedMerchantSubscriptionWebhooks.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchMerchantSubscriptionWebhooksSuccess(fetchedMerchantSubscriptionWebhooks))
      }
      else {
        dispatch(fetchMerchantSubscriptionWebhooksFail('Unable to load merchant subscription webhooks.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchant subscription webhooks.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchMerchantSubscriptionWebhooksFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}
