import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  Grid,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ApiCredentialDialog = props => {
  const { className, user, open, onCloseClick } = props;
  // const [authenticationToken, setAuthenticationToken] = useState(user.authentication_token)
  // const [authenticationKey, setAuthenticationKey] = useState(user.authentication_key)
  
  const classes = useStyles();

  // useEffect(() => {
  //   setAuthenticationToken(user.authentication_token)
  //   setAuthenticationKey(user.authentication_key)
  // },[user])

  return (
    <Dialog
      className={clsx(classes.root, className)}
      open={open}
      onClose={onCloseClick}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">API Credentials</DialogTitle>
      <Divider />
      <DialogContent>
        <form
          autoComplete="off"
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography variant="h3">
                <strong><u>Authentication Token</u></strong>
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography variant="h3">
              <strong><u>Authentication Key</u></strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography variant="h4">
                {user ? user.authentication_token : ''}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography variant="h4">
                {user ? user.authentication_key : ''}
              </Typography>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              color="secondary"
              onClick={(event) => onCloseClick(event)}
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(ApiCredentialDialog)