import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Toasts from '../Toasts'
import { useIdleTimer } from 'react-idle-timer'
import { store } from '../../../Store'
import MessageBar from '../MessageBar'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    borderRadius: '50%',
    flexGrow: 0,
    flexShrink: 0
  },
  sm: {
    height: theme.spacing(1),
    width: theme.spacing(1)
  },
  md: {
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  lg: {
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  neutral: {
    backgroundColor: theme.palette.neutral
  },
  primary: {
    backgroundColor: theme.palette.primary.main
  },
  info: {
    backgroundColor: theme.palette.info.main
  },
  warning: {
    backgroundColor: theme.palette.warning.main
  },
  danger: {
    backgroundColor: theme.palette.error.main
  },
  success: {
    backgroundColor: theme.palette.success.main
  }
}));

const Timeout = props => {
  const { className, size, color, ...rest } = props;
  const [remaining, setRemaining] = useState(0)
  const [open, setOpen] = useState(false)
  
  const classes = useStyles();

  const timeout = 21600000
  const promptBeforeIdle = 30000

  const logoutUser = () => {
    return { type: 'LOGOUT_USER' }
  };

  const onIdle = () => {
    store.dispatch(logoutUser())
  }

  const onAction = () => {
    activate()
    setOpen(false)
  }

  const onPrompt = () => {
    setOpen(true)
  }

  let timeoutbar = (
    <div></div>
  )
  if(open){
    timeoutbar = (
      <MessageBar
      severity={"error"}
      title={"Due to inactivity, you will be logged out in " + remaining + " seconds."}
    />
    )
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    onAction,
    // onPresenceChange,
    timeout, //1000 * 60 * .5,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <React.Fragment>
      {timeoutbar}
    </React.Fragment>
  );
};

export default Timeout;
