import React, {useState} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { AccountForm } from '../../../components/Account';
import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const AccountAdd = props => {
  const { onAccountSave, plan_level } = props;
  const [account] = useState({
    id: null,
    name: '',
    website: '',
    primaryContactEmail: ''
  })
  const classes = useStyles();

  const handleSubmitClick = (event, account) => {
    event.preventDefault();
  
    const accountData = {
      account: {
        id: account.id,
        name: account.name,
        website: account.website,
        primaryContactEmail: account.primaryContactEmail
      }
    }
    onAccountSave(accountData)
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving && !props.loading){
    spinner = null
  }

  let accountForm = <Redirect to={`/funders/${props.id}`} />
  if ( !props.id ) {
    accountForm = (
        <div className={classes.root}>
          <div className={classes.content}>
            <AccountForm account={account} onSubmitClick={handleSubmitClick} plan_level={plan_level} />
            {spinner}
          </div>
        </div>
      );
  }
  
  return accountForm;
};

const mapStateToProps = state => {
  return {
    saving: state.account.saving,
    loading: state.account.loading,
    id: state.account.id,
    token: state.auth.token,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAccountSave: (accountData) => dispatch(actions.addAccount(accountData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AccountAdd, axios))