import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  dashboard_message_enabled: false,
  dashboard_message_severity: 'info',
  dashboard_message_title: null,
  dashboard_message: null,
  loading: false,
  saving: false,
  error: null
}

const fetchAppSettingsStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchAppSettingsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    dashboard_message_enabled: action.appsettings.dashboard_message_enabled,
    dashboard_message_severity: action.appsettings.dashboard_message_severity,
    dashboard_message_title: action.appsettings.dashboard_message_title,
    dashboard_message: action.appsettings.dashboard_message
  })
}

const fetchAppSettingsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const updateAppSettingsStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateAppSettingsSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      dashboard_message_enabled: action.appsettingsData.dashboard_message_enabled,
      dashboard_message_severity: action.appsettingsData.dashboard_message_severity,
      dashboard_message_title: action.appsettingsData.dashboard_message_title,
      dashboard_message: action.appsettingsData.dashboard_message
  } );
};

const updateAppSettingsFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_APPSETTINGS_START:
      return fetchAppSettingsStart(state, action)
    case actionTypes.FETCH_APPSETTINGS_SUCCESS:
      return fetchAppSettingsSuccess(state, action)
    case actionTypes.FETCH_APPSETTINGS_FAIL:
      return fetchAppSettingsFail(state, action)
    case actionTypes.UPDATE_APPSETTINGS_START:
      return updateAppSettingsStart(state, action)
    case actionTypes.UPDATE_APPSETTINGS_SUCCESS:
      return updateAppSettingsSuccess(state, action)
    case actionTypes.UPDATE_APPSETTINGS_FAIL:
      return updateAppSettingsFail(state, action)
    default:
      return state
  }
}
    
export default reducer
