import { history } from '../Routes'
import { put, select, call } from 'redux-saga/effects'
import * as actions from '../store/actions'
import {store} from '../Store'

export function * addMerchantRedirect (payload) {
  try {
    yield history.push('/merchants/new')
  } catch (error) {
    console.log(error)
  }
}

export function * addNoteToMerchantRedirect (payload) {
  try {
    yield history.push(`/merchants/${payload.id}`)
  } catch (error) {
    console.log(error)
  }
}