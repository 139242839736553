import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import {authHeaders} from '../../../helpers'

import { CircularProgress, Backdrop, Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const TotalNotes = props => {
  const { account } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const [kpiValue, setKpiValue] = useState(0)
  
  useEffect(() => {
    setLoading(true)
    let url = `/widgets/total_notes`
    if (account){
      url = `/widgets/total_notes?account_id=${account}`
    }
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        setLoading(false)
        setKpiValue(response.data.data)
      }
      else {
        setLoading(false)
      }
    })
    .catch(error => {
      setLoading(false)
    })
    
    return () => {
      source.cancel()
    }
  }, [])

  let kpi = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    kpi = (
      <Card>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                TOTAL NOTES
              </Typography>
              <Typography variant="h3"><NumberFormat value={kpiValue} displayType={'text'} thousandSeparator={true} /></Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <NotesIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
  
  return (
    <React.Fragment>
      {kpi}
    </React.Fragment>
  );
};

export default withErrorHandler(TotalNotes, axios)