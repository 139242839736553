import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'
import {authHeaders} from '../../../helpers'

import { SimpleMerchantsTable } from '../../Merchant';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const RecentMerchantsByCategory = props => {
  const classes = useStyles();
  const { 
    category,
    perpage
  } = props

  const [loading, setLoading] = useState(true)
  const [merchants, setMerchants] = useState([])
  const [pagination, setPagination] = useState(0)
  const [page, setPage] = useState(0)
  const [per_page, setPerPage] = useState(perpage || 20)
  
  // useEffect(() => {
  //   onFetchRecentMerchantsByCategory(page, per_page, category);
    
  //   return () => {
  //     source.cancel()
  //   }
  // }, [onFetchRecentMerchantsByCategory, page, per_page, category])

  useEffect(() => {
    setLoading(true)
    let url = `/widgets/recent_merchants_by_category?page=${page + 1}&per_page=${per_page}&category=${category}`
    if (category === 'All'){
      url = `/widgets/recent_merchants_by_category?page=${page + 1}&per_page=${per_page}`
    }

    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchants = []
        for(let key in response.data.data){
          fetchedMerchants.push({
            ...response.data.data[key],
            id: key
          })
        }
        setLoading(false)
        setPagination(response.data.pagination)
        setMerchants(fetchedMerchants)
      }
      else {
        setLoading(false)
        // dispatch(fetchMerchantsFail('Unable to load merchants.'))
      }
    })
    .catch(error => {
      setLoading(false)
      // dispatch(fetchMerchantsFail(error.response.data.errors))
    })
    
    return () => {
      source.cancel()
    }
  }, [page, per_page, category])

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage)
  // }

  // const handlePerPageChange = (event) => {
  //   setPerPage(event.target.value)
  // }

  let merchantsTable = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    merchantsTable = (
      <SimpleMerchantsTable
        merchants={merchants}
        pagination={pagination}
        hidePagination
        // onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        // onPerPageChange={handlePerPageChange}
      />
    )
  }
  
  return (
    <Card>
      <CardHeader
        title={`Recently Reported ${category}`}
      />
      <Divider />
      {merchantsTable}
    </Card>
  );
};

export default withErrorHandler(RecentMerchantsByCategory, axios)