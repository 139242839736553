import * as actionTypes from './actionTypes'
import createToast from '../../factories/createToast'

export const addToast = (options = {}) => {
  return {
    type: actionTypes.ADD_TOAST,
    payload: createToast(options)
  };
}

export const removeToast = (id) => {
  return {
    type: actionTypes.REMOVE_TOAST,
    payload: id
  };
}