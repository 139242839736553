import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const clearMerchantSubscriptionImports = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_SUCCESS,
    merchant_subscription_imports: []
  }
}

export const fetchMerchantSubscriptionImportsSuccess = (merchant_subscription_imports) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_SUCCESS,
    merchant_subscription_imports: merchant_subscription_imports
  }
}

export const fetchMerchantSubscriptionImportsFail = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_FAIL,
    error: error
  }
}

export const fetchMerchantSubscriptionImportsStart = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_START
  }
}

export const fetchMerchantSubscriptionImports = (account_id) => { 
  return dispatch => {
    dispatch(fetchMerchantSubscriptionImportsStart())
    let url = `accounts/${account_id}/merchant_subscription_imports`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchantSubscriptionImports = []
        for(let key in response.data.data){
          fetchedMerchantSubscriptionImports.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchMerchantSubscriptionImportsSuccess(fetchedMerchantSubscriptionImports))
      }
      else {
        dispatch(fetchMerchantSubscriptionImportsFail('Unable to load merchant subscription imports.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchant subscription imports.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchMerchantSubscriptionImportsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}
