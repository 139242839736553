import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '40%'
  }
}));

const MembersTable = props => {
  const { 
    accounts,
  } = props;
  
  const classes = useStyles();
  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  
  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>Primary Contact</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map(account => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={account.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {account.name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <a href={`http://${account.website}`} target="_blank">{account.website}</a>
                    </TableCell>
                    <TableCell>
                      <a href={`mailto:${account.primary_contact_email}`} target="_blank">{account.primary_contact_email}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
    </React.Fragment>
  );
};

export default MembersTable;
