import { takeLatest } from 'redux-saga/effects';
import { agreedToTermsRedirect, subscriptionCreatedRedirect, subscriptionCanceledRedirect } from './auth';
import { searchFlow, searchResultsFlow } from './searchSaga';
import { addMerchantRedirect, addNoteToMerchantRedirect } from './merchantSaga';
import { addUserRedirect } from './userSaga';
import { addDisputeRedirect } from './disputeSaga';

import * as types from '../store/actions/actionTypes';


// export function * watchLogout() {
//   yield takeLatest(types.LOGOUT_USER, logoutFlow);
// }

// export function * watchLogin() {
//   yield takeLatest(types.LOGIN_USER, loginRedirect);
// }

export function * watchSearch() {
  yield takeLatest(types.SEARCH_MERCHANTS, searchFlow);
}

export function * watchSearchResults() {
  yield takeLatest(types.SEARCH_MERCHANTS_RESULTS, searchResultsFlow);
}

export function * watchAgreedToTerms() {
  yield takeLatest(types.AGREED_TO_TERMS, agreedToTermsRedirect);
}

export function * watchSubscriptionCreated() {
  yield takeLatest(types.SUBSCRIPTION_CREATED, subscriptionCreatedRedirect);
}

export function * watchAddMerchantRedirect() {
  yield takeLatest(types.INITIALIZE_ADD_MERCHANT, addMerchantRedirect);
}

export function * watchAddNoteToMerchantRedirect() {
  yield takeLatest(types.INITIALIZE_ADD_NOTE_TO_MERCHANT, addNoteToMerchantRedirect);
}

export function * watchAddUserRedirect() {
  yield takeLatest(types.ADD_USER_SUCCESS, addUserRedirect);
}

export function * watchSubscriptionCanceled() {
  yield takeLatest(types.SUBSCRIPTION_CANCELED, subscriptionCanceledRedirect);
}

export function * watchAddDisputeRedirect() {
  yield takeLatest(types.INITIALIZE_ADD_DISPUTE, addDisputeRedirect);
}
