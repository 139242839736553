import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../../store/actions'
import validate from 'validate.js';
import queryString from 'query-string'


import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from '@material-ui/core';

const schema = {
  agree: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  terms: {
    height: '400px',
    overflow: 'scroll'
  }
}));

const MFAVerification  = (props) => {
  const { 
    onMFAVerificationSubmit,
    error,
    id,
    token
  } = props

  const [code, setCode] = useState('')
  const [remember, setRemember] = useState('')

  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setCode(null)
    setRemember(true)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setRemember(event.target.checked)
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let valid = (code && code.length == 6);
    
    if(valid){
      let data = {
        code: code,
        remember: remember
      }
  
      onMFAVerificationSubmit(token, data);
    } else {
      alert("Code must be 6 digits")
    }
  }

  const handleInputChange = (event, field) => {
    switch(field){
      case 'code':
        setCode(event.target.value)
        break
      default:
        return null
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Authenticate your account" />
          <Divider />
          <CardContent className={classes.terms}>
          <Typography variant="subtitle2">
            Enter 6-digit code from your two factor authenticator app.
          </Typography>
          <TextField
            margin="dense"
            name="code"
            onChange={(event) => handleInputChange(event, 'code')}
            required
            value={code || ''}
            variant="outlined"
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={remember}
                  value="remember"
                  name="remember"
                  color="primary"
                />
              }
              label="Remember this device"
            />
          </FormGroup>
          </CardContent>
          <Divider />
          <CardActions>
            <form
              onSubmit={handleSubmit}
            >
              <Button
                className={classes.signInButton}
                color="primary"
                // disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Verify
              </Button>
            </form>
          </CardActions>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    id: state.auth.user.resource_id
  }
}

const mapDispatchToProps = dispatch => ({
  onMFAVerificationSubmit: (token, data) => dispatch(actions.verifyOtp(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MFAVerification);
