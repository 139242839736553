import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { TableContainer } from '@material-ui/core';

import CategoryChip from '../../UI/CategoryChip'

import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

const SearchResults = props => {
  const { 
    merchants
  } = props;
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    content: {
      padding: 0
    },
    actions: {
      justifyContent: 'flex-end'
    }
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>EIN/BN</TableCell>
                  <TableCell>Added By</TableCell>
                  <TableCell>Added</TableCell>
                  <TableCell>Latest Note Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchants.map(merchant => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={merchant.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          <Link to={`/merchants/${merchant.resource_id}`}>{merchant.legal_name}</Link>
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      {merchant.fein}
                    </TableCell>
                    <TableCell>
                      {merchant.added_by}
                    </TableCell>
                    <TableCell>
                      {moment(merchant.created_at).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      <CategoryChip category={merchant.last_note_category} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
    </React.Fragment>
  );
};

export default SearchResults;
