import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button,
  CardHeader,
  Typography,
  CardContent,
  FormControlLabel,
  Switch
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

const useStyles = makeStyles((theme) => ({
  root: {},
  trial_button: {
    backgroundColor: theme.palette.orange,
    color: theme.palette.white,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.orange,
      color: theme.palette.white
    }
  },
  activate_button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.green,
      color: theme.palette.white
    }
  },
  noUnderline: {
    textDecoration: 'none'
  }
}));

const AccountSecurity = props => {
  const { className, onMfaRequiredToggle, account, ...rest } = props;
  const classes = useStyles();

  // const isRequired = (account.mfa_required === true)

  let content = null

  content = 
    <React.Fragment>
      <CardContent>
        <FormControlLabel
              control={<Switch checked={account.mfa_required} onChange={(event) => onMfaRequiredToggle(event, 'mfaRequired')} name="mfa_required" color="primary" />}
              label="Require Multi-Factor Authentication"
            />
      </CardContent>
      <Divider />
    </React.Fragment>

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Multi-Factor Authentication"
      />
      <Divider />
      {content}
    </Card>
  );
};


export default withErrorHandler(AccountSecurity, axios)