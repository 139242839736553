import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import { history } from '../../Routes'
import {addToast} from './toasts'

export const fetchInvoicesSuccess = (invoices, pagination) => {
  return {
    type: actionTypes.FETCH_INVOICES_SUCCESS,
    invoices: invoices,
    pagination: pagination
  }
}

export const fetchInvoicesFail = (error) => {
  return {
    type: actionTypes.FETCH_INVOICES_FAIL,
    error: error
  }
}

export const fetchInvoicesStart = () => {
  return {
    type: actionTypes.FETCH_INVOICES_START
  }
}

export const fetchInvoices = (token, accountId) => {
  return dispatch => {
    dispatch(fetchInvoicesStart())
    let url = `accounts/${accountId}/billing/invoices`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedInvoices = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedInvoices.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchInvoicesSuccess(fetchedInvoices, pagination))
      }
      else {
        dispatch(fetchInvoicesFail('Unable to load invoices.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve invoices.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchInvoicesFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}
