import React from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../hocs/withErrorHandler'
import axios from '../../httpClients/axios-datamerch'

import { SearchResults } from '../../components/Merchant';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';
import {MessageBar} from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const Search = props => {
  const classes = useStyles();
  const { 
    merchants,
    loading,
    user
  } = props

  let searchResults = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!loading){
    searchResults = (
      <SearchResults
        merchants={merchants}
      />
    )
  }

  let results_limit = 5;
  let limit_message = 'More than 5 merchants match your search criteria.  Please narrow your keyword search.'

  if (user.plan_level === 'PRO'){
    results_limit = 25
    limit_message = 'More than 25 merchants match your search criteria.  Please narrow your keyword search.'
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
      { merchants && merchants.length === results_limit ?
      <MessageBar
          severity="warning"
          title="Multiple merchants were found matching your search"
          message={limit_message}
        />
        : null }
      </div>
      <div className={classes.content}>
        <Card>
          <CardHeader
            title="Merchants"
          />
          <Divider />
          {searchResults}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchants: state.merchant.merchants,
    loading: state.merchant.loading,
    token: state.auth.token,
    user: state.auth.user
  }
}

export default connect(mapStateToProps)(withErrorHandler(Search, axios))