import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  merchants: [],
  merchant: {
    fein: '',
    legal_name: '',
    dba: '',
    city: '',
    state: '',
    business_phone: '',
    business_startdate: '',
    industry: '',
    category: '',
    note: '',
    submission_source: '',
    broker_name: ''
  },
  id: null,
  dialog_id: null,
  saving: false,
  error: null,
  flash: null,
  pagination: {
    current_page: 0,
    per_page: 25,
    total_pages: 1,
    total_count: 0
  },
  searched: false,
  merchant_not_found_dialog_open: false,
  merchant_exists_dialog_open: false,
  merchant_delete_modal_open: false,
  reason: '',
  search: null
}

const fetchMerchantsStart = (state, action) => {
  return updateObject(state, {loading: true, merchants: [], merchant: null, search: null})
}

const fetchMerchantsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchants: action.merchants,
    pagination: action.pagination
  })
}

const fetchMerchantsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchMerchantStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchMerchantSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant: action.merchant
  })
}

const fetchMerchantFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const addMerchantStart = (state, action) => {
  return updateObject(state, {saving: true, search: null})
}

const addMerchantSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const addMerchantFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const updateMerchantStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateMerchantSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const updateMerchantFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const archiveMerchantStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const archiveMerchantSuccess = ( state, action ) => {
  const updatedMerchants = state.merchants.filter(merchant => merchant.resource_id !== action.id)
  
  return updateObject( state, {
      loading: false,
      saving: false,
      merchants: updatedMerchants,
      merchant_delete_modal_open: false
  } );
};

const archiveMerchantFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const searchMerchantsStart = (state, action) => {
  return updateObject(state, {loading: true, merchants: [], merchant: null, search: null})
}

const searchMerchantsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchants: action.merchants,
    searched: true
  })
}

const searchMerchantsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    merchants: [],
    searched: true
  })
}

const searchMerchantsReset = (state, action) => {
  return updateObject(state, {
    searched: false,
    search: null
  })
}

const openMerchantNotFoundDialog = (state, action) => {
  return updateObject(state, {
    merchant_not_found_dialog_open: true,
    search: action.search
  })
}

const closeMerchantNotFoundDialog = (state, action) => {
  return updateObject(state, {
    merchant_not_found_dialog_open: false,
    search: null
  })
}

const openMerchantExistsDialog = (state, action) => {
  return updateObject(state, {
    merchant_exists_dialog_open: true,
    dialog_id: action.id
  })
}

const closeMerchantExistsDialog = (state, action) => {
  return updateObject(state, {
    merchant_exists_dialog_open: false
  })
}

const openMerchantDeleteModal = (state, action) => {
  return updateObject(state, {
    merchant_delete_modal_open: true,
    dialog_id: action.id,
    reason: action.reason
  })
}

const closeMerchantDeleteModal = (state, action) => {
  return updateObject(state, {
    merchant_delete_modal_open: false
  })
}

const toggleMerchantSubscriptionStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const toggleMerchantSubscriptionSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant: action.merchant
  })
}

const toggleMerchantSubscriptionFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const initializeAddMerchant = (state, action) => {
  return updateObject(state, {
    merchant_not_found_dialog_open: false,
    merchant_exists_dialog_open: false,
    merchant: {
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      state: '',
      business_phone: '',
      business_startdate: '',
      industry: '',
      category: '',
      note: '',
      submission_source: '',
      broker_name: ''
    },
    merchants: [],
    id: null,
    dialog_id: null,
    loading: false,
    saving: false
  })
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_MERCHANTS_START:
      return fetchMerchantsStart(state, action)
    case actionTypes.FETCH_MERCHANTS_SUCCESS:
      return fetchMerchantsSuccess(state, action)
    case actionTypes.FETCH_MERCHANTS_FAIL:
      return fetchMerchantsFail(state, action)
    case actionTypes.FETCH_MERCHANT_START:
      return fetchMerchantStart(state, action)
    case actionTypes.FETCH_MERCHANT_SUCCESS:
      return fetchMerchantSuccess(state, action)
    case actionTypes.FETCH_MERCHANT_FAIL:
      return fetchMerchantFail(state, action)
    case actionTypes.UPDATE_MERCHANT_START:
      return updateMerchantStart(state, action)
    case actionTypes.UPDATE_MERCHANT_SUCCESS:
      return updateMerchantSuccess(state, action)
    case actionTypes.UPDATE_MERCHANT_FAIL:
      return updateMerchantFail(state, action)
    case actionTypes.ADD_MERCHANT_START:
      return addMerchantStart(state, action)
    case actionTypes.ADD_MERCHANT_SUCCESS:
      return addMerchantSuccess(state, action)
    case actionTypes.ADD_MERCHANT_FAIL:
      return addMerchantFail(state, action)
    case actionTypes.ARCHIVE_MERCHANT_START:
      return archiveMerchantStart(state, action)
    case actionTypes.ARCHIVE_MERCHANT_SUCCESS:
      return archiveMerchantSuccess(state, action)
    case actionTypes.ARCHIVE_MERCHANT_FAIL:
      return archiveMerchantFail(state, action)
    case actionTypes.SEARCH_MERCHANTS_START:
      return searchMerchantsStart(state, action)
    case actionTypes.SEARCH_MERCHANTS_SUCCESS:
      return searchMerchantsSuccess(state, action)
    case actionTypes.SEARCH_MERCHANTS_FAIL:
      return searchMerchantsFail(state, action)
    case actionTypes.SEARCH_MERCHANTS_RESET:
      return searchMerchantsReset(state, action)
    case actionTypes.OPEN_MERCHANT_NOT_FOUND_DIALOG:
      return openMerchantNotFoundDialog(state, action)
    case actionTypes.CLOSE_MERCHANT_NOT_FOUND_DIALOG:
      return closeMerchantNotFoundDialog(state, action)
    case actionTypes.OPEN_MERCHANT_EXISTS_DIALOG:
      return openMerchantExistsDialog(state, action)
    case actionTypes.CLOSE_MERCHANT_EXISTS_DIALOG:
      return closeMerchantExistsDialog(state, action)
    case actionTypes.INITIALIZE_ADD_MERCHANT:
      return initializeAddMerchant(state, action)
    case actionTypes.OPEN_MERCHANT_DELETE_MODAL:
      return openMerchantDeleteModal(state, action)
    case actionTypes.CLOSE_MERCHANT_DELETE_MODAL:
      return closeMerchantDeleteModal(state, action)
    case actionTypes.TOGGLE_MERCHANT_SUBSCRIPTION_START:
      return toggleMerchantSubscriptionStart(state, action)
    case actionTypes.TOGGLE_MERCHANT_SUBSCRIPTION_SUCCESS:
      return toggleMerchantSubscriptionSuccess(state, action)
    case actionTypes.TOGGLE_MERCHANT_SUBSCRIPTION_FAIL:
      return toggleMerchantSubscriptionFail(state, action)
    default:
      return state
  }
}
    
export default reducer
