import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../hocs/withErrorHandler'
import axios from '../../httpClients/axios-datamerch'

import * as actions from '../../store/actions'

import DashboardMessage from './DashboardMessage'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AppSettings = props => {
  const {
    className,
    appSettings,
    onSubmitClick,
    modalOpen,
    token,
    profile,
    mode,
    ...rest
  } = props;

  const [dashboardMessageEnabled, setDashboardMessageEnabled] = useState('')
  const [dashboardMessageSeverity, setDashboardMessageSeverity] = useState('')
  const [dashboardMessageTitle, setDashboardMessageTitle] = useState('')
  const [dashboardMessage, setDashboardMessage] = useState('')

  const classes = useStyles();

  useEffect(() => {
    setDashboardMessageEnabled(appSettings.dashboard_message_enabled)
    setDashboardMessageSeverity(appSettings.dashboard_message_severity)
    setDashboardMessageTitle(appSettings.dashboard_message_title)
    setDashboardMessage(appSettings.dashboard_message)
  },[appSettings])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'dashboardMessageEnabled':
        setDashboardMessageEnabled(event.target.checked)
        break
      case 'dashboardMessageSeverity':
        setDashboardMessageSeverity(event.target.value)
        break
      case 'dashboardMessageTitle':
        setDashboardMessageTitle(event.target.value)
        break
      case 'dashboardMessage':
        setDashboardMessage(event.target.value)
        break
      default:
        return null
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
      >
        <DashboardMessage
          enabled={dashboardMessageEnabled}
          severity={dashboardMessageSeverity}
          title={dashboardMessageTitle}
          message={dashboardMessage}
          onChange={(event, field) => handleInputChange(event, field)}
        />
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={(event, user) => onSubmitClick(event, {
              dashboardMessageEnabled: dashboardMessageEnabled,
              dashboardMessageSeverity: dashboardMessageSeverity,
              dashboardMessageTitle: dashboardMessageTitle,
              dashboardMessage: dashboardMessage
            })}
          >
            Save Settings
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    appSettings: state.appSettings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AppSettings, axios))