import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { asPercent } from '../../../helpers';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'fein', headerName: 'EIN', width: 500 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  processed_status_processed: {
    color: theme.palette.green,
    marginLeft: '20px'
  },
}));

const MerchantSubscriptionUploadsTable = props => {
  const { 
    merchant_subscription_uploads
  } = props;
  
  const classes = useStyles();

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  useEffect(() => {
  }, [merchant_subscription_uploads])

  const renderFileSize = (file_size) => {
    let unit = 'bytes'

    if (Math.floor(file_size / 1073741824) > 0){
      file_size = Math.round(((file_size / 1073741824) + Number.EPSILON) * 100) / 100
      unit = 'GB'
      return `${file_size} ${unit}`
    }

    if (Math.floor(file_size / 1048576) > 0){
      file_size = Math.round(((file_size / 1048576) + Number.EPSILON) * 100) / 100
      unit = 'MB'
      return `${file_size} ${unit}`
    }

    if (Math.floor(file_size / 1024) > 0){
      file_size = Math.round(((file_size / 1024) + Number.EPSILON) * 100) / 100
      unit = 'KB'
    }
    
    return `${file_size} ${unit}`
  }

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
          <Table>
               <TableHead>
                <TableRow>
                  <TableCell>Uploaded</TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Processed</TableCell>
                  <TableCell colSpan={4}>Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchant_subscription_uploads && merchant_subscription_uploads.map(merchant_subscription_upload => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={merchant_subscription_upload.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {moment(merchant_subscription_upload.created_at).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {merchant_subscription_upload.file_name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {renderFileSize(merchant_subscription_upload.file_size)}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {merchant_subscription_upload.status}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {merchant_subscription_upload.processed && 
                          <CheckCircleOutlineIcon className={classes.processed_status_processed} />
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {merchant_subscription_upload.processed && 
                          <span>Created: {merchant_subscription_upload.imported}</span>
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {merchant_subscription_upload.processed && 
                          <span>Skipped: {merchant_subscription_upload.skipped}</span>
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {merchant_subscription_upload.processed && 
                          <span>Errors: {merchant_subscription_upload.error}</span>
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {merchant_subscription_upload.results_file_url && merchant_subscription_upload.results_file_url.length > 0 &&
                          <Button
                            color="primary"
                            component="a"
                            href={"https://datamerch-imports.s3.amazonaws.com/"+ merchant_subscription_upload.results_file_url}
                            variant="contained"
                          >
                            Download Results
                          </Button>
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
    </React.Fragment>
  );
};

export default MerchantSubscriptionUploadsTable;
