import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { asPercent } from '../../../helpers';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'fein', headerName: 'EIN', width: 500 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  processed_status_processed: {
    color: theme.palette.green,
    marginLeft: '20px'
  },
  success: {
    color: theme.palette.green
  },
  failed: {
    color: theme.palette.red
  }
}));

const MerchantSubscriptionWebhookLog = props => {
  const { 
    merchant_subscription_webhooks
  } = props;
  
  const classes = useStyles();

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  useEffect(() => {
  }, [merchant_subscription_webhooks])

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
          <Table>
               <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>EIN</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Next Retry</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchant_subscription_webhooks && merchant_subscription_webhooks.map(merchant_subscription_webhook => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={merchant_subscription_webhook.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" className={merchant_subscription_webhook.status === 'failed' ? classes.failed : classes.success}>
                          {moment(merchant_subscription_webhook.created_at).format('MM/DD/YYYY HH:MM:SS')}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" className={merchant_subscription_webhook.status === 'failed' ? classes.failed : classes.success}>
                          {merchant_subscription_webhook.ein}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" className={merchant_subscription_webhook.status === 'failed' ? classes.failed : classes.success}>
                          {merchant_subscription_webhook.action}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" className={merchant_subscription_webhook.status === 'failed' ? classes.failed : classes.success}>
                          {merchant_subscription_webhook.status}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" className={merchant_subscription_webhook.status === 'failed' ? classes.failed : classes.success}>
                          {merchant_subscription_webhook.status === 'failed' ? moment(merchant_subscription_webhook.next_retry).format('MM/DD/YYYY HH:MM:SS') : ''}
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
    </React.Fragment>
  );
};

export default MerchantSubscriptionWebhookLog;
