import React from 'react';
import useHttpErrorHandler from '../../hooks/http-error-handler'
import MuiAlert from '@material-ui/lab/Alert';
import { history } from '../../Routes'



const withErrorHandler = ( WrappedComponent, axios ) => {
    const Alert = (props) =>  {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    return props => {
      const [error] = useHttpErrorHandler(axios)
      if (error) {
        if (error && error.response && error.response.status === 401) {
          window.localStorage.removeItem('token')
          window.scrollTo(0, 0)
          history.push(`/login`)
          // auth.logout();
          // router.replace('/auth/login');
        }
        else {
          return (
            <React.Fragment>
                <WrappedComponent {...props} />
            </React.Fragment>
          );
        }
        // return Promise.reject(error.response);
      }
      
      
      
      
        // if (error && error.message){
        //   console.log(error)
          
        // }

      return (
        <React.Fragment>
            <WrappedComponent {...props} />
        </React.Fragment>
      );
      
    }
}

export default withErrorHandler