import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const fetchSearchCountsSuccess = (search_counts) => {
  return {
    type: actionTypes.FETCH_SEARCH_COUNTS_SUCCESS,
    search_counts: search_counts
  }
}

export const fetchSearchCountsFail = (error) => {
  return {
    type: actionTypes.FETCH_SEARCH_COUNTS_FAIL,
    error: error
  }
}

export const fetchSearchCountsStart = () => {
  return {
    type: actionTypes.FETCH_SEARCH_COUNTS_START
  }
}

export const resetSearchCounts = () => {
  return {
    type: actionTypes.RESET_SEARCH_COUNTS
  }
}

export const fetchSearchCounts = (search) => {
  return dispatch => {
    dispatch(fetchSearchCountsStart())
    let url = `/search_counts?search=${search}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedSearchCounts = []
        
        for(let key in response.data.data){
          fetchedSearchCounts.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchSearchCountsSuccess(fetchedSearchCounts))
      } else {
        dispatch(fetchSearchCountsFail('An error occurred while attempting to retrieve search counts.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve search counts.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchSearchCountsFail(error.response.data.errors))
    })
  }
}
