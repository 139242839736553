import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import {addToast} from '../../../store/actions/toasts' 
import { store } from '../../../Store'
import { history } from '../../../Routes'


import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { UserForm } from '../../../components/User';

import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  },
  members: {
    marginTop: '30px'
  }
}));

const UserEdit = props => {
  const classes = useStyles();

  const validateEmail = (email, user_account_primary_email_split) => {
    if (is_dm_admin){
      return true;
    }

    return email.includes(user_account_primary_email_split[1])
  }

  const { onFetchUser, onSaveError, user, onUserSave, token, is_dm_admin, user_account_primary_email } = props
  const { id } = props.match.params
  
  useEffect(() => {
    onFetchUser(token, id);
  }, [onFetchUser, token, id])

  const handleSubmitClick = (event, user) => {
    event.preventDefault();
    let user_account_primary_email_split = user_account_primary_email.split('@')

    if (user_account_primary_email_split.length < 2){
      history.push(`/logout`)
    } else {
      if (validateEmail(user.email, user_account_primary_email_split)) {
        const userData = {
          user: {
            id: user.id,
            accountId: user.accountId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }
        onUserSave(token, userData, id)
      } else {
        onSaveError({
          message: `Only email addresses ending in @${user_account_primary_email_split[1]} are valid.`,
          severity: 'error',
          duration: null
        })
        return false;
      }
    }

    

    // const userData = {
    //   user: {
    //     id: user.id,
    //     accountId: user.accountId,
    //     firstName: user.firstName,
    //     lastName: user.lastName,
    //     email: user.email
    //   }
    // }
    // onUserSave(token, userData, id)
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving && !props.loading){
    spinner = null
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <UserForm user={user} onSubmitClick={handleSubmitClick} />
        {spinner}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    saving: state.user.saving,
    loading: state.user.loading,
    token: state.auth.token,
    user_account_primary_email: state.auth.user.account_primary_email,
    is_dm_admin: state.auth.user.is_dm_admin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchUser: (token, id) => dispatch(actions.fetchUser(token, id)),
    onUserSave: (token, userData, id) => dispatch(actions.updateUser(token, userData, id)),
    onSaveError: (options) => dispatch(actions.addToast(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(UserEdit, axios))