import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, InputBase, Input, InputAdornment, IconButton, Button, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputMask from "react-input-mask";

import {numbersOnly} from '../../../helpers'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
    borderColor: theme.palette.blue,
    borderWidth: '2px',
    borderStyle: 'solid'
  },
  icon: {
    color: theme.palette.text.secondary
  },
  input: {
    '&::placeholder': {
      color: theme.palette.blue
    },
    flexGrow: 1,
    fontSize: '13px',
    lineHeight: '13px',
    letterSpacing: '-0.05px',
    padding: '1px 0 1px',
    margin: '0px',
  },
  iconButton: {
    padding: '5px 0px 5px 14px',
    minWidth: '0px',
    backgroundColor: theme.palette.blue,
    color: theme.palette.white
  },
  iconButtonRed: {
    padding: '5px 0px 5px 14px',
    minWidth: '0px',
    backgroundColor: theme.palette.red,
    color: theme.palette.white
  },
  iconButtonYellow: {
    padding: '5px 0px 5px 14px',
    minWidth: '0px',
    backgroundColor: theme.palette.yellow
  },
  divider: {
    height: 28,
    margin: 2,
  }
}));

const SearchInput = props => {
  const { className, onChange, style, onSearchClick, onSearchChange, placeholder, user, ...rest } = props;

  const classes = useStyles();

  const [search, setSearch] = useState('')

  const handleMerchantSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const buttonWithColor = () => {
    if (user.account_contribution_locked && !user.account_contribution_override) {
      return classes.iconButtonRed
    } else {
      if (user.account_contribution_warning_date && !user.account_contribution_override){
        return classes.iconButtonYellow
      } else {
        return classes.iconButton
      }
    }
  }

  return (
    <form autoComplete="off">
      <Paper
        {...rest}
        className={clsx(classes.root, className)}
        style={style}
      >
        {/*<InputMask 
          mask="99-9999999"
          value={search}
          disabled={false}
          maskChar=" "
          onChange={handleMerchantSearchChange}
        >
          {(inputProps) => <InputBase
            {...inputProps}
            className={classes.input}
            placeholder={placeholder}
            type="text"
          />}
          </InputMask> */}

<InputBase
            
            className={classes.input}
            placeholder={placeholder}
            type="text"
            value={search}
          disabled={false}
          onChange={handleMerchantSearchChange}
          />
        
        
        {/* <Button
          type="submit"
          className={buttonWithColor()}
          aria-label="search"
          variant="contained"
          size="small"
          color="inherit"
          onClick={(event) => onSearchClick(event, search)}
          startIcon={<SearchIcon />}
        >
          &nbsp;
        </Button> */}
        <Button
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          variant="contained"
          size="small"
          color="inherit"
          onClick={(event) => onSearchClick(event, search)}
          startIcon={<SearchIcon />}
        >
          &nbsp;
        </Button>
      </Paper>
    </form>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
