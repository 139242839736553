import React from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'
import { makeStyles } from '@material-ui/styles';

import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog_text: {
    fontSize: '16px',
    lineHeight: '30px'
  }
}));

const MerchantSubscriptionDeleteDialog = props => {
  const { 
    openDialog,
    onCloseDialog,
    onContinueDialog
  } = props;
  const classes = useStyles();
  
  const handleDialogClose = () => {
    onCloseDialog()
  };

  const handleChooseContinue = () => {
    onContinueDialog()
  };



  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Merchant Subscription(s)"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialog_text}>
          Are you sure you want to delete the selected merchant subscription(s)
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleChooseContinue}
          variant="contained"
          color="primary"
          autoFocus
        >
          Yes, delete merchant subscriptions
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    openDialog: state.merchantSubscription.merchant_subscription_delete_modal_open
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseDialog: () => dispatch(actions.closeMerchantSubscriptionDeleteDialog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSubscriptionDeleteDialog)
