import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: { marginLeft: '40px'},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.blue,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.white,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.white,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.white
    }
  },
  footerdisclosure: {
    fontSize: '12px'
  },
  footerdisclosureparagraph: {
    marginBottom: '20px'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const year = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <RouterLink to="/developers">
            <Button
              className={classes.button}
            >
              Developers
            </Button>
          </RouterLink>
          <RouterLink to="/terms_of_use">
            <Button
              className={classes.button}
            >
              Terms of Use
            </Button>
          </RouterLink>
          <RouterLink to="/privacy_policy">
            <Button
              className={classes.button}
            >
              Privacy Policy
            </Button>
          </RouterLink>
          <RouterLink to="/dispute_process">
            <Button
              className={classes.button}
            >
              Dispute Process
            </Button>
          </RouterLink>
          <RouterLink to="/pro" target="_blank">
            <Button
              className={classes.button}
            >
              DataMerch Pro
            </Button>
          </RouterLink>
        </Grid>
        <Grid
          item
          lg={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <Typography>
            &copy;{' '}
            DataMerch
            &nbsp;&nbsp;{year}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <div className={classes.footerdisclosure}>
            <Typography className={classes.footerdisclosureparagraph}>
              DataMerch LLC or its members do not constitute a "consumer report" as that term is defined in the federal Fair Credit Reporting Act, 15 USC 1681 et seq. (FCRA).  DataMerch LLC only accepts, stores, and distributes data on commercial entities.  User is prohibited from providing information on individual persons or consumers.   Information listed on DataMerch LLC may not be used in whole or in part as a factor in determining eligibility for personal credit, insurance, employment or another permissible purpose under the FCRA.  It is the policy of DataMerch LLC to view sole-proprietors, single-shareholder corporations, single-member partnerships and single-member companies as commercial entities and never as individuals or consumers.
            </Typography>
            <Typography className={classes.footerdisclosureparagraph}>
              Due to the nature of the origin of public record information, the public records and commercially available data sources used in reports may contain errors. Source data is sometimes reported or entered inaccurately, processed poorly or incorrectly, and is generally not free from defect. This product or service aggregates and reports data, as provided by the public records and commercially available data sources, and is not the source of the data, nor is it a comprehensive compilation of the data. Before relying on any data, it should be independently verified.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
