import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'
import {authHeaders} from '../../../helpers'

import Chart from './Chart';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const SearchesByDay = props => {
  const { account } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    labels: [],
    api_searches: [],
    web_searches: [],
    hits: []
  })
  
  useEffect(() => {
    setLoading(true)
    let url = `/widgets/searches_by_day?days=30`
    if (account){
      url = `/widgets/searches_by_day?days=30&account_id=${account}`
    }
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const labels = []
        const api_searches = []
        const web_searches = []
        const hits = []
        for(let key in response.data.data.daily_searches){
          labels.push(response.data.data.daily_searches[key].daily_search_date)
          api_searches.push(response.data.data.daily_searches[key].total_api_searches)
          web_searches.push(response.data.data.daily_searches[key].total_web_searches)
          hits.push(response.data.data.daily_searches[key].total_hits)
        }
        setLoading(false)
        setData({
          labels: labels,
          api_searches: api_searches,
          web_searches: web_searches,
          hits: hits
        })
      }
      else {
        setLoading(false)
      }
    })
    .catch(error => {
      setLoading(false)
    })
    
    return () => {
      source.cancel()
    }
  }, [])

  let chart = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    chart = (
      <Chart
        data={data}
      />
    )
  }
  
  return (
    <Card>
      <CardHeader
        title={`Searches By Day (Last 30 days)`}
      />
      <Divider />
      {chart}
    </Card>
  );
};

export default withErrorHandler(SearchesByDay, axios)