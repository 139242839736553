import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { MerchantSubscriptionWebhookLog } from '../../../components/MerchantSubscription';
import {MessageBar} from '../../../components'
import { 
  CircularProgress,
  Backdrop,
  Card,
  CardHeader,
  Divider,
  Tabs,
  Tab,
  Typography,
  Box,
  AppBar
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const MerchantSubscriptionWebhookLogs = props => {
  const classes = useStyles();
  
  const { 
    onFetchMerchantSubscriptionWebhookLogs,
    merchant_subscription_webhook_logs,
    token,
    loading,
    pagination,
    account_id
  } = props
  
  // let merchant_subscriptions = props.merchant_subscriptions
  // let loading = props.loading

  const [page, setPage] = useState(0)
  const [per_page, setPerPage] = useState(25)
  const [isFiltered, setIsFiltered] = useState(false)
  const [deleteAll, setDeleteAll] = useState(false)
  const [value, setValue] = useState(0);

  const [table_merchant_subscription_webhook_logs, setTableMerchantSubscriptionWebhookLogs] = useState(merchant_subscription_webhook_logs)
  
  useEffect(() => {
    onFetchMerchantSubscriptionWebhookLogs(account_id, page, per_page);
  }, [onFetchMerchantSubscriptionWebhookLogs, account_id, page, per_page])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value)
  }

  useEffect(() => {
    setTableMerchantSubscriptionWebhookLogs(merchant_subscription_webhook_logs)
  }, [merchant_subscription_webhook_logs])

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  let merchantSubscriptionWebhookLogsTable = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

  if(!loading){
    merchantSubscriptionWebhookLogsTable = (
      <MerchantSubscriptionWebhookLog
        merchant_subscription_webhooks={table_merchant_subscription_webhook_logs}
        pagination={pagination}
        onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        onPerPageChange={handlePerPageChange}
      />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
        <AppBar position="static" color="default">
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value="0"
            >
              <Tab label="Merchant Subscriptions" href="merchant_subscriptions"/>
              <Tab label="Uploads" href="merchant_subscription_imports" />
              <Tab label="Webhook Logs" href="merchant_subscription_webhook_logs" className={classes.active_tab} value="0"/>
            </Tabs>
          </AppBar>
          <Card>
            <Divider />
             {merchantSubscriptionWebhookLogsTable}
          </Card>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchant_subscription_webhook_logs: state.merchantSubscriptionWebhook.merchant_subscription_webhooks,
    loading: state.merchantSubscriptionWebhook.loading,
    token: state.auth.token,
    pagination: state.merchantSubscriptionWebhook.pagination,
    account_id: state.auth.user.account_resource_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchMerchantSubscriptionWebhookLogs: (account_id, page, per_page) => dispatch(actions.fetchMerchantSubscriptionWebhooks(account_id, page, per_page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantSubscriptionWebhookLogs, axios))