import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import NoteInfo from '../NoteInfo'

const useStyles = makeStyles(() => ({
  root: {}
}));

const NoteDialog = props => {
  const { className, merchantNote, open, mode, is_dm_admin, loading, accounts, merchant_add, onCancelClick, onSubmitClick, plan_level } = props;
  const [id, setId] = useState(merchantNote.id)
  const [merchant_id] = useState(merchantNote.merchant_id)
  const [noteCategory, setNoteCategory] = useState(merchantNote.category)
  const [noteSubmissionSource, setNoteSubmissionSource] = useState(merchantNote.submission_source)
  const [noteBrokerName, setNoteBrokerName] = useState(merchantNote.broker_name)
  const [proxied_creator, setProxiedCreator] = useState('')
  const [show_broker_name, setShowBrokerName] = useState(false)
  const [noteNote, setNoteNote] = useState(merchantNote.note)
  
  const classes = useStyles();

  useEffect(() => {
    setId(merchantNote.id)
    setNoteCategory(merchantNote.category)
    setNoteNote(merchantNote.note)
    setNoteSubmissionSource(merchantNote.submission_source)
    setNoteBrokerName(merchantNote.broker_name)
    setProxiedCreator(merchantNote.proxiedCreator)
    if (merchantNote.submission_source === 'broker'){
      setShowBrokerName(true)
    } else {
      setShowBrokerName(false)
    }
  },[merchantNote,accounts])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'category':
        setNoteCategory(event.target.value)
        break
      case 'note':
        setNoteNote(event.target.value)
        break
      case 'proxied_creator':
        setProxiedCreator(event.target.value)
        break
      case 'submission_source':
        setNoteSubmissionSource(event.target.value)
        setNoteBrokerName(null)
        if (event.target.value === 'broker'){
          setShowBrokerName(true)
        } else {
          setShowBrokerName(false)
        }
        break
      case 'broker_name':
        setNoteBrokerName(event.target.value)
        break
      default:
        return null
    }
  };

  return (
    <Dialog
      className={clsx(classes.root, className)}
      open={open}
      onClose={onCancelClick}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{mode==='add' ? 'Add Note' : 'Edit Note'}</DialogTitle>
      <Divider />
      <DialogContent>
        <form
          autoComplete="off"
        >
          <NoteInfo
            merchant_id={merchant_id}
            category={noteCategory}
            note={noteNote}
            proxied_creator = {proxied_creator}
            is_dm_admin={is_dm_admin}
            loading={loading}
            submission_source={noteSubmissionSource}
            broker_name={noteBrokerName}
            accounts={accounts}
            merchant_add={merchant_add}
            mode={mode}
            onChange={(event, field) => handleInputChange(event, field)}
            plan_level={plan_level}
            show_broker_name={show_broker_name}
          />
          <DialogActions>
            <Button
              color="secondary"
              onClick={(event) => onCancelClick(event)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(event, note) => {
                const form = event.currentTarget.form
                if (form.reportValidity()){
                  onSubmitClick(event, {
                    id: id,
                    merchant_id: merchant_id,
                    category: noteCategory,
                    proxied_creator: proxied_creator,
                    note: noteNote,
                    submission_source: noteSubmissionSource,
                    broker_name: noteBrokerName
                  })}
                }
              }
              type="submit"
            >
              Save note
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};


export default withErrorHandler(NoteDialog, axios)