import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'
import {authHeaders} from '../../../helpers'

import Chart from './Chart';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const HitsByCategory = props => {
  const { account } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    labels: ['API', 'Web'],
    total_api_searches: 0,
    percent_api_searches: 0,
    total_web_searches: 0,
    percent_web_searches: 0
  })
  
  useEffect(() => {
    setLoading(true)
    let url = `/widgets/hits_by_category?days=30`
    if (account){
      url = `/widgets/hits_by_category?days=30&account_id=${account}`
    }
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const labels = ['Suspicious Activity', 'Slow Pay', 'Split Payer', 'Stacking History', 'Criminal History', 'Covid-19 Hardship', 'Default','Other']
        const total_suspicious_activity_hits = response.data.data.hits_by_category.total_suspicious_activity_hits
        const percent_suspicious_activity_hits = response.data.data.hits_by_category.percent_suspicious_activity_hits
        const total_slow_pay_hits = response.data.data.hits_by_category.total_slow_pay_hits
        const percent_slow_pay_hits = response.data.data.hits_by_category.percent_slow_pay_hits
        const total_split_pay_hits = response.data.data.hits_by_category.total_split_pay_hits
        const percent_split_pay_hits = response.data.data.hits_by_category.percent_split_pay_hits
        const total_stacking_history_hits = response.data.data.hits_by_category.total_stacking_history_hits
        const percent_stacking_history_hits = response.data.data.hits_by_category.percent_stacking_history_hits
        const total_criminal_history_hits = response.data.data.hits_by_category.total_criminal_history_hits
        const percent_criminal_history_hits = response.data.data.hits_by_category.percent_criminal_history_hits
        const total_covid_hardship_hits = response.data.data.hits_by_category.total_covid_hardship_hits
        const percent_covid_hardship_hits = response.data.data.hits_by_category.percent_covid_hardship_hits
        const total_default_hits = response.data.data.hits_by_category.total_default_hits
        const percent_default_hits = response.data.data.hits_by_category.percent_default_hits
        const total_other_hits = response.data.data.hits_by_category.total_other_hits
        const percent_other_hits = response.data.data.hits_by_category.percent_other_hits

        setLoading(false)
        setData({
          labels: labels,
          total_suspicious_activity_hits: total_suspicious_activity_hits,
          percent_suspicious_activity_hits: percent_suspicious_activity_hits,
          total_slow_pay_hits: total_slow_pay_hits,
          percent_slow_pay_hits: percent_slow_pay_hits,
          total_split_pay_hits: total_split_pay_hits,
          percent_split_pay_hits: percent_split_pay_hits,
          total_stacking_history_hits: total_stacking_history_hits,
          percent_stacking_history_hits: percent_stacking_history_hits,
          total_criminal_history_hits: total_criminal_history_hits,
          percent_criminal_history_hits: percent_criminal_history_hits,
          total_covid_hardship_hits: total_covid_hardship_hits,
          percent_covid_hardship_hits: percent_covid_hardship_hits,
          total_default_hits: total_default_hits,
          percent_default_hits: percent_default_hits,
          total_other_hits: total_other_hits,
          percent_other_hits: percent_other_hits
        })
      }
      else {
        setLoading(false)
      }
    })
    .catch(error => {
      setLoading(false)
    })
    
    return () => {
      source.cancel()
    }
  }, [])

  let chart = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    chart = (
      <Chart
        data={data}
      />
    )
  }
  
  return (
    <Card>
      <CardHeader
        title={`Hits by Category (Last 30 days)`}
      />
      <Divider />
      {chart}
    </Card>
  );
};

export default withErrorHandler(HitsByCategory, axios)