import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = []

export default function toasts(state = [], action) {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.ADD_TOAST:
      return [payload, ...state];

    case actionTypes.REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload);

    default:
      return state;
  }
}
