import React from 'react';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import {
  Grid,
  TextField
} from '@material-ui/core';

const MerchantDeleteInfo = props => {
  const { 
    onChange,
    //profile,
    formState,
    hasError
  } = props;
  
  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="Reason for Deletion"
            margin="dense"
            error={hasError('reason')}
            name="reason"
            onChange={onChange}
            required
            value={formState.values.reason || ''}
            variant="outlined"
            type="text"
            helperText={
              hasError('reason') ? formState.errors.reason[0] : null
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withErrorHandler(MerchantDeleteInfo, axios)