import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { AccountPaymentDialog } from '..';
import { CircularProgress, Card, CardHeader, Divider, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    padding: '13px 15px 13px 15px',
    margin: '20px'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  payment_method_options: {
    display: 'flex',
    alignItems: 'center'
  },
  payment_method: {
    fontSize: '14px'
  },
  payment_method_title: {
    fontSize: '14px',
    textDecoration: 'underline'
  },
  payment_method_details: {
    fontSize: '14px'
  }
}));

const PaymentMethod = props => {
  const classes = useStyles();

  const { 
    onFetchPaymentMethodUpdateUrl,
    onFetchPaymentMethod,
    token,
    accountId,
    className,
    loading,
    account_view,
    payment_method_update_url,
    payment_method,
    ...rest
  } = props

  useEffect(() => {
    onFetchPaymentMethod(accountId);
  }, [onFetchPaymentMethod, accountId])

  // useEffect(() => {
  //   handlePaymentMethodRedirect();
  // }, [payment_method_update_url])

  // const handlePaymentMethodRedirect = () => {
  //   if (payment_method_update_url) {
  //     window.location.replace(payment_method_update_url)
  //   }
  // }

  const PaymentMethodButton = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    let updateType = 'funder'
    if (account_view) {
      updateType = 'account'
    }
    
    const handlePaymentMethodButtonClick = event => {
      onFetchPaymentMethodUpdateUrl(accountId, updateType);
    };
  
    return (
      <Button
              variant="contained"
              className={classes.button}
              onClick={handlePaymentMethodButtonClick}
            >
              Update Payment Method
            </Button>
          
    );
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Payment Method"
      />
      <Divider />
      <div className={classes.payment_method_options}>
        <PaymentMethodButton />
        {payment_method ? 
          <div className={classes.payment_method}>
            <div className={classes.payment_method_title}>
              Current Payment Method
            </div>
            <div className={classes.payment_method_details}>
              ### <b>{payment_method.last4}, {payment_method.exp_month}/{payment_method.exp_year}</b> 
            </div>
          </div>
          : null
        }
      </div>
      <AccountPaymentDialog paymentMethodUpdateUrl={payment_method_update_url} />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.user.loading,
    payment_method_update_url: state.account.payment_method_update_url,
    payment_method: state.account.payment_method,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchPaymentMethodUpdateUrl: (accountId, updateType) => dispatch(actions.fetchPaymentMethodUpdateUrl(accountId, updateType)),
    onFetchPaymentMethod: (accountId) => dispatch(actions.fetchPaymentMethod(accountId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(PaymentMethod, axios))