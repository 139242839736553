import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { MerchantSubscriptionsTable } from '../../../components/MerchantSubscription';
import { MerchantSubscriptionDialog } from '../../../components/MerchantSubscription';
import { MerchantSubscriptionDeleteDialog } from '../../../components/MerchantSubscription';
import {MessageBar} from '../../../components'
import { 
  CircularProgress,
  Backdrop,
  Card,
  CardHeader,
  Divider,
  Tabs,
  Tab,
  Typography,
  Box,
  AppBar
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const MerchantSubscriptionList = props => {
  const classes = useStyles();
  
  const { 
    onFetchMerchantSubscriptions,
    onOpenMerchantSubscriptionDialog,
    onOpenMerchantSubscriptionDeleteDialog,
    onCloseMerchantSubscriptionDeleteDialog,
    onDeleteMerchantSubscriptions,
    onDeleteAllMerchantSubscriptions,
    merchant_subscriptions,
    merchant_subscription_imports,
    token,
    loading,
    pagination,
    account_id,
    merchant_subscription_notification_email,
    merchant_subscription_notification_webhook_url
  } = props
  
  // let merchant_subscriptions = props.merchant_subscriptions
  // let loading = props.loading

  const [page, setPage] = useState(0)
  const [per_page, setPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [deleteAll, setDeleteAll] = useState(false)
  const [value, setValue] = useState(0);

  const [table_merchant_subscriptions, setTableMerchantSubscriptions] = useState(merchant_subscriptions)
  const [merchant_subscriptions_to_delete, setTableMerchantSubscriptionsToDelete] = useState([])
  
  useEffect(() => {
    if (search) {
      setIsFiltered(true)
    }
    onFetchMerchantSubscriptions(account_id, page, per_page, search);
  }, [onFetchMerchantSubscriptions, account_id, page, per_page, search])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value)
  }

  useEffect(() => {
    setTableMerchantSubscriptions(merchant_subscriptions)
  }, [merchant_subscriptions])

  const handleSelectAllMerchantSubscriptionsToggle = (event) => {
    setTableMerchantSubscriptions(table_merchant_subscriptions.map(item => { 
      return { ...item, selected: !item.selected } 
    }))
  }

  const handleSearchClick = (event, search) => {
    setIsFiltered(true)
    setSearch(search)
  }

  const handleSearchCancelClick = (event) => {
    setIsFiltered(false)
    setSearch('')
  }

  const handleAddMerchantSubscriptionClick = (event) => {
    onOpenMerchantSubscriptionDialog();
  }

  const handleDeleteMerchantSubscriptionClick = (event, merchant_subscription) => {
    handleDeleteMerchantSubscriptionsClick(event,[merchant_subscription])
  }

  const handleDeleteMerchantSubscriptionsClick = (event, delete_merchant_subscriptions) => {
    setTableMerchantSubscriptionsToDelete(delete_merchant_subscriptions)
    handleMerchantSubscriptionDeleteConfirmDialog(event)
  }

  const handleDeleteAllMerchantSubscriptionsClick = (event) => {
    setDeleteAll(true)
    handleMerchantSubscriptionDeleteConfirmDialog(event)
  }

  const handleMerchantSubscriptionDeleteConfirmDialog = (event) => {
    onOpenMerchantSubscriptionDeleteDialog();
  }

  const handleMerchantSubscriptionDeleteConfirm = (event) => {
    if (deleteAll){
      onDeleteAllMerchantSubscriptions(account_id, page, per_page, search);
    } else {
      onDeleteMerchantSubscriptions(account_id, page, per_page, search, merchant_subscriptions_to_delete);
    }
    
    setTableMerchantSubscriptionsToDelete([])
    setDeleteAll(false)
  }

  const handleMerchantSubscriptionDeleteCancel = (event) => {
    setTableMerchantSubscriptionsToDelete([])
    setDeleteAll(false)
    onCloseMerchantSubscriptionDeleteDialog();
  }


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  let merchantSubscriptionsTable = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if(!loading){
    merchantSubscriptionsTable = (
      <MerchantSubscriptionsTable
        merchant_subscriptions={table_merchant_subscriptions}
        onAddMerchantSubscriptionClick={(event) => handleAddMerchantSubscriptionClick(event)}
        pagination={pagination}
        onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        onPerPageChange={handlePerPageChange}
        onSearchClick={(event, search) => handleSearchClick(event, search)}
        onSearchCancelClick={handleSearchCancelClick}
        currentSearch={search}
        isFiltered={isFiltered}
        onDeleteMerchantSubscriptionClick={(event, merchant_subscription) => handleDeleteMerchantSubscriptionClick(event, merchant_subscription)}
        onDeleteSelectedMerchantSubscriptionsClick={(event, delete_merchant_subscriptions) => handleDeleteMerchantSubscriptionsClick(event, delete_merchant_subscriptions)}
        onDeleteAllMerchantSubscriptionsClick={(event) => handleDeleteAllMerchantSubscriptionsClick(event)}
        onSelectAllMerchantSubscriptionsToggle={(event) => handleSelectAllMerchantSubscriptionsToggle(event)}
      />
    )
  }

  return (
    <div className={classes.root}>
      {(!merchant_subscription_notification_email && !merchant_subscription_notification_webhook_url) ?
        <MessageBar
          severity="warning"
          title="Configuration Missing"
          message="You will not receive notifications from your merchant subscriptions until you configure a Subscription Notification Email or Subscription Notification Webhook URL in your Account settings"
        />
      : null }
      <div className={classes.content}>
        <Card>
        <AppBar position="static" color="default">
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value="0"
            >
              <Tab label="Merchant Subscriptions" href="merchant_subscriptions" className={classes.active_tab} value="0"/>
              <Tab label="Uploads" href="merchant_subscription_imports" />
              <Tab label="Webhook Logs" href="merchant_subscription_webhook_logs" />
            </Tabs>
          </AppBar>
          <Card>
            <Divider />
            {merchantSubscriptionsTable}
          </Card>
        </Card>
      </div>
      <MerchantSubscriptionDialog
        accountId={account_id}
        per={pagination.per_page}
        page={pagination.current_page}
        search={search}
      />
      <MerchantSubscriptionDeleteDialog 
        onCloseDialog={handleMerchantSubscriptionDeleteCancel}
        onContinueDialog={handleMerchantSubscriptionDeleteConfirm}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchant_subscriptions: state.merchantSubscription.merchant_subscriptions,
    loading: state.merchantSubscription.loading,
    token: state.auth.token,
    pagination: state.merchantSubscription.pagination,
    account_id: state.auth.user.account_resource_id,
    merchant_subscription_notification_email: state.auth.user.account_merchant_subscription_notification_email,
    merchant_subscription_notification_webhook_url: state.auth.user.account_merchant_subscription_notification_webhook_url
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchMerchantSubscriptions: (account_id, page, per_page, search) => dispatch(actions.fetchMerchantSubscriptions(account_id, page, per_page, search)),
    onFetchMerchantSubscriptionImports: (account_id) => dispatch(actions.fetchMerchantSubscriptionImports(account_id)),
    onOpenMerchantSubscriptionDialog: () => dispatch(actions.openMerchantSubscriptionDialog()),
    onDeleteMerchantSubscriptions: (account_id, page, per_page, search, merchant_subscriptions) => dispatch(actions.deleteMerchantSubscriptions(account_id, page, per_page, search, merchant_subscriptions)),
    onDeleteAllMerchantSubscriptions: (account_id, page, per_page, search) => dispatch(actions.deleteAllMerchantSubscriptions(account_id, page, per_page, search)),
    onOpenMerchantSubscriptionDeleteDialog: () => dispatch(actions.openMerchantSubscriptionDeleteDialog()),
    onCloseMerchantSubscriptionDeleteDialog: () => dispatch(actions.closeMerchantSubscriptionDeleteDialog()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantSubscriptionList, axios))