import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Switch
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import * as actions from '../../../store/actions'

import UserInfo from '../UserInfo'
import Mfa from '../Mfa'
import { PasswordDialog } from '../';
import {
  Typography
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {}
}));

const UserForm = props => {
  const {
    className,
    user,
    onSubmitClick,
    onOpenChangePasswordModal,
    onCloseChangePasswordModal,
    onForgetDevice,
    onResetMFAModal,
    modalOpen,
    onPasswordChangeSubmit,
    token,
    profile,
    mode,
    is_dm_admin,
    onMfaRequiredToggle,
    ...rest
  } = props;

  const [id, setId] = useState(null)
  const [accountId, setAccountId] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mfa_required, setMfaRequired] = useState('')

  const classes = useStyles();

  useEffect(() => {
    if(user && user.id){
      setId(user.resource_id)
      setAccountId(user.account_id)
      setFirstName(user.first_name)
      setLastName(user.last_name)
      setEmail(user.email)
      setMfaRequired(user.mfa_required)
    }
  },[user])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'firstName':
        setFirstName(event.target.value)
        break
      case 'lastName':
        setLastName(event.target.value)
        break
      case 'email':
        setEmail(event.target.value)
        break
      default:
        return null
    }
  };

  const handleChangePasswordClick = (event) => {
    onOpenChangePasswordModal()
  }

  const handleCancelChangePasswordClick = (event) => {
    onCloseChangePasswordModal()
  }

  const handleSubmitPasswordChangeClick = (event, passwordData) => {
    onPasswordChangeSubmit(token, passwordData)
  }

  const handleForgetDeviceClick = (id) => {
    onForgetDevice(token, id)
  }

  const handleResetMFAClick = (id) => {
    onResetMFAModal(token, id)
  }

  const mfa = () => {
    if (user && user.mfa_enabled){
      return (<Mfa
        onForgetDeviceClick={(event, id) => handleForgetDeviceClick(event, id)}
        onResetMFAClick={(event, id) => handleResetMFAClick(event, id)}
        id={id}
        profile={profile}
        mode={mode}
        user={user}
      />)
    } else {
      if (user && mode != 'add' && is_dm_admin && !profile){
        return (<React.Fragment>
          <CardHeader
            title={'Multi-Factor Authentication'}
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <FormControlLabel
                  control={<Switch checked={mfa_required} onChange={(id) => onMfaRequiredToggle(user.resource_id)} name="mfa_required" color="primary" />}
                  label="Require Multi-Factor Authentication"
                />
              </Grid>
            </Grid>
          </CardContent>
        </React.Fragment>)
      } else {
        if (profile && user.mfa_required) {
          return (<React.Fragment>
            <CardHeader
              title={'Multi-Factor Authentication'}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Typography>MFA has not been enabled.</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </React.Fragment>)
        } else {
          return (<React.Fragment></React.Fragment>)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
        >
          <UserInfo
            firstName={firstName}
            lastName={lastName}
            email={email}
            onChange={(event, field) => handleInputChange(event, field)}
            onChangePasswordClick={handleChangePasswordClick}
            id={id}
            profile={profile}
            mode={mode}
          />
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={(event, user) => onSubmitClick(event, {
                id: id,
                accountId: accountId,
                firstName: firstName,
                lastName: lastName,
                email: email
              })}
            >
              Save details
            </Button>
          </CardActions>
        </form>
        <PasswordDialog
          open={modalOpen}
          onCloseClick={(event) => handleCancelChangePasswordClick(event)}
          onSubmitClick={handleSubmitPasswordChangeClick}
          userId={id}
          profile={profile}
        />
      </Card>
      <br></br>
      <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {mfa()}
      <PasswordDialog
        open={modalOpen}
        onCloseClick={(event) => handleCancelChangePasswordClick(event)}
        onSubmitClick={handleSubmitPasswordChangeClick}
        userId={id}
        profile={profile}
      />
    </Card>
  </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    modalOpen: state.user.passwordModalOpen,
    token: state.auth.token,
    is_dm_admin: state.auth.user.is_dm_admin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onOpenChangePasswordModal: () => dispatch(actions.openPasswordModal()),
    onCloseChangePasswordModal: () => dispatch(actions.closePasswordModal()),
    onPasswordChangeSubmit: (token, passwordData) => dispatch(actions.changePassword(token, passwordData)),
    onForgetDevice: (token, id) => dispatch(actions.forgetDevice(token, id)),
    onResetMFAModal: (token, id) => dispatch(actions.resetMfa(token, id)),
    onMfaRequiredToggle: (id) => dispatch(actions.mfaRequiredToggleUser(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(UserForm, axios))