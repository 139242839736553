import { history } from '../Routes'
import { put, select, call } from 'redux-saga/effects'
import * as actions from '../store/actions'
import {store} from '../Store'

export function * addUserRedirect (payload) {
  try {
    let current_url = history.location.pathname

    if (current_url === '/account/members/new'){
      yield history.push('/account')
    } else {
      current_url = current_url.split('/').slice(0, -2).join('/')
      yield history.push(current_url) 
    }
  } catch (error) {
    console.log(error)
  }
}
