import React from 'react';
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel
} from '@material-ui/core';

import {MessageBar} from '../../../components'

const useStyles = makeStyles(theme => ({
  changePasswordButton: {
    margin: '10px 0'
  }
}));

const DashboardMessage = props => {
  const { onChange, title, message, severity, enabled } = props;

  const classes = useStyles();

  const isEnabled = (enabled === true)
  const dashboardMessageSeverity = [
    {
      value: 'info',
      label: 'Information - Blue'
    },
    {
      value: 'warning',
      label: 'Caution - Orange'
    },
    {
      value: 'error',
      label: 'Warning - Red'
    },
    {
      value: 'success',
      label: 'Success - Green'
    }
  ];

  return (
    <React.Fragment>
      <CardHeader
        title='Dashboard Message'
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
          >
            <FormControlLabel
              control={<Switch checked={isEnabled} onChange={(event) => onChange(event, 'dashboardMessageEnabled')} name="enabled" color="primary" />}
              label="Show Message Bar"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label="Type"
              margin="dense"
              name="severity"
              onChange={(event) => onChange(event, 'dashboardMessageSeverity')}
              required
              select
              SelectProps={{ native: true }}
              value={severity}
              variant="outlined"
            >
              {dashboardMessageSeverity.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label="Title - optional"
              margin="dense"
              name="title"
              onChange={(event) => onChange(event, 'dashboardMessageTitle')}
              value={title || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label="Message"
              margin="dense"
              name="message"
              onChange={(event) => onChange(event, 'dashboardMessage')}
              required
              value={message || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <CardHeader
              title="Preview"
            />
            <MessageBar
            severity={severity}
            title={title}
            message={message}
          />
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
};

export default withErrorHandler(DashboardMessage, axios)