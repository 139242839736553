import React from 'react';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const AccountInfo = props => {
  const { onChange, name, website, primaryContactEmail, account_view} = props;
  
  let title = "Funder Info"
  if (account_view) {
    title = "Account Info"
  }
  return (
    <React.Fragment>
      <CardHeader
        title={title}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label="Funder name"
              margin="dense"
              name="name"
              onChange={(event) => onChange(event, 'name')}
              required
              value={name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Website"
              margin="dense"
              name="website"
              onChange={(event) => onChange(event, 'website')}
              required
              value={website || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Primary Contact Email"
              margin="dense"
              name="primaryContactEmail"
              onChange={(event) => onChange(event, 'primaryContactEmail')}
              required
              value={primaryContactEmail || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
};

export default withErrorHandler(AccountInfo, axios)