import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const fetchAppSettingsSuccess = (appsettings) => {
  return {
    type: actionTypes.FETCH_APPSETTINGS_SUCCESS,
    appsettings: appsettings
  }
}

export const fetchAppSettingsFail = (error) => {
  // return {
  //   type: actionTypes.FETCH_APPSETTINGS_FAIL,
  //   error: error
  // }
  return {
    type: actionTypes.LOGOUT_USER
  }
}

export const fetchAppSettingsStart = () => {
  return {
    type: actionTypes.FETCH_APPSETTINGS_START
  }
}

export const fetchAppSettings = () => {
  return dispatch => {
    dispatch(fetchAppSettingsStart())
    let url = `/app_settings`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedAppSettings = {...response.data.data}
        dispatch(fetchAppSettingsSuccess(fetchedAppSettings))
      } else {
        dispatch(fetchAppSettingsFail('An error occurred while attempting to retrieve app settings.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve app settings.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      if (error) {
        console.log(error)
        // dispatch(fetchAppSettingsFail(error.response.data.errors))
        dispatch(fetchAppSettingsFail())
      }
      else {
        dispatch(fetchAppSettingsFail())
      }
    })
  }
}


export const updateAppSettingsStart = () => {
  return {
    type: actionTypes.UPDATE_APPSETTINGS_START
  }
}

export const updateAppSettingsSuccess = (appsettingsData) => {
  return {
    type: actionTypes.UPDATE_APPSETTINGS_SUCCESS,
    appsettingsData: appsettingsData
  }
}

export const updateAppSettingsFail = (error) => {
  return {
    type: actionTypes.UPDATE_APPSETTINGS_FAIL,
    error: error
  }
}

export const updateAppSettings = (appsettingsData) => {
  const modifiedAppsettingsData = {
    app_settings: {
      dashboard_message_enabled: appsettingsData.appSettings.dashboardMessageEnabled,
      dashboard_message_severity: appsettingsData.appSettings.dashboardMessageSeverity,
      dashboard_message_title: appsettingsData.appSettings.dashboardMessageTitle,
      dashboard_message: appsettingsData.appSettings.dashboardMessage
    }
  }

  return dispatch => {
    //dispatch(updateAppSettingsStart())
    let url = `/app_settings`
    let config = authHeaders()
    axios.put(url, modifiedAppsettingsData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateAppSettingsSuccess(data))
        dispatch(addToast({
          message: 'App settings have been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(updateAppSettingsFail('An error occurred while attempting to update app settings.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update app settings.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateAppSettingsFail(error.response.data.errors))
    })
  }
}
