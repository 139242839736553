import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import AccountInfo from '../AccountInfo'
import AccountMerchantSubscriptionSettings from '../AccountMerchantSubscriptionSettings'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountForm = props => {
  const { className, account, plan_level, onSubmitClick, is_dm_admin, account_view, ...rest } = props;
  const [id, setId] = useState(null)
  const [name, setName] = useState('')
  const [website, setWebsite] = useState('')
  const [primaryContactEmail, setPrimaryContactEmail] = useState('')
  const [merchant_subscription_notification_email, setMerchantSubscriptionNotificationEmail] = useState('')
  const [merchant_subscription_notification_webhook, setMerchantSubscriptionNotificationWebhook] = useState('')
  const [merchant_subscription_enabled, setMerchantSubscriptionEnabled] = useState('')

  const classes = useStyles();

  useEffect(() => {
    if(account && account.id){
      setId(account.id)
      setName(account.name)
      setWebsite(account.website)
      setPrimaryContactEmail(account.primary_contact_email)
      setMerchantSubscriptionNotificationEmail(account.merchant_subscription_notification_email)
      setMerchantSubscriptionNotificationWebhook(account.merchant_subscription_notification_webhook)
      setMerchantSubscriptionEnabled(account.merchant_subscription_enabled)
    }
  },[account])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'name':
        setName(event.target.value)
        break
      case 'website':
        setWebsite(event.target.value)
        break
      case 'primaryContactEmail':
        setPrimaryContactEmail(event.target.value)
        break
      case 'merchant_subscription_notification_email':
        setMerchantSubscriptionNotificationEmail(event.target.value)
        break
      case 'merchant_subscription_notification_webhook':
        setMerchantSubscriptionNotificationWebhook(event.target.value)
        break
      case 'merchant_subscription_enabled':
        if (event.target.checked){
          setMerchantSubscriptionEnabled(true)
        } else {
          setMerchantSubscriptionEnabled(false)
        }
        break
      default:
        return null
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      >
      <form
        autoComplete="off"
      >
        <AccountInfo
          name={name}
          website={website}
          primaryContactEmail={primaryContactEmail}
          onChange={(event, field) => handleInputChange(event, field)}
          account_view={account_view}
        />
        {(account && account.id && (account.plan_level === 'PRO')) ? 
          <React.Fragment>
            <Divider />
            <AccountMerchantSubscriptionSettings
              merchant_subscription_notification_email={merchant_subscription_notification_email}
              merchant_subscription_notification_webhook={merchant_subscription_notification_webhook}
              merchant_subscription_enabled={merchant_subscription_enabled}
              onChange={(event, field) => handleInputChange(event, field)}
              account_view={account_view}
              is_dm_admin={is_dm_admin}
            />
          </React.Fragment>
        : null }
        
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={(event, account) => onSubmitClick(event, {
              id: id,
              name: name,
              website: website,
              primaryContactEmail: primaryContactEmail,
              merchant_subscription_enabled: merchant_subscription_enabled,
              merchant_subscription_notification_email: merchant_subscription_notification_email,
              merchant_subscription_notification_webhook: merchant_subscription_notification_webhook
            })}
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};


export default withErrorHandler(AccountForm, axios)