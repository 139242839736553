import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  search_counts: []
}

const fetchSearchCountsStart = (state, action) => {
  return updateObject(state, {loading: true, seach_counts: []})
}

const fetchSearchCountsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    search_counts: action.search_counts
  })
}

const fetchSearchCountsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const resetSearchCounts = (state, action) => {
  return updateObject(state, {loading: false, search_counts: []})
}


const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_SEARCH_COUNTS_START:
      return fetchSearchCountsStart(state, action)
    case actionTypes.FETCH_SEARCH_COUNTS_SUCCESS:
      return fetchSearchCountsSuccess(state, action)
    case actionTypes.FETCH_SEARCH_COUNTS_FAIL:
      return fetchSearchCountsFail(state, action)
    case actionTypes.RESET_SEARCH_COUNTS:
      return resetSearchCounts(state, action)
    default:
      return state
  }
}
    
export default reducer
