import React from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import withErrorHandler from '../../hocs/withErrorHandler'
import axios from '../../httpClients/axios-datamerch'
import * as actions from '../../store/actions'

import { AppSettings } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = props => {
  const classes = useStyles();
  const { onAppSettingsSave} = props
  

  const handleSubmitClick = (event, appSettings) => {
    event.preventDefault();
    
    const appSettingsData = {
      appSettings: {
        dashboardMessageEnabled: appSettings.dashboardMessageEnabled,
        dashboardMessageSeverity: appSettings.dashboardMessageSeverity,
        dashboardMessageTitle: appSettings.dashboardMessageTitle,
        dashboardMessage: appSettings.dashboardMessage
      }
    }
    onAppSettingsSave(appSettingsData)
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <AppSettings onSubmitClick={handleSubmitClick} />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchant: state.merchant.merchant,
    saving: state.merchant.saving,
    token: state.auth.token,
    dialog_id: state.merchant.dialog_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAppSettingsSave: (appSettingsData) => dispatch(actions.updateAppSettings(appSettingsData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Settings, axios))