import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  invoices: [],
  error: null,
  flash: null,
  pagination: {
    current_page: 0,
    per_page: 25,
    total_pages: 1,
    total_count: 0
  },
  loading: false
}

const fetchInvoicesStart = (state, action) => {
  return updateObject(state, {loading: true, invoices: []})
}

const fetchInvoicesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    invoices: action.invoices,
    pagination: action.pagination
  })
}

const fetchInvoicesFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_INVOICES_START:
      return fetchInvoicesStart(state, action)
    case actionTypes.FETCH_INVOICES_SUCCESS:
      return fetchInvoicesSuccess(state, action)
    case actionTypes.FETCH_INVOICES_FAIL:
      return fetchInvoicesFail(state, action)
    default:
      return state
  }
}
    
export default reducer
