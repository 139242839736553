import React from 'react';
import {connect} from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import InputIcon from '@material-ui/icons/Input';
import InfoIcon from '@material-ui/icons/Info';
// import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SearchInput } from '../../../../components/';
import { asPercent } from '../../../../helpers';

import {
  AppBar,
  Toolbar,
  Fab,
  Hidden,
  IconButton,
  Card,
  CardActions,
  Divider,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  Switch,
  Checkbox,
  TextField,
  Tooltip,
  ClickAwayListener
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#fff'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.black
  },
  searchInput: {
    marginRight: '50px'
  },
  fab: {
    marginRight: '30px'
  },
  sidebarToggle: {
    color: theme.palette.black
  },
  contribution_container: {
    display: 'flex'
  },
  contribution_caution: {
    color: theme.palette.orange,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  contribution_ok: {
    color: theme.palette.green,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  contribution_low: {
    color: theme.palette.red,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  warning_bar: {
    backgroundColor: theme.palette.orange,
    '& p,h3': {
      color: theme.palette.white
    }
  },
  proBadge: {
    color: theme.palette.white,
      marginLeft: '15px',
    fontSize: '20px',
    backgroundColor: theme.palette.blue,
    padding: '5px',
    borderRadius: '5px'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, onMerchantSearchClick, onLogoutClick, user, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const ContributionPercentage = (props) => {
    const { val } = props;
    let percent = 0;
    let cssName = classes.contribution_caution;
    
    if (val > 0){
      percent = asPercent(val);
    
      if (percent > 1)
        cssName = classes.contribution_ok;
      if (percent < 0.5)
        cssName = classes.contribution_low;
    } else {
      cssName = classes.contribution_low;
    }
    return (
      <div className={cssName}>
        { percent }% 
      </div>
    );
  }

  const ContributionPercentageIndicator = (props) => {
    const { val, hide } = props;
    
    if (!hide) {
      return (
        <div>
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="body2"
              >
                90 DAY CONTRIBUTION
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Minimum upload percentage to search is 0.5%"
                  >
                    <IconButton aria-label="info" className={classes.margin} size="small" onClick={handleTooltipOpen}>
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </Typography>
              <Typography variant="h4"><ContributionPercentage val={val} /></Typography>
            </Grid>
          </Grid>
        </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo.png"
            height="40px"
          />
        </RouterLink>
        { user.plan_level === 'PRO' ? 
        <Typography
          variant="h5"
          className={classes.proBadge}
        >
          PRO
        </Typography>
        : null }
        <div className={classes.flexGrow} />
        <ContributionPercentageIndicator val={user.account_total_ninety_days_contribution_percentage} hide={false} /*user.account_contribution_override} */ />
        <Hidden xsUp>
          <SearchInput
            className={classes.searchInput}
            onSearchClick={onMerchantSearchClick}
          />
          <RouterLink to="/merchants/new">
            <Fab color="primary" className={classes.fab}>
              <AddIcon />
            </Fab>
          </RouterLink>
          <IconButton
            className={classes.signOutButton}
            onClick={onLogoutClick}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            onClick={onSidebarOpen}
            className={classes.sidebarToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, null)(Topbar)