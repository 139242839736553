export const TOKEN_STORAGE_KEY = 'token'

export const parseAuthHeader = (authHeader) => {
  if (authHeader) {
    return authHeader.split(' ').pop()
  } else {
    return null
  }
}

export const setToken = (response) => {
  if (!response.data || !response.data.data || !response.data.data.jwt) {
    throw response.data.errors || ''
  }
  const token = response.data.data.jwt
  window.localStorage.setItem(TOKEN_STORAGE_KEY, token)
}
