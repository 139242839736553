import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../../store/actions'
import validate from 'validate.js';

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  Card
} from '@material-ui/core';

import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    paddingTop: 125
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  contactUs: {
    textAlign: 'right',
    paddingBottom: '20px'
  },
  footerdisclosure: {
    marginTop: '60px',
    fontSize: '12px',
    color: 'cccccc'
  },
  footerdisclosureparagraph: {
    marginBottom: '20px',
    fontSize: '12px',
    color: '#888'
  }
}));

const Login  = (props) => {
  const { 
    userLoginFetch,
    token,
    error,
    redirect
  } = props

  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let email = event.target.email.value;
    let password = event.target.password.value;



    const data = {
      email: email,
      password: password
    };

    userLoginFetch(data);
  }

  const showSocialLogin = false
  const maintenanceMode = false

  return (
    <React.Fragment>
    { maintenanceMode ? 
      <div className={classes.root}>
      <div className={classes.content}>
      <Grid
      container
      spacing={2}
    >
      <Grid item>
        
          <Typography variant="h3">DataMerch is currently unavailable due to maintenance</Typography>
        
      </Grid>
    </Grid>
    </div>
    </div>
      :
    <form
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Sign in
      </Typography>
      {!token ? (
        <Grid
          container
        >
          <Grid item>
            <Typography variant="h5" color="secondary">{message}</Typography>
          </Grid>
        </Grid>
      ) : <Redirect to={redirect} />}
      { showSocialLogin ? (
        <React.Fragment>
          < Typography
            color="textSecondary"
            gutterBottom
          >
            Sign in with social media
          </Typography>
          <Grid
            className={classes.socialButtons}
            container
            spacing={2}
          >
            <Grid item>
              <Button
                color="primary"
                onClick={handleSubmit}
                size="large"
                variant="contained"
              >
                <FacebookIcon className={classes.socialIcon} />
                Login with Facebook
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSubmit}
                size="large"
                variant="contained"
              >
                <GoogleIcon className={classes.socialIcon} />
                Login with Google
              </Button>
            </Grid>
          </Grid>
          <Typography
            align="center"
            className={classes.sugestion}
            color="textSecondary"
            variant="body1"
          >
            or login with email address
          </Typography>
        </React.Fragment>
      ) : null}
      <TextField
        className={classes.textField}
        error={hasError('email')}
        fullWidth
        helperText={
          hasError('email') ? formState.errors.email[0] : null
        }
        label="Email address"
        name="email"
        onChange={handleChange}
        type="text"
        value={formState.values.email || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('password')}
        fullWidth
        helperText={
          hasError('password') ? formState.errors.password[0] : null
        }
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={formState.values.password || ''}
        variant="outlined"
      />
      <Button
        className={classes.signInButton}
        color="primary"
        disabled={!formState.isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Sign in now
      </Button>
      <Grid
        className={classes.grid}
        container
        justify="space-between"
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <Link
              component={RouterLink}
              to="/forgot_password"
              variant="h6"
            >
              forgot your password?
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
        >
          <Grid
          className={classes.grid}
          container
          alignContent="flex-end"
          >
            <Grid
              item
              lg={12}
              xs={12}
              className={classes.contactUs}
            >
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Don't have an account?{' '}
                <Link
                  href="mailto:support@datamerch.com"
                  variant="h6"
                >
                  Contact Us
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
        container
        spacing={4}
        className={classes.disclosureContainer}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <div className={classes.footerdisclosure}>
            <Typography className={classes.footerdisclosureparagraph}>
              DataMerch LLC always strives to have the most accurate business information on file. If you believe there is potential erroneous information on our site, please follow our <a href="dispute" target="_blank">dispute process</a>. For customer support or general inquiries, please contact <a href="mailto:support@datamerch.com">support@datamerch.com</a>. 
            </Typography>
            <Typography className={classes.footerdisclosureparagraph}>
              DataMerch LLC or its members do not constitute a "consumer report" as that term is defined in the federal Fair Credit Reporting Act, 15 USC 1681 et seq. (FCRA).  DataMerch LLC only accepts, stores, and distributes data on commercial entities.  User is prohibited from providing information on individual persons or consumers.   Information listed on DataMerch LLC may not be used in whole or in part as a factor in determining eligibility for personal credit, insurance, employment or another permissible purpose under the FCRA.  It is the policy of DataMerch LLC to view sole-proprietors, single-shareholder corporations, single-member partnerships and single-member companies as commercial entities and never as individuals or consumers.
            </Typography>
            <Typography className={classes.footerdisclosureparagraph}>
              Due to the nature of the origin of public record information, the public records and commercially available data sources used in reports may contain errors. Source data is sometimes reported or entered inaccurately, processed poorly or incorrectly, and is generally not free from defect. This product or service aggregates and reports data, as provided by the public records and commercially available data sources, and is not the source of the data, nor is it a comprehensive compilation of the data. Before relying on any data, it should be independently verified.
            </Typography>
          </div>
        </Grid>
      </Grid>
      </Grid>
    </form>
      }
      </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    error: state.auth.error,
    redirect: state.auth.attemptedRoute
  }
}

const mapDispatchToProps = dispatch => ({
  // userLoginFetch: userInfo => dispatch(actions.auth(userInfo))
  userLoginFetch: data => dispatch({
    type: 'LOGIN_ATTEMPT',
    credentials: data
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
