import React from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'

import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Divider } from '@material-ui/core';

const MerchantExistsDialog = props => {
  const { 
    openDialog,
    onCloseDialog,
    onChooseAdd,
    dialog_id,
    onInializeAddMerchant
  } = props;
  
  const handleDialogClose = () => {
    onCloseDialog()
    // onInializeAddMerchant()
  };

  const handleChooseAdd = () => {
    onChooseAdd(dialog_id)
    onCloseDialog()
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Merchant already exists in DataMerch"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A merchant with the EIN or BN you entered already exists in DataMerch.  Would you like to add notes to the matching merchant?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          No
        </Button>
        <Button
          onClick={handleChooseAdd}
          variant="contained"
          color="primary"
          autoFocus
        >
          Yes, I want to add notes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    openDialog: state.merchant.merchant_exists_dialog_open,
    dialog_id: state.merchant.dialog_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseDialog: () => dispatch(actions.closeMerchantExistsDialog()),
    onChooseAdd: (dialog_id) => dispatch(actions.initializeAddNoteToMerchant(dialog_id)),
    onInializeAddMerchant: () => dispatch(actions.initializeAddMerchant())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantExistsDialog)
