import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'
import {authHeaders} from '../../../helpers'

import Chart from './Chart';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const SearchesBySource = props => {
  const { account } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    labels: ['API', 'Web'],
    total_api_searches: 0,
    percent_api_searches: 0,
    total_web_searches: 0,
    percent_web_searches: 0
  })
  
  useEffect(() => {
    setLoading(true)
    let url = `/widgets/searches_by_source?days=30`
    if (account){
      url = `/widgets/searches_by_source?days=30&account_id=${account}`
    }
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const labels = ['API', 'Web']
        const total_api_searches = response.data.data.searches_by_source.total_api_searches
        const percent_api_searches = response.data.data.searches_by_source.percent_api_searches
        const total_web_searches = response.data.data.searches_by_source.total_web_searches
        const percent_web_searches = response.data.data.searches_by_source.percent_web_searches

        setLoading(false)
        setData({
          labels: labels,
          total_api_searches: total_api_searches,
          percent_api_searches: percent_api_searches,
          total_web_searches: total_web_searches,
          percent_web_searches: percent_web_searches
        })
      }
      else {
        setLoading(false)
      }
    })
    .catch(error => {
      setLoading(false)
    })
    
    return () => {
      source.cancel()
    }
  }, [])

  let chart = (
    <CircularProgress color="inherit" />
  )
  if(!loading){
    chart = (
      <Chart
        data={data}
      />
    )
  }
  
  return (
    <Card>
      <CardHeader
        title={`Searches By Source (Last 30 days)`}
      />
      <Divider />
      {chart}
    </Card>
  );
};

export default withErrorHandler(SearchesBySource, axios)