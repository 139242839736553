import React from 'react'
import { makeStyles } from '@material-ui/styles';

import Backdrop from '../Backdrop/Backdrop'

const useStyles = makeStyles(theme => ({
  root: {
    width: '70%',
    position: 'fixed',
    zIndex: '500',
    left: '15%',
    top: '30%',
    backgroundColor: theme.palette.white,
    border: '1px solid #ccc',
    boxShadow: '1px 1px 1px black',
    padding: '16px',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease-out',
    [theme.breakpoints.up('md')]: {
      width: '500px',
      left: 'calc(50% - 250px)'
    },
  }
}));

const Modal = React.memo(props => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Backdrop show={props.show} clicked={props.modalClosed}/>
      <div 
        className={classes.root}
        style={{
          transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: props.show ? '1': '0' 
        }}>
        {props.children}
      </div>
    </React.Fragment>
  )
});

export default Modal