import React, {useState} from 'react';
import {connect} from 'react-redux'
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import {
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';

import * as actions from '../../../store/actions'

import { ApiCredentialDialog } from '../';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  add_button: {
    textDecoration: 'none'
  }
}));

const UsersTable = props => {
  const { 
    users,
    deleted,
    onRestoreClick,
    onArchiveClick,
    onUserAdminToggle,
    onUserMfaRequiredToggle,
    onUserGrantApiAccessClick,
    onUserRevokeApiAccessClick,
    api_access,
    account_id,
    account_view,
    account,
    onOpenApiCredentialsModal,
    modalOpen,
    onCloseApiCredentialsModal
  } = props;

  const classes = useStyles();

  const handleShowApiCredentialsClick = (event, user) => {
    onOpenApiCredentialsModal(user)
  }

  const handleCloseApiCredentialsClick = (event) => {
    event.preventDefault();
    onCloseApiCredentialsModal()
  };

  let add_member_link = (
    <Link to={`/funders/${account_id}/members/new`} className={classes.add_button}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AddIcon />}
      >
        Add Member
      </Button>
    </Link>
  )

  if (account_view) {
    add_member_link = (
      <Link to={`/account/members/new`}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
        >
          Add Member
        </Button>
      </Link>
    )
  }

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              {!deleted ? (
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} align={'right'}>
                      {add_member_link}
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : null }
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  { deleted ? (
                    <React.Fragment>
                      <TableCell>Restore</TableCell>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TableCell>Admin?</TableCell>
                      { api_access ? (
                        <React.Fragment>
                          <TableCell>API</TableCell>
                        </React.Fragment>
                      ) : (
                        null
                      )}
                      { !account_view ? (
                        <TableCell>MFA Required</TableCell>
                      ) : (
                        null
                      )}
                      <TableCell>Delete</TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          { account_view ? (
                            <Link to={`/account/members/${user.resource_id}`}>{user.first_name} {user.last_name}</Link>
                          ) : (
                            <Link to={`/funders/${account_id}/members/${user.resource_id}`}>{user.first_name} {user.last_name}</Link>
                          )}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <a href={`mailto:${user.email}`} target="_blank">{user.email}</a>
                    </TableCell>
                    { deleted ? (
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={event => onRestoreClick(event, user.resource_id)}>
                          Restore
                        </Button>
                      </TableCell>
                    ) : (
                      <React.Fragment>
                        <TableCell>
                          <Checkbox
                              checked={user.is_admin}
                              color="primary"
                              onChange={event => onUserAdminToggle(event, user.resource_id)}
                              value="true"
                            />
                        </TableCell>
                        { api_access ? (
                          user.api_granted ? (
                            <TableCell>
                              <Button variant="contained" color="primary" onClick={event => handleShowApiCredentialsClick(event, user)}>
                                Show
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button variant="contained" color="secondary" onClick={event => onUserRevokeApiAccessClick(event, user.resource_id)}>
                                Revoke
                              </Button>
                              </TableCell>
                          ) : (
                            <TableCell>
                              <Button variant="contained" color="primary" onClick={event => onUserGrantApiAccessClick(event, user.resource_id)}>
                                Grant Access
                              </Button>
                            </TableCell>
                          )
                        ) : (
                          null
                        )}
                        { !account_view ? (
                          <TableCell>
                            <Checkbox
                                checked={user.mfa_required}
                                color="primary"
                                onChange={event => onUserMfaRequiredToggle(event, user.resource_id)}
                                value="true"
                                disabled={account.mfa_required}
                              />
                          </TableCell>
                        ) : (
                          null
                        )}
                        <TableCell>
                          <IconButton aria-label="delete" onClick={event => onArchiveClick(event, user.resource_id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </React.Fragment>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <ApiCredentialDialog open={modalOpen} onCloseClick={handleCloseApiCredentialsClick} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    modalOpen: state.user.apiCredentialModalOpen,
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onOpenApiCredentialsModal: (user) => dispatch(actions.openApiCredentialModal(user)),
    onCloseApiCredentialsModal: () => dispatch(actions.closeApiCredentialModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
