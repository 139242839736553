import React from 'react';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import {
  Grid,
  TextField
} from '@material-ui/core';

const PasswordInfo = props => {
  const { 
    onChange,
    // oldPassword,
    // newPassword,
    // confirmPassword,
    profile,
    formState,
    hasError
  } = props;
  
  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
      >
        { profile==='abc' ? (
          <Grid
              item
              md={12}
              xs={12}
            >
            <TextField
              fullWidth
              label="Current Password"
              margin="dense"
              error={hasError('oldPassword')}
              name="oldPassword"
              onChange={onChange}
              required
              value={formState.values.oldPassword || ''}
              variant="outlined"
              type="password"
              helperText={
                hasError('oldPassword') ? formState.errors.oldPassword[0] : null
              }
            >
            </TextField>
          </Grid>
        ) : null}
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="New Password"
            margin="dense"
            error={hasError('newPassword')}
            name="newPassword"
            onChange={onChange}
            required
            value={formState.values.newPassword || ''}
            variant="outlined"
            type="password"
            helperText={
              hasError('newPassword') ? formState.errors.newPassword[0] : null
            }
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="Confirm Password"
            margin="dense"
            error={hasError('confirmPassword')}
            name="confirmPassword"
            onChange={onChange}
            required
            value={formState.values.confirmPassword || ''}
            variant="outlined"
            type="password"
            helperText={
              hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withErrorHandler(PasswordInfo, axios)