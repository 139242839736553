import React from 'react';
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import {
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  forgetDeviceButton: {
    margin: '10px 0'
  },
  resetMFAButton: {
    margin: '10px 0'
  }
}));

const Mfa = props => {
  const { id, onForgetDeviceClick, onResetMFAClick, profile, mode, user } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <CardHeader
        title={'Multi-Factor Authentication'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            {mode !== 'add' ? (
              <Button className={classes.resetMFAButton} variant="contained" color="secondary" onClick={() => onResetMFAClick(id)}>
                Reset MFA
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            {mode !== 'add' && user.otp_remember_device ? (
              <React.Fragment>
                <Button className={classes.forgetDeviceButton} variant="contained" color="secondary" onClick={() => onForgetDeviceClick(id)}>
                  Forget Device
                </Button>
                <Typography>{user.otp_ip}<br></br>{user.otp_device}</Typography>
              </React.Fragment>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
};

export default withErrorHandler(Mfa, axios)