import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Alert, AlertTitle} from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0px',
    color: '#fff'
  }
}));

const MessageBar = props => {
  const { severity, title, richContent, message, ...rest } = props;

  const classes = useStyles();

  return (
    richContent ? 
      <Alert
        variant="filled"
        color={severity}
        severity={severity}
        classes={classes}
        {...rest}
      >
        {title ?
        <AlertTitle classes={classes}>{title}</AlertTitle>
        : null}
        <div>
          DataMerch Pro membership with our merchant subscriptions tool is now available! See the 
          <RouterLink to="/pro" target="_blank">
            <Button
              className={classes.button}
            >
              video
            </Button>
          </RouterLink>
           and instructions to upgrade and activate merchant subscriptions 
           <RouterLink to="/docs/Merchant_subscriptions_directions.pdf" target="_blank">
            <Button
              className={classes.button}
            >
              here
            </Button>
          </RouterLink>.
        </div>
      </Alert>
      : <Alert
        variant="filled"
        color={severity}
        severity={severity}
        classes={classes}
        {...rest}
      >
        {title ?
        <AlertTitle classes={classes}>{title}</AlertTitle>
        : null}
        {message}
      </Alert>
    
    
    // <Alert
    //   variant="filled"
    //   color={severity}
    //   severity={severity}
    //   classes={classes}
    //   {...rest}
    // >
    //   {title ?
    //   <AlertTitle classes={classes}>{title}</AlertTitle>
    //   : null}
    //   {richContent ?
    //   <div>
    //     <AlertTitle classes={classes}>{title}</AlertTitle>
    //   </div>
    //   : null}
    //   {message}
    // </Alert>
  );
};

MessageBar.propTypes = {
  severity: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
};

export default MessageBar;
