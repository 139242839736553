import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Typography
} from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import PersonIcon from '@material-ui/icons/Person';

import { numberWithCommas } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  source: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  sourceIcon: {
    color: theme.palette.icon
  }
}));

const Chart = props => {
  const { data } = props;
  
  const classes = useStyles();
  const theme = useTheme();

  const chart_data = {
    datasets: [
      {
        data: [data.total_api_searches, data.total_web_searches],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.error.main,
          theme.palette.warning.main
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: data.labels
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const sources = [
    {
      title: 'API',
      value: `${data.percent_api_searches}%`,
      icon: <ComputerIcon />,
      color: theme.palette.primary.main
    },
    {
      title: 'Web',
      value: `${data.percent_web_searches}%`,
      icon: <PersonIcon />,
      color: theme.palette.error.main
    }
  ];

  return (
    <div>
      <div className={classes.chartContainer}>
        <Doughnut
          data={chart_data}
          options={options}
        />
      </div>
      <div className={classes.stats}>
        {sources.map(source => (
          <div
            className={classes.source}
            key={source.title}
          >
            <Typography variant="body1">{source.title}</Typography>
            <Typography
              style={{ color: source.color }}
              variant="h2"
            >
              {source.value}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chart;
