import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  token: null,
  isLoggedIn: false,
  user: {
    account_resource_id: null,
    first_name: null,
    last_name: null,
    account_name: null,
    is_admin: false,
    is_dm_admin: false,
    mfa_verified: false,
    mfa_required: false,
    mfa_enabled: false,
    account_merchant_subscription_enabled: false,
    plan_level: 'BASIC',
    account_merchant_subscription_notification_email: null,
    account_merchant_subscription_notification_webhook_url: null
  },
  error: null,
  message: null,
  loading: false,
  isAdmin: true,
  authRedirectPath: '/',
  attemptedRoute: null,
  otp_url: null,
  otp_secret: null
}

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    user: action.user,
    error: null,
    loading: false,
    attemptedRoute: action.attemptedRoute
  })
}

const authUpdateAccount = (state, action) => {
  let user = state.user
  user.plan_level = action.accountData.plan_level
  user.account_merchant_subscription_enabled = action.accountData.merchant_subscription_enabled
  user.account_merchant_subscription_notification_email = action.accountData.merchant_subscription_notification_email
  user.account_merchant_subscription_notification_webhook_url = action.accountData.merchant_subscription_notification_webhook
  return updateObject(state, {
    user: user
  })

  // return { ...state, user: [...state.user, user] };
}

const resetMfaSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const authLogout = (state, action) => {
  return updateObject(state, {token: null})
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {authRedirectPath: action.path})
}

const updateProfileStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateProfileFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const updateProfileSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      user: action.userData
  } )
}

const passwordResetStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const passwordResetFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const passwordResetSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false
  } )
}

const forgotPasswordStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const forgotPasswordFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const forgotPasswordSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      message: action.message
  } )
}

const agreeToTermsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  })
}

const agreeToTermsSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    error: null,
    loading: false
  })
}

const agreeToTermsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const subscriptionCreateStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const subscriptionCreateSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      user: action.user
  } );
};

const subscriptionCreateFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const subscriptionCancelStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const subscriptionCancelSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      user: action.user
  } );
};

const subscriptionCancelFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const fetchDashboardUpdatesStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchDashboardUpdatesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.user
  })
}

const fetchDashboardUpdatesFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchOTPURLStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  })
}

const fetchOTPURLSuccess = (state, action) => {
  return updateObject(state, {
    otp_secret: action.url.otp_secret,
    otp_url: action.url.otp_url,
    error: null,
    loading: false
  })
}

const fetchOTPURLFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const newOtpStart = (state, action) => {
  const user = state.user
  user.mfa_verified = false
  user.mfa_enabled = false
  return updateObject(state, {
    user: user,
    error: null,
    loading: true
  })
}

const newOtpSuccess = (state, action) => {
  const user = state.user
  user.mfa_verified = true
  user.mfa_enabled = true
  return updateObject(state, {
    user: user,
    error: null,
    loading: false
  })
}

const newOtpFail = (state, action) => {
  const user = state.user
  user.mfa_verified = false
  user.mfa_enabled = false
  return updateObject(state, {
    user: user,
    error: action.error,
    loading: false
  })
}

const verifyOtpStart = (state, action) => {
  const user = state.user
  user.mfa_verified = false
  return updateObject(state, {
    user: user,
    error: null,
    loading: true
  })
}

const verifyOtpSuccess = (state, action) => {
  const user = state.user
  user.mfa_verified = true
  return updateObject(state, {
    user: user,
    error: null,
    loading: false
  })
}

const verifyOtpFail = (state, action) => {
  const user = state.user
  user.mfa_verified = false
  return updateObject(state, {
    user: user,
    error: action.error,
    loading: false
  })
}




const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.AUTH_START: return authStart(state, action)
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
    case actionTypes.AUTH_FAIL: return authFail(state, action)
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action)
    case actionTypes.AUTH_UPDATE_ACCOUNT: return authUpdateAccount(state, action)
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action)
    case actionTypes.UPDATE_PROFILE_SUCCESS: return updateProfileSuccess(state, action)
    case actionTypes.UPDATE_PROFILE_START: return updateProfileStart(state, action)
    case actionTypes.UPDATE_PROFILE_FAIL: return updateProfileFail(state, action)
    case actionTypes.PASSWORD_RESET_SUCCESS: return passwordResetSuccess(state, action)
    case actionTypes.PASSWORD_RESET_START: return passwordResetStart(state, action)
    case actionTypes.PASSWORD_RESET_FAIL: return passwordResetFail(state, action)
    case actionTypes.FORGOT_PASSWORD_SUCCESS: return forgotPasswordSuccess(state, action)
    case actionTypes.FORGOT_PASSWORD_START: return forgotPasswordStart(state, action)
    case actionTypes.FORGOT_PASSWORD_FAIL: return forgotPasswordFail(state, action)
    case actionTypes.AGREE_TO_TERMS_SUCCESS: return agreeToTermsSuccess(state, action)
    case actionTypes.AGREE_TO_TERMS_START: return agreeToTermsStart(state, action)
    case actionTypes.AGREE_TO_TERMS_FAIL: return agreeToTermsFail(state, action)
    case actionTypes.SUBSCRIPTION_CREATE_START: return subscriptionCreateStart(state, action)
    case actionTypes.SUBSCRIPTION_CREATE_SUCCESS: return subscriptionCreateSuccess(state, action)
    case actionTypes.SUBSCRIPTION_CREATE_FAIL: return subscriptionCreateFail(state, action)
    case actionTypes.SUBSCRIPTION_CANCEL_START: return subscriptionCancelStart(state, action)
    case actionTypes.SUBSCRIPTION_CANCEL_SUCCESS: return subscriptionCancelSuccess(state, action)
    case actionTypes.SUBSCRIPTION_CANCEL_FAIL: return subscriptionCancelFail(state, action)
    case actionTypes.FETCH_DASHBOARDUPDATES_START: return fetchDashboardUpdatesStart(state, action)
    case actionTypes.FETCH_DASHBOARDUPDATES_SUCCESS: return fetchDashboardUpdatesSuccess(state, action)
    case actionTypes.FETCH_DASHBOARDUPDATES_FAIL: return fetchDashboardUpdatesFail(state, action)
    case actionTypes.FETCH_OTPURL_START: return fetchOTPURLStart(state, action)
    case actionTypes.FETCH_OTPURL_SUCCESS: return fetchOTPURLSuccess(state, action)
    case actionTypes.FETCH_OTPURL_FAIL: return fetchOTPURLFail(state, action)
    case actionTypes.NEW_OTP_START: return newOtpStart(state, action)
    case actionTypes.NEW_OTP_SUCCESS: return newOtpSuccess(state, action)
    case actionTypes.NEW_OTP_FAIL: return newOtpFail(state, action)
    case actionTypes.VERIFY_OTP_START: return verifyOtpStart(state, action)
    case actionTypes.VERIFY_OTP_SUCCESS: return verifyOtpSuccess(state, action)
    case actionTypes.VERIFY_OTP_FAIL: return verifyOtpFail(state, action)
    case actionTypes.AUTH_RESET_MFA_SUCCESS: return resetMfaSuccess(state, action)
    
    case 'UPDATE_CREDENTIALS':
      return {
        ...state,
        email: action.email !== undefined ? action.email : state.email,
        password: action.password !== undefined ? action.password : state.password
      }
    case 'UPDATE_EMAIL':
      return {
        ...state,
        email: action.field
      }
    case 'UPDATE_PASSWORD':
      return {
        ...state,
        password: action.field
      }
    case 'LOGIN_USER':
      return {
        ...state,
        user: action.user,
        isLoggedIn: true
      }
    case 'LOGIN_ERROR':
      return {
        ...state,
        error: [action.errors]
      }
    case 'LOGOUT_USER':
      return {
        ...initialState,
        attemptedRoute: state.attemptedRoute
      }
    case 'ATTEMPTED_ROUTE':
      return {
        ...state,
        attemptedRoute: action.from
      }
    default: return state
  }
}

export default reducer 