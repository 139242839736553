import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { MerchantSummary } from '../../../components/Merchant';
import { NoteList } from '../../../components/Note';

import { CircularProgress, Backdrop } from '@material-ui/core';

import queryString from 'query-string';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  },
  members: {
    marginTop: '30px'
  }
}));

const MerchantView = props => {
  const classes = useStyles();
  
  const { onFetchMerchant, merchant, accounts, is_dm_admin, is_admin, account_merchant_subscription_enabled, plan_level, merchant_add, onToggleMerchantSubscription, onFetchMembers, loading_accounts, token} = props
  const { id } = props.match.params

  const parsed = queryString.parse(props.location.search)
  const highlight = parsed.highlight || null

  useEffect(() => {
    onFetchMerchant(token, id)
    onFetchMembers(token)
  }, [onFetchMerchant, token, id])

  // const handleSubmitClick = (event, merchant) => {
  //   event.preventDefault();
    
  //   const merchantData = {
  //     merchant: {
  //       id: merchant.id,
  //       fein: merchant.fein,
  //       legal_name: merchant.legal_name,
  //       dba: merchant.dba,
  //       city: merchant.city,
  //       state: merchant.state,
  //       business_phone: merchant.business_phone,
  //       business_startdate: merchant.business_startdate,
  //       industry: merchant.industry
  //     }
  //   }
  //   onMerchantSave(merchantData, id)
  // };

  const handleMerchantSubscriptionToggle = (event) => {
    onToggleMerchantSubscription(merchant.resource_id)
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving){
    spinner = null
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MerchantSummary
          merchant={merchant}
          is_dm_admin={is_dm_admin}
          is_admin={is_admin}
          account_merchant_subscription_enabled={account_merchant_subscription_enabled}
          onMerchantSubscriptionToggle={handleMerchantSubscriptionToggle}
          plan_level={plan_level}
        />
        <NoteList
          className={classes.members}
          merchantId={id}
          accounts={accounts}
          loading_accounts={loading_accounts}
          is_dm_admin={is_dm_admin}
          merchant_add={undefined}
          highlight={highlight}
        />
        {spinner}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchant: state.merchant.merchant,
    saving: state.merchant.saving,
    token: state.auth.token,
    is_dm_admin: state.auth.user.is_dm_admin,
    loading_accounts: state.account.loading,
    accounts: state.account.accounts,
    is_admin: state.auth.user.is_admin,
    account_merchant_subscription_enabled: state.auth.user.account_merchant_subscription_enabled,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchMerchant: (token, id) => dispatch(actions.fetchMerchant(token, id)),
    onMerchantSave: (merchantData, id) => dispatch(actions.updateMerchant(merchantData, id)),
    onFetchMembers: (token) => dispatch(actions.fetchDatamerchMembers(token)),
    onToggleMerchantSubscription: (id) => dispatch(actions.toggleMerchantSubscription(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantView, axios))