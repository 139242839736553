import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button,
  CardHeader,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

const useStyles = makeStyles(() => ({
  root: {}
}));

const DisputeForm = props => {
  const { className, dispute, onSubmitClick, account_view, ...rest } = props;
  const [id, setId] = useState(null)
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [fein, setFein] = useState('')
  const [legal_name, setLegalName] = useState('')
  const [dba, setDba] = useState('')
  const [city, setCity] = useState('')
  const [business_state, setBusinessState] = useState('')
  const [reason, setReason] = useState('')

  let title = "Merchant Dispute"
  if (account_view) {
    title = "Merchant Dispute"
  }

  const classes = useStyles();

  useEffect(() => {
    if(dispute && dispute.id){
      setId(dispute.id)
      setFirstName(dispute.first_name)
      setLastName(dispute.last_name)
      setEmail(dispute.email)
      setFein(dispute.fein)
      setLegalName(dispute.legal_name)
      setDba(dispute.dba)
      setCity(dispute.city)
      setBusinessState(dispute.business_state)
      setReason(dispute.reason)
    }
  },[dispute])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'first_name':
        setFirstName(event.target.value)
        break
      case 'last_name':
        setLastName(event.target.value)
        break
      case 'email':
        setEmail(event.target.value)
        break
      case 'fein':
        setFein(event.target.value)
        break
      case 'legal_name':
        setLegalName(event.target.value)
        break
      case 'dba':
        setDba(event.target.value)
        break
      case 'city':
        setCity(event.target.value)
        break
      case 'business_state':
        setBusinessState(event.target.value)
        break
      case 'reason':
        setReason(event.target.value)
        break
      default:
        return null
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      >
      <form
        autoComplete="off"
      >
        <CardHeader
          title={title}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="EIN"
                margin="dense"
                name="fein"
                onChange={(event) => handleInputChange(event, 'fein')}
                required
                value={fein || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="First Name"
                margin="dense"
                name="first_name"
                onChange={(event) => handleInputChange(event, 'first_name')}
                required
                value={first_name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last Name"
                margin="dense"
                name="last_name"
                onChange={(event) => handleInputChange(event, 'last_name')}
                required
                value={last_name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                onChange={(event) => handleInputChange(event, 'email')}
                required
                value={email || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Legal Business Name"
                margin="dense"
                name="legal_name"
                onChange={(event) => handleInputChange(event, 'legal_name')}
                required
                value={legal_name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="DBA"
                margin="dense"
                name="dba"
                onChange={(event) => handleInputChange(event, 'dba')}
                value={dba || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="city"
                onChange={(event) => handleInputChange(event, 'city')}
                required
                value={city || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="State"
                margin="dense"
                name="business_state"
                onChange={(event) => handleInputChange(event, 'business_state')}
                required
                value={business_state || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Reason for Dispute"
                margin="dense"
                name="reason"
                onChange={(event) => handleInputChange(event, 'reason')}
                required
                value={reason || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={(event, dispute) => onSubmitClick(event, {
              id: id,
              first_name: first_name,
              last_name: last_name,
              email: email,
              fein: fein,
              legal_name: legal_name,
              dba: dba,
              city: city,
              business_state: business_state,
              reason: reason
            })}
          >
            Submit Dispute
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};


export default withErrorHandler(DisputeForm, axios)