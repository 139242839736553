import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import {authHeaders} from '../../../helpers'


import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { DisputeForm } from '../../../components/Dispute';
import {addToast} from '../../../store/actions/toasts' 
import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const DisputeAdd = props => {
  const { id, dialog_id, onDisputeSave } = props;
  const [feinIsValid, setFeinIsValid] = useState(false)
  const [localDialogId, setLocalDialogId] = useState(null)
  const [dispute, setDispute] = useState({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    fein: '',
    legal_name: '',
    dba: '',
    city: '',
    business_state: '',
    reason: ''
  })

  useEffect(() => {
    setDispute({
      id: null,
      first_name: '',
      last_name: '',
      email: '',
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      business_state: '',
      reason: ''
    })
  }, [id])
  
  const classes = useStyles();

  const handleSubmitClick = (event, dispute) => {
    event.preventDefault();
  
    const disputeData = {
      dispute: {
        id: dispute.id,
        first_name: dispute.first_name,
        last_name: dispute.last_name,
        email: dispute.email,
        fein: dispute.fein,
        legal_name: dispute.legal_name,
        dba: dispute.dba,
        city: dispute.city,
        business_state: dispute.business_state,
        reason: dispute.reason
      }
    }
    onDisputeSave(disputeData)
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving && !props.loading){
    spinner = null
  }

  const  disputeForm = (
    <div className={classes.root}>
      <div className={classes.content}>
        <DisputeForm dispute={dispute} onSubmitClick={handleSubmitClick} />
        {spinner}
      </div>
    </div>
  );
  
  return disputeForm;
};

const mapStateToProps = state => {
  return {
    saving: state.dispute.saving,
    loading: state.dispute.loading,
    id: state.dispute.id,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDisputeSave: (disputeData) => dispatch(actions.addDispute(disputeData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(DisputeAdd, axios))