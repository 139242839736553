import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  list: {
    paddingLeft: theme.spacing(3)
  }
}));

const PrivacyPolicy  = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Privacy Policy" />
          <Divider />
          <CardContent className={classes.terms}>
            <Typography
              variant="h5"
            >
              PLEASE READ THIS PRIVACY POLICY CAREFULLY - BY ACCESSING AND USING THIS WEBSITE, YOU CONFIRM THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THIS PRIVACY POLICY IN ITS ENTIRETY.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              DATAMERCH LLC PRIVACY POLICY
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Welcome to Datamerch. The privacy of all information stored and shared on our site is extremely important to us. Datamerch LLC operates Datamerch.com, a members-only online information sharing database where Funders in the cash advance industry can share data on unsatisfactory Merchant Accounts and cross reference underwrite to avoid potential losses.
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              This "Privacy Policy" governs the collection, storage, and use of personal information collected by Datamerch LLC and any subsidiaries, (collectively, "Datamerch," "we," "us," or "our") of Datamerch. Member(s) ("Member(s)") and all visitors ("Users," “Funders” and, collectively with "Member(s),""you" and "your") via the website at www.datamerch.com (including all subdomains, the "Site"), and by telephone, facsimile, email, or other means (collectively, and together with the Site, the "Datamerch Database"). Merchant(s) (“Merchant(s),” “Customer,” “Merchant Accounts”) and all third-party information stored within the Site.
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              This Privacy Policy provides details about personal information we collect from you, and how we use this information. You should read this Privacy Policy carefully before accessing and using the Datamerch database.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              INFORMATION WE COLLECT
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Datamerch collects four categories of information:
              <ul className={classes.list}>
                <li>
                  Information from all Site Users to share across our database about unsatisfactory Merchant Accounts
                </li>
                <li>
                  Personal information about Merchants required to verify the business he or she owns (sole proprietors only)
                </li>
                <li>
                  Business information of Merchants necessary to verify the business he or she owns
                </li>
                <li>
                  Optional information to share amongst other Site Users to further define the risk associated with Merchant Accounts
                </li>
              </ul>
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              OWNERSHIP OF INFORMATION
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Any and all Member, Funder, Merchant, User, Customer information that is entered on our Site is the property of Datamerch. We are not under any obligation to return, provide copies, or provide any details on information that is stored in the Datamerch Database.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              MEMBERSHIP REQUIREMENTS
            </Typography>
            <Typography
              variant="subtitle2"
            >
              The Site is intended for use by Funding organizations only. Membership sign up is required for each organization. By using this Site, you are certifying that you are an employee of or have a relationship with one of our Member Funding organizations.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              AGE REQUIREMENTS
            </Typography>
            <Typography
              variant="subtitle2"
            >
              We do not knowingly collect personally identifiable information from any person under the age of eighteen (18). By using this Site, you are certifying that you will not knowingly enter any personally identifiable information about any person under the age of eighteen (18). If you become aware of any information in our database of a person under the age of eighteen (18), please notify us immediately at <a href="mailto:support@datamerch.com">support@datamerch.com</a>.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              INFORMATION WE COLLECT FROM SITE USERS
            </Typography>
            <Typography
              variant="subtitle1"
            >
              Computer
            </Typography>
            <Typography
              variant="subtitle2"
            >
              We collect information about your computer and your visits to our web site, such as your IP address, geographical location, browser type, referral source, length of visit, page views, and other information. We use this aggregated information in the administration of our web site to improve its usability and to evaluate the success of particular marketing/advertising campaigns, search engine optimization strategies, and other marketing activities.
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle1"
            >
              Cookies
            </Typography>
            <Typography
              variant="subtitle2"
            >
              We use cookies on our web site. This enables the web server to identify and track the web browser and load the pages according to a user's preferences for that particular site, including the personalization of content. Cookies are also used to gather statistical data, such as which pages are visited, what is downloaded, domain name and country of origin, and the addresses of sites visited before and aftercoming to our site, as well as Member "click stream" activity (meaning, the paths taken by visitors to our site as they navigate from page to page) and transactional attributes in accordance with information Members voluntarily submit in the course of using our site. This data is aggregated for analysis to ensure proper functioning of our site, as well as to evaluate the effectiveness of our marketing efforts. More importantly, using cookies also helps us protect the security of Member accounts.
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle1"
            >
              Contact Information
            </Typography>
            <Typography
              variant="subtitle2"
            >
              In addition, we gather the names and email addresses or other contact information of anyone who contacts us with questions about our company or operations to serve the respective needs of these individuals and assist these individuals as appropriate.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              INFORMATION WE COLLECT FROM MEMBERS
            </Typography>
            <Typography
              variant="subtitle1"
            >
              Information Collected
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Certain personal and business information about Merchants is collected and entered by our Site Users and Members. Information we collect about Merchants includes, but is not limited to: Email addresses, Full Names (sole proprietors only), Telephone Numbers, Federal Employer Identification Numbers, Business Legal Names, Doing Business As Names, Business Addresses, Additional Information including Notes and Optional Information entered by our Members about Merchant Accounts.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              USES OF INFORMATION WE COLLECT FROM MEMBERS
            </Typography>
            <Typography
              variant="subtitle2"
            >
              We share information collected from our Members across our Site so other Members can search for this information and make underwriting judgments based on this information.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              OPT-OUT PROCEDURE
            </Typography>
            <Typography
              variant="subtitle2"
            >
              To opt-out of all further contact initiated by us, and to forestall our further use or disclosure of your personal information, email us any time at: <a href="mailto:support@datamerch.com">support@datamerch.com</a>. Opting out of all communication in this manner means that you must discontinue all use of the Service.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              DATAMERCH SECURITY POLICY
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Datamerch safeguards all sensitive information through physical, electronic, and operational policies and practices. The data is considered highly confidential. The physical security of the data is achieved through a combination of network firewalls, encryption, and servers with hardened operating systems. Access to the system, both physical and electronic, is carefully controlled.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              PASSWORDS
            </Typography>
            <Typography
              variant="subtitle2"
            >
              For security purposes, Datamerch requires passwords. We have also instituted admin accounts per Member so they will be able to assign unique sub-account logins for other employees. We require these sub-accounts to be disabled if an employee with access to Datamerch is terminated or is no longer employed with said Member. Please notify us immediately at support@datamerch.com if we need to disable an account.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              WE MAY MAKE CHANGES TO THIS POLICY
            </Typography>
            <Typography
              variant="subtitle2"
            >
              This Privacy Policy is subject to change. You understand and agree that your continued use of the Site after we have made any such changes constitutes your acceptance of the most current Privacy Policy, as amended.
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              CONTACTING DATAMERCH
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Please contact us with any questions regarding this policy:
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              DataMerch LLC
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Attn: Compliance Department
            </Typography>
            <Typography
              variant="subtitle2"
            >
              23110 State Road 54, #247
            </Typography>
            <Typography
              variant="subtitle2"
            >
              Lutz, FL 33549
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              Email Address:
            </Typography>
            <Typography
              variant="subtitle2"
            >
              <a href="mailto:support@datamerch.com">support@datamerch.com</a>
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              Telephone number:
            </Typography>
            <Typography
              variant="subtitle2"
            >
              (866)549-3114
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
