import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { DeletedMerchantSummary } from '../../../components/Merchant';
import { DeletedNoteList } from '../../../components/Note';

import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  },
  members: {
    marginTop: '30px'
  }
}));

const DeletedMerchantView = props => {
  const classes = useStyles();
  
  const { onFetchMerchant, merchant, token} = props
  const { id } = props.match.params
  
  useEffect(() => {
    onFetchMerchant(token, id);
  }, [onFetchMerchant, token, id])

  // const handleSubmitClick = (event, merchant) => {
  //   event.preventDefault();
    
  //   const merchantData = {
  //     merchant: {
  //       id: merchant.id,
  //       fein: merchant.fein,
  //       legal_name: merchant.legal_name,
  //       dba: merchant.dba,
  //       city: merchant.city,
  //       state: merchant.state,
  //       business_phone: merchant.business_phone,
  //       business_startdate: merchant.business_startdate,
  //       industry: merchant.industry
  //     }
  //   }
  //   onMerchantSave(merchantData, id)
  // };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving){
    spinner = null
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DeletedMerchantSummary merchant={merchant} />
        <DeletedNoteList className={classes.members} merchantId={id}/>
        {spinner}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    merchant: state.deletedMerchant.merchant,
    saving: state.merchant.saving,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchMerchant: (token, id) => dispatch(actions.fetchDeletedMerchant(token, id)),
    onMerchantSave: (merchantData, id) => dispatch(actions.updateMerchant(merchantData, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(DeletedMerchantView, axios))