import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  notes: [],
  note: {
    id: null,
    category: '',
    note: '',
    submission_source: '',
    broker_name: ''
  },
  loading: false,
  saving: false,
  modalOpen: false,
  disputeModalOpen: false
}

const fetchNotesStart = (state, action) => {
  return updateObject(state, {loading: true, notes:[], note: null})
}

const fetchNotesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false, notes: action.notes
  })
}

const fetchNotesFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchNoteStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchNoteSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    note: action.note
  })
}

const fetchNoteFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const addNoteStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const addNoteSuccess = ( state, action ) => {
  // const newNotes = .concat(action.noteData)
  const newNotes = [action.noteData].concat(state.notes)
  
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id,
      notes: newNotes,
      modalOpen: false,
      disputeModalOpen: false
  } );
};

const addNoteFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const updateNoteStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateNoteSuccess = ( state, action ) => {
  const updatedNotes = state.notes.map(note => {
    if (note.resource_id === action.id) {
      return {
        ...action.noteData
      }
    } else {
      return note
    }
  })

  return updateObject( state, {
      loading: false,
      saving: false,
      modalOpen: false,
      disputeModalOpen: false,
      notes: updatedNotes
  } );
};

const updateNoteFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const disputeNoteStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const disputeNoteSuccess = ( state, action ) => {
  const updatedNotes = state.notes.map(note => {
    if (note.resource_id === action.id) {
      return {
        ...action.noteData
      }
    } else {
      return note
    }
  })

  return updateObject( state, {
      loading: false,
      saving: false,
      modalOpen: false,
      disputeModalOpen: false,
      notes: updatedNotes
  } );
};

const disputeNoteFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const archiveNoteStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const archiveNoteSuccess = ( state, action ) => {
  const updatedNotes = state.notes.filter(note => {
    return note.resource_id !== action.id
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      notes: updatedNotes
  } );
};

const archiveNoteFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const modalOpen = (state, action) => {
  return updateObject( state, {
    modalOpen: true
  } );
}

const modalClose = (state, action) => {
  return updateObject( state, {
    modalOpen: false
  } );
}

const disputeModalOpen = (state, action) => {
  return updateObject( state, {
    disputeModalOpen: true
  } );
}

const disputeModalClose = (state, action) => {
  return updateObject( state, {
    disputeModalOpen: false
  } );
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_NOTES_START:
      return fetchNotesStart(state, action)
    case actionTypes.FETCH_NOTES_SUCCESS:
      return fetchNotesSuccess(state, action)
    case actionTypes.FETCH_NOTES_FAIL:
      return fetchNotesFail(state, action)
    case actionTypes.FETCH_NOTE_START:
      return fetchNoteStart(state, action)
    case actionTypes.FETCH_NOTE_SUCCESS:
      return fetchNoteSuccess(state, action)
    case actionTypes.FETCH_NOTE_FAIL:
      return fetchNoteFail(state, action)
    case actionTypes.UPDATE_NOTE_START:
      return updateNoteStart(state, action)
    case actionTypes.UPDATE_NOTE_SUCCESS:
      return updateNoteSuccess(state, action)
    case actionTypes.UPDATE_NOTE_FAIL:
      return updateNoteFail(state, action)
    case actionTypes.DISPUTE_NOTE_START:
      return disputeNoteStart(state, action)
    case actionTypes.DISPUTE_NOTE_SUCCESS:
      return disputeNoteSuccess(state, action)
    case actionTypes.DISPUTE_NOTE_FAIL:
      return disputeNoteFail(state, action)
    case actionTypes.ADD_NOTE_START:
      return addNoteStart(state, action)
    case actionTypes.ADD_NOTE_SUCCESS:
      return addNoteSuccess(state, action)
    case actionTypes.ADD_NOTE_FAIL:
      return addNoteFail(state, action)
    case actionTypes.ARCHIVE_NOTE_START:
      return archiveNoteStart(state, action)
    case actionTypes.ARCHIVE_NOTE_SUCCESS:
      return archiveNoteSuccess(state, action)
    case actionTypes.ARCHIVE_NOTE_FAIL:
      return archiveNoteFail(state, action)
    case actionTypes.OPEN_NOTE_MODAL:
      return modalOpen(state, action)
    case actionTypes.CLOSE_NOTE_MODAL:
      return modalClose(state, action)
    case actionTypes.OPEN_NOTE_DISPUTE_MODAL:
      return disputeModalOpen(state, action)
    case actionTypes.CLOSE_NOTE_DISPUTE_MODAL:
      return disputeModalClose(state, action)
    default:
      return state
  }
}
    
export default reducer
