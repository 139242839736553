import React, { useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import UploadService from "../../../services/importUploadService";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 0,
    marginTop: '20px',
    marginBottom: '16px',
    marginRight: '20px',
    verticalAlign: 'bottom'
  },
  // colorPrimary: {
  //   backgroundColor: "#EEEEEE",
  // },
  // bar: {
  //   borderRadius: 5,
  //   backgroundColor: '#1a90ff',
  // },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  btn_choose: {
    marginTop: '20px'
  },
  btn_upload: {
    marginTop: '20px',
    marginRight: '20px',
    '& span': {
      color: '#fff !important'
    }
  },
  btn_upload_filename: {
    verticalAlign: 'bottom'
  },
  btn_upload_progress: {
    marginTop: '20px',
    marginRight: '20px'
  },
}));

const UploadFile = props => {
  const { accountId, uploadType, onUploadComplete, accept } = props
  const [open, setOpen] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [message, setMessage] = useState('')
  const [fileInfos, setFileInfos] = useState([])
  const [isError, setIsError] = useState(false)

  const classes = useStyles();

  const selectFile = (e) => {
    setSelectedFiles(e.target.files)
  }

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0)
    setCurrentFile(currentFile)

    UploadService.upload(accountId, currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total))
    })
      .then((response) => {
        setMessage(response.data.message)
        setIsError(false)
        onUploadComplete()
        return true
      })
      .then((files) => {
        setFileInfos(files.data)
      })
      .catch((e) => {
        setProgress(0)
        setMessage("Could not upload the file.")
        setCurrentFile(null)
        setIsError(true)
      });

    setSelectedFiles(null)
  }
  
  return (
    <div>
      {currentFile && progress < 100 &&
        <Box display="flex" alignItems="center">
          <Box width="100%">
            <BorderLinearProgress variant="indeterminate" />
          </Box>
        </Box>
      }

      {(!selectedFiles || selectedFiles.length == 0) && !currentFile &&
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            onChange={selectFile}
            accept={accept || ""}  
          />
          <Button
            className={classes.btn_choose}
            variant="outlined"
            component="span" >
              Choose File
          </Button>
        </label>
      }
      {selectedFiles && selectedFiles.length > 0 && 
        <div className={classes.file_name}>
          <Button
            className={classes.btn_upload}
            color="primary"
            variant="contained"
            component="span"
            disabled={!selectedFiles}
            onClick={upload}>
            Upload
          </Button>
          <span className={classes.btn_upload_filename}>
            {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
          </span>
        </div>
      }

      {progress >= 100 &&
        <>
          <Box display="flex" alignItems="center">
            <Box width="100%">
              <BorderLinearProgress variant="determinate" value={100} />
            </Box>
          </Box>
        </>
      }

      <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
        {message}
      </Typography>
    </div >
  )
};

export default UploadFile