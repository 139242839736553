import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { AccountForm } from '../../../components/Account';
import { AccountSecurity } from '../../../components/Account';
import { Membership } from '../../../components/Account';
import { Contribution } from '../../../components/Account';
import { UserList } from '../../../components/User';

import {RecentMerchantsByCategory} from '../../../components'
import {SearchesByDay} from '../../../components'
import {AdditionsByDay} from '../../../components'
import {SearchesBySource} from '../../../components'
import {HitsByCategory} from '../../../components'
import {TotalMerchants} from '../../../components'
import {TotalNotes} from '../../../components'

import { InvoicesList, PaymentMethod } from '../../../components/Account';
import {addToast} from '../../../store/actions/toasts'

import { 
  CircularProgress,
  Backdrop,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  AppBar,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  backdrop: {
    zIndex: '100'
  },
  members: {
    marginTop: '30px'
  },
  dividerMinHeight: {
    margin: '50px 0 50px 0'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AccountEdit = props => {
  const classes = useStyles();
  const [api_access, setApiAccess] = useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [value, setValue] = React.useState(0);

  const { 
    onFetchAccount,
    account,
    onAccountSave,
    onShowPaymentMethodUpdatedToast,
    onCancelMembership,
    onStartTrial,
    onCancelTrial,
    onAccountContributionOverrideToggle,
    onAccountContributionRecalculate,
    onMfaRequiredToggle,
    current_user,
    token,
    is_dm_admin,
    onUpgradeMembership,
    onDowngradeMembership
  } = props

  const { id } = props.match.params
  
  useEffect(() => {
    onFetchAccount(token, id);
    handleTabNav();
  }, [onFetchAccount, token, id])

  useEffect(() => {
    if(account != null){
      setApiAccess(account.can_access_api)
    }
  }, [account, api_access])

  const handleTabNav = () => {
    if (window.location.href.indexOf("#billing_updated") > -1) {
      setValue(4);
      onShowPaymentMethodUpdatedToast({
        message: 'Your payment method has been updated.',
        severity: 'success',
        duration: 10000
      })
    }
  }

  const handleSubmitClick = (event, account) => {
    event.preventDefault();
  
    const accountData = {
      account: {
        id: account.id,
        name: account.name,
        website: account.website,
        primaryContactEmail: account.primaryContactEmail,
        merchant_subscription_enabled: account.merchant_subscription_enabled,
        merchant_subscription_notification_email: account.merchant_subscription_notification_email,
        merchant_subscription_notification_webhook: account.merchant_subscription_notification_webhook
      }
    }
    onAccountSave(token, accountData, id)
  };

  const handleAccountContributionOverrideToggle = (event, account) => {
    event.preventDefault();
  
    onAccountContributionOverrideToggle(id)
  };

  const handleAccountContributionRecalculate = (event, account) => {
    event.preventDefault();
  
    onAccountContributionRecalculate(id)
  };

  const handleCancelMembershipClick = () => {
    setOpenDialog(true);
  }

  const handleStartTrialClick = () => {
    onStartTrial(id)
  }

  const handleCancelTrialClick = () => {
    onCancelTrial(id)
  }

  const handleUpgradeMembershipClick = () => {
    onUpgradeMembership(id, false)
  }

  const handleDowngradeMembershipClick = () => {
    onDowngradeMembership(id, false)
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOk = () => {
    onCancelMembership(id, current_user)
    setOpenDialog(false);
  };

  const handleMfaRequiredToggle = (event, account) => {
    event.preventDefault();
  
    onMfaRequiredToggle(id)
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving){
    spinner = null
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary">
            <Tab label="Funder Settings" />
            <Tab label="Membership" />
            <Tab label="Account Members" />
            <Tab label="Security" />
            <Tab label="Billing" />
            <Tab label="Contribution" />
            <Tab label="Analytics" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AccountForm account={account} onSubmitClick={handleSubmitClick} is_dm_admin={is_dm_admin} plan_level='BASIC' />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Membership account={account} onCancelMembershipClick={handleCancelMembershipClick} onStartTrialClick={handleStartTrialClick} onCancelTrialClick={handleCancelTrialClick} onUpgradeMembershipClick={handleUpgradeMembershipClick} onDowngradeMembershipClick={handleDowngradeMembershipClick}  />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserList className={classes.members} accountId={id} api_access={api_access}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AccountSecurity account={account} onMfaRequiredToggle={handleMfaRequiredToggle}/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PaymentMethod accountId={id} />
          <InvoicesList className={classes.members} accountId={id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Contribution account={account} onAccountContributionOverrideToggle={handleAccountContributionOverrideToggle} onAccountContributionRecalculate={handleAccountContributionRecalculate}  />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Grid
            container
            spacing={4}
          > 
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <TotalMerchants account={id} />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <TotalNotes account={id} />
              </Grid>
              <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <AdditionsByDay account={id} />
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <SearchesByDay account={id} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={12}
              xl={3}
              xs={12}
            >
              <SearchesBySource account={id} />
            </Grid>
            <Grid
              item
              lg={9}
              sm={12}
              xl={9}
              xs={12}
            >
              <HitsByCategory account={id} />
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
            </Grid>
          </Grid>
        </TabPanel>
        {spinner}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancel Membership"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel membership?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleOk} variant="contained" color="primary" autoFocus>
            Yes, cancel membership
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.account,
    saving: state.account.saving,
    token: state.auth.token,
    current_user: state.auth.user,
    is_dm_admin: state.auth.user.is_dm_admin,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAccount: (token, id) => dispatch(actions.fetchAccount(token, id)),
    onAccountSave: (token, accountData, id) => dispatch(actions.updateAccount(token, accountData, id)),
    onCancelMembership: (id, current_user) => dispatch(actions.subscriptionCancel(id, current_user)),
    onStartTrial: (id) => dispatch(actions.trialStart(id)),
    onCancelTrial: (id) => dispatch(actions.trialCancel(id)),
    onAccountContributionOverrideToggle: (id) => dispatch(actions.contributionOverrideToggle(id)),
    onAccountContributionRecalculate: (id) => dispatch(actions.contributionRecalculate(id)),
    onMfaRequiredToggle: (id) => dispatch(actions.mfaRequiredToggle(id)),
    onShowPaymentMethodUpdatedToast: (options) => dispatch(actions.addToast(options)),
    onUpgradeMembership: (id, account_view) => dispatch(actions.membershipUpgrade(id, account_view)),
    onDowngradeMembership: (id, account_view) => dispatch(actions.membershipDowngrade(id, account_view))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AccountEdit, axios))