import { SwitchVideo } from "@material-ui/icons";
import axios from "../httpClients/axios-datamerch";
import {authHeaders} from '../helpers'
import { fileChecksum } from '../helpers/checksum'


class ImportUploadService {
  upload = async (accountId, file, onUploadProgress, account) => {
    const createPresignedUrl = async(file, byte_size, checksum) => {
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...authHeaders().headers
        },
        body: JSON.stringify({
          file: {
            filename: file.name,
            byte_size: byte_size,
            checksum: checksum,
            content_type: 'text/csv',
            metadata: {
            }
          }
        })
      }

      // let res = await fetch(process.env.REACT_APP_API_ROOT_URL + '/upload/merchant_subscriptions', options)
      let res = await fetch(process.env.REACT_APP_API_ROOT_URL + '/presigned_url', options)
      if (res.status !== 200) return res
      return await res.json()

      // const body = {
      //   file: {
      //     filename: file.name,
      //     byte_size: byte_size,
      //     checksum: checksum,
      //     content_type: 'application/text'
      //   }
      // }

      // let path = '/upload/merchant_subscriptions'

      // let res = await axios.post(path, body, {
      //   headers: headers,
      //   onUploadProgress,
      // });

      // if (res.status !== 200) return res
      // return await res.json()
    }

    const checksum = await fileChecksum(file)
    const presignedFileParams = await createPresignedUrl(file, file.size, checksum)
  
    const s3PutOptions = {
      method: 'PUT',
      headers: presignedFileParams.direct_upload.headers,
      body: file,
    }
    let awsRes = await fetch(presignedFileParams.direct_upload.url, s3PutOptions)
    if (awsRes.status !== 200) return awsRes

    let body = JSON.stringify({
      file: presignedFileParams.blob_signed_id,
      file_name: file.name,
      file_size: file.size
    })

    const headers = {
      "Content-Type": "application/json",
      ...authHeaders().headers
    }

    let path = `/accounts/${accountId}/merchant_subscription_imports`

    let res = await axios.post(path, body, {
      headers: headers,
      onUploadProgress,
    });

    if (res.status !== 200) return res 
    return await res.json()

    // formData.append("file", file)
    // formData.append("file", file)
    // formData.append("file", file)
    

    // let path = '/upload/import'

    // switch(type){
    //   case 'merchant_subscriptions':
    //     path = '/upload/merchant_subscriptions'
    //     break
    //   default:
    //     path = '/upload/import'
    //     break
    // }

    // const headers = {
    //   "Content-Type": "multipart/form-data",
    //   ...authHeaders().headers
    // }

    // return axios.post(path, formData, {
    //   headers: headers,
    //   onUploadProgress,
    // });
  }
}

export default new ImportUploadService()