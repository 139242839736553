import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { NotesTable } from '../../Note';
import { CircularProgress, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const NoteList = props => {
  const classes = useStyles();

  const { 
    onFetchNotes,
    onNoteRestore,
    onNoteArchive,
    onNoteAdd,
    onNoteSave,
    onNoteCancel,
    onNoteDisputeCancel,
    onNoteDisputeSave,
    token,
    merchantId,
    className,
    loading,
    notes,
    accounts,
    loading_accounts,
    highlight,
    is_dm_admin,
    ...rest
  } = props
  
  useEffect(() => {
    onFetchNotes(token, merchantId);
  }, [onFetchNotes, token, merchantId])

  const handleArchiveClick = (event, noteId) => {
    event.preventDefault();

    onNoteArchive(token, noteId)
  }

  const handleSubmitNoteClick = (event, note) => {
    event.preventDefault();

    let noteData = {
      note: {
        merchant_id: merchantId,
        category: note.category,
        proxied_creator: note.proxied_creator,
        note: note.note,
        submission_source: note.submission_source,
        broker_name: note.broker_name
      }
    }

    if (note.id){
      noteData = {
        note: {
          id: note.id,
          merchant_id: merchantId,
          category: note.category,
          proxied_creator: '',
          note: note.note,
          submission_source: note.submission_source,
          broker_name: note.broker_name
        }
      }
    }

    if (note.id){
      onNoteSave(noteData, note.id)
    } else {
      onNoteAdd(noteData)
    }
  };

  const handleCancelNoteClick = (event) => {
    event.preventDefault();
    onNoteCancel()
  };


  const handleSubmitNoteDisputeClick = (event, note) => {
    event.preventDefault();
    const form = event.currentTarget.form

    let noteData = {
      note: {
        reason: ''
      }
    }

    if (note.id){
      noteData = {
        note: {
          id: note.id,
          reason: note.reason
        }
      }
    }

    if (note.id && form.reportValidity()){
      onNoteDisputeSave(noteData, note.id)
    } else {
      return false;
    }
  };

  const handleCancelNoteDisputeClick = (event) => {
    event.preventDefault();
    onNoteDisputeCancel()
  };

  let active_notes = <CircularProgress />
  if(!loading){
    active_notes = (
      <NotesTable
        notes={notes}
        onArchiveClick={(event, noteId) => handleArchiveClick(event, noteId)}
        merchant_id={merchantId}
        onSubmitNoteClick={(event, note) => handleSubmitNoteClick(event, note)}
        onCancelNoteClick={(event) => handleCancelNoteClick(event)}
        onSubmitNoteDisputeClick={(event, note) => handleSubmitNoteDisputeClick(event, note)}
        onCancelNoteDisputeClick={(event) => handleCancelNoteDisputeClick(event)}
        accounts={accounts}
        is_dm_admin={is_dm_admin}
        loading_accounts={loading_accounts}
        highlight={highlight}
      />
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Notes"
      />
      <Divider />
      {active_notes}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    notes: state.note.notes,
    loading: state.note.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchNotes: (token, merchantId) => dispatch(actions.fetchNotes(token, merchantId)),
    onNoteArchive: (token, noteId) => dispatch(actions.archiveNote(token, noteId)),
    onNoteAdd: (noteData) => dispatch(actions.addNote(noteData)),
    onNoteSave: (noteData, id) => dispatch(actions.updateNote(noteData, id)),
    onNoteCancel: () => dispatch(actions.closeNoteModal()),
    onNoteDisputeCancel: () => dispatch(actions.closeNoteDisputeModal()),
    onNoteDisputeSave: (noteData, id) => dispatch(actions.disputeNote(noteData, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(NoteList, axios))