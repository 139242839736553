import React from 'react';
import {connect} from 'react-redux'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  }
}));

const Profile = props => {
  const { className, user } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Typography
        className={classes.name}
        variant="h4"
      >
        {(user && user.first_name )|| ''} {(user && user.last_name) || ''}
      </Typography>
      <Typography
        className={classes.bio}
        variant="body2"
      >
        {(user && user.account_name) || ''}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps)(Profile)
