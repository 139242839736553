import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { MerchantSubscriptionUploadsTable } from '../../../components/MerchantSubscription';
import { MerchantSubscriptionDialog } from '../../../components/MerchantSubscription';
import { MerchantSubscriptionDeleteDialog } from '../../../components/MerchantSubscription';
import { 
  CircularProgress,
  Backdrop,
  Card,
  CardHeader,
  Divider,
  Tabs,
  Tab,
  Typography,
  Box,
  AppBar
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const MerchantSubscriptionImportList = props => {
  const classes = useStyles();
  
  const { 
    onFetchMerchantSubscriptionImports,
    merchant_subscription_imports,
    token,
    loading_imports,
    pagination,
    account_id
  } = props
  
  // let merchant_subscriptions = props.merchant_subscriptions
  // let loading = props.loading

  const [value, setValue] = useState(0);

  useEffect(() => {
    onFetchMerchantSubscriptionImports(account_id);
  }, [onFetchMerchantSubscriptionImports, account_id])

  


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  let merchantSubscriptionUploadsTable = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  if(!loading_imports){
    merchantSubscriptionUploadsTable = (
      <MerchantSubscriptionUploadsTable
        merchant_subscription_uploads={merchant_subscription_imports}
      />
    )
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <AppBar position="static" color="default">
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value="0"
            >
              <Tab label="Merchant Subscriptions" href="merchant_subscriptions"/>
              <Tab label="Uploads" href="merchant_subscription_imports" className={classes.active_tab} value="0" />
              <Tab label="Webhook Logs" href="merchant_subscription_webhook_logs" />
            </Tabs>
          </AppBar>
          <Card>
            <Divider />
            {merchantSubscriptionUploadsTable}
          </Card>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    account_id: state.auth.user.account_resource_id,
    loading_imports: state.merchantSubscriptionImport.loading,
    merchant_subscription_imports: state.merchantSubscriptionImport.merchant_subscription_imports,
  }
}

const mapDispatchToProps = dispatch => {
  return {
   onFetchMerchantSubscriptionImports: (account_id) => dispatch(actions.fetchMerchantSubscriptionImports(account_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantSubscriptionImportList, axios))