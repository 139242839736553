import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  ribbon: {
    width: '150px',
    height: '150px',
    overflow: 'hidden',
    position: 'absolute',

    '&::before': {
      position: 'absolute',
      zIndex: '-1',
      content: '""',
      display: 'block',
      border: '5px solid #2980b9',
    },
    '&::after': {
      position: 'absolute',
      zIndex: '-1',
      content: '""',
      display: 'block',
      border: '5px solid #2980b9',
    },
    '& span': {
      position: 'absolute',
      display: 'block',
      width: '225px',
      padding: '5px 0',
      backgroundColor: '#3498db',
      boxShadow: '0 5px 6px rgba(0,0,0,.1)',
      color: '#fff',
      font: "700 12px/1 'Lato', sans-serif",
      textShadow: '0 1px 1px rgba(0,0,0,.2)',
      textTransform: 'uppercase',
      textAlign: 'center',
    }
  },
  ribbon_top_left: {
    top: '8px',
    left: '8px',
    '&::before': {
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
      top: '0',
      right: '49px',
    },
    '&::after': {
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
      bottom: '49px',
      left: '0',
    },
    '& span': {
      right: '-7px',
      top: '30px',
      transform: 'rotate(-45deg)',
    }
  }
}));

const CornerRibbon = props => {
  const { corner } = props;

  const classes = useStyles();

  let cornerClass = classes.ribbon_top_left
  switch (corner){
    case 'top-left':
      cornerClass = classes.ribbon_top_left
      break
    case 'top-right':
      cornerClass = classes.ribbon_top_right
      break
    case 'bottom-left':
      cornerClass = classes.ribbon_bottom_left
      break
    case 'bottom-right':
      cornerClass = classes.ribbon_bottom_right
      break
    default:
      cornerClass = classes.ribbon_top_left
      break
  }

  return (
    <div className={`${classes.ribbon} ${cornerClass}`}>
      <span>recommended</span>
    </div>
  );
};

export default CornerRibbon;
