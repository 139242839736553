import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import {authHeaders} from '../../../helpers'


import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { MerchantForm } from '../../../components/Merchant';
import { MerchantExistsDialog } from '../../../components/Merchant';
import {addToast} from '../../../store/actions/toasts' 
import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const MerchantAdd = props => {
  const { id, dialog_id, onMerchantSave, onOpenMerchantExistsDialog, onFetchMembers, accounts, loading_accounts, token, is_dm_admin, plan_level} = props;
  const [feinIsValid, setFeinIsValid] = useState(false)
  const [localDialogId, setLocalDialogId] = useState(null)
  const [merchant, setMerchant] = useState({
    id: null,
    fein: '',
    legal_name: '',
    dba: '',
    city: '',
    state: '',
    business_phone: '',
    business_startdate: '',
    industry: '',
    proxied_creator: '',
    category: '',
    note: '',
    submission_source: '',
    broker_name: ''
  })

  useEffect(() => {
    setMerchant({
      id: null,
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      state: '',
      business_phone: '',
      business_startdate: '',
      industry: '',
      proxied_creator: '',
      category: '',
      note: '',
      submission_source: '',
      broker_name: ''
    })
    onFetchMembers(token)
  }, [onFetchMembers, token, id])
  
  const classes = useStyles();

  const handleSubmitClick = (event, merchant) => {
    event.preventDefault();
    const form = event.currentTarget.form
    if (form.reportValidity()){
      if (!feinIsValid) {
        onOpenMerchantExistsDialog(localDialogId)
        return false
      } else {
        
          let proxied_creator = null
          if (merchant.proxied_creator !== ''){
            proxied_creator = merchant.proxied_creator
          }
          const merchantData = {
            merchant: {
              id: merchant.id,
              fein: merchant.fein,
              legal_name: merchant.legal_name,
              dba: merchant.dba,
              city: merchant.city,
              state: merchant.state,
              business_phone: merchant.business_phone,
              business_startdate: merchant.business_startdate,
              industry: merchant.industry,
              proxied_creator: proxied_creator,
              category: merchant.category,
              note: merchant.note,
              submission_source: merchant.submission_source,
              broker_name: merchant.broker_name
            }
          }
          onMerchantSave(merchantData)
        
      }
    }
  };

  const handleFEINLookup = (event) => {
    event.preventDefault();
    let url = `/search/merchant_check_exists?search=${event.target.value.replace(/-|\s/g,"")}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        setFeinIsValid(false)
        setLocalDialogId(response.data.data.resource_id)
        onOpenMerchantExistsDialog(response.data.data.resource_id)
      } else {
        setFeinIsValid(true)
      }
    })
    .catch(error => {
      console.log(error.response)
    })
  }

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving && !props.loading){
    spinner = null
  }

  // let merchantForm = <Redirect to={`/merchants`} />
  // if ( !props.id ) {
  const  merchantForm = (
      <div className={classes.root}>
        <div className={classes.content}>
          <MerchantForm 
            merchant={merchant}
            onSubmitClick={handleSubmitClick}
            onFEINBlur={handleFEINLookup}
            accounts={accounts}
            loading={loading_accounts}
            is_dm_admin={is_dm_admin}
            merchant_add={true}
            plan_level={plan_level}
          />
          {spinner}
          <MerchantExistsDialog id={id} />
        </div>
      </div>
    );
  // }
  
  return merchantForm;
};

const mapStateToProps = state => {
  return {
    saving: state.merchant.saving,
    is_dm_admin: state.auth.user.is_dm_admin,
    loading: state.merchant.loading,
    loading_accounts: state.account.loading,
    id: state.merchant.id,
    dialog_id: state.merchant.dialog_id,
    accounts: state.account.accounts,
    token: state.auth.token,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onMerchantSave: (merchantData) => dispatch(actions.addMerchant(merchantData)),
    onOpenMerchantExistsDialog: (dialog_id) => dispatch(actions.openMerchantExistsDialog(dialog_id)),
    onFetchMembers: (token) => dispatch(actions.fetchDatamerchMembers(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantAdd, axios))