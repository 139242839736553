import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  Divider,
  Button,
  CardHeader,
  Typography,
  CardContent
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import MembershipPlans from '../MembershipPlans'


const useStyles = makeStyles((theme) => ({
  root: {},
  trial_button: {
    backgroundColor: theme.palette.orange,
    color: theme.palette.white,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.orange,
      color: theme.palette.white
    }
  },
  activate_button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.green,
      color: theme.palette.white
    }
  },
  noUnderline: {
    textDecoration: 'none'
  },
  section_title: {
    paddingBottom: '10px'
  }
}));

const Membership = props => {
  const { className, onCancelMembershipClick, onStartTrialClick, onCancelTrialClick, onUpgradeMembershipClick, onDowngradeMembershipClick, account, current_plan, account_view, ...rest } = props;
  const classes = useStyles();

  let content = null

  if (account_view){
    if (account && account.membership_status && account.membership_status === 'trialing'){
      content = 
        <React.Fragment>
          <CardContent>
            <Typography variant="h6">
              You are currently using a trial membership.
            </Typography>
          </CardContent>
          <Divider />
        </React.Fragment>
    } else {
      content = 
        <React.Fragment>
          <CardContent>
            <Typography variant="h3" className={classes.section_title}>
              Manage Subscription Plan
            </Typography>
            <MembershipPlans 
              current_plan={account.plan_level}
              onUpgradeMembershipClick={onUpgradeMembershipClick}
              onDowngradeMembershipClick={onDowngradeMembershipClick}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h3" color="secondary" className={classes.section_title}>
              Danger Zone
            </Typography>
            <Typography variant="h6">
              I understand that cancelling my membership will have the following results:
            </Typography>
            <Typography variant="subtitle2">
              Members of my account will no longer be able to search, add, or update merchants.
            </Typography>
          </CardContent>
          <Divider />
        </React.Fragment>
    }
  } else {
    if (account && account.membership_status && account.membership_status === 'trialing'){
      content = 
        <React.Fragment>
          <CardContent>
            <Typography variant="h6">
              Funder is currently using a trial membership
            </Typography>
          </CardContent>
          <Divider />
        </React.Fragment>
    } else {
      if (account && account.membership_status && account.membership_status !== 'trialing'){
        content = 
        <React.Fragment>
          <CardContent>
            <Typography variant="h3" className={classes.section_title}>
              Manage Subscription Plan
            </Typography>
            <MembershipPlans 
              current_plan={account.plan_level}
              onUpgradeMembershipClick={onUpgradeMembershipClick}
              onDowngradeMembershipClick={onDowngradeMembershipClick}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h3" color="secondary" className={classes.section_title}>
              Danger Zone
            </Typography>
            <Typography variant="h6">
              I understand that cancelling this funder's membership will have the following results:
            </Typography>
            <Typography variant="subtitle2">
              Members of this account will no longer be able to search, add, or update merchants.
            </Typography>
          </CardContent>
          <Divider />
        </React.Fragment>
      } else {
        content = 
        <React.Fragment>
          <CardContent>
            <Typography variant="h6">
              Funder does not have an active membership and is not on a trial.
            </Typography>
          </CardContent>
          <Divider />
        </React.Fragment>
      }
    }
  }

  let membership_form = null

  if (account_view){
    if (account && account.membership_status && account.membership_status === 'trialing'){
      membership_form = 
        <form
          autoComplete="off"
        >
          <CardActions>
            <RouterLink to="/membership" className={classes.noUnderline}>
              <Button
                variant="contained"
                className={classes.activate_button}
              >
                Activate Membership
              </Button>
            </RouterLink>
          </CardActions>
        </form>
    } else {
      membership_form = 
        <form
          autoComplete="off"
        >
          <CardActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={onCancelMembershipClick}
            >
              Cancel Membership
            </Button>
          </CardActions>
        </form>
    }
  } else {
    if (account && account.membership_status && account.membership_status === 'trialing'){
      membership_form = 
        <form
          autoComplete="off"
        >
          <CardActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={onCancelTrialClick}
            >
              Cancel Trial
            </Button>
            </CardActions>
        </form>
    } else {
      if (account && account.membership_status && account.membership_status !== 'trialing'){
        membership_form = 
          <form
            autoComplete="off"
          >
            <CardActions>
              <Button
                color="secondary"
                variant="contained"
                onClick={onCancelMembershipClick}
              >
                Cancel Membership
              </Button>
            </CardActions>
          </form>
      } else {
        membership_form = 
          <form
            autoComplete="off"
          >
            <CardActions>
              <Button
                className={classes.trial_button}
                variant="contained"
                onClick={onStartTrialClick}
              >
                Start Trial
              </Button>
              </CardActions>
          </form>
      }
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Membership"
      />
      <Divider />
      {content}
      {membership_form}
    </Card>
  );
};


export default withErrorHandler(Membership, axios)