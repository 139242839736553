import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {clearNotes} from './note'
import { history } from '../../Routes'
import {addToast} from './toasts'
import { ContactSupportOutlined } from '@material-ui/icons'

export const clearMerchantSubscriptions = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_SUCCESS,
    merchant_subscriptions: []
  }
}

export const clearMerchantSubscription = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_SUCCESS,
    merchant_subscription: {
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      state: '',
      business_phone: '',
      business_startdate: '',
      industry: '',
      category: '',
      note: ''
    }
  }
}

export const fetchMerchantSubscriptionsSuccess = (merchant_subscriptions, pagination) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_SUCCESS,
    merchant_subscriptions: merchant_subscriptions,
    pagination: pagination
  }
}

export const fetchMerchantSubscriptionsFail = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_FAIL,
    error: error
  }
}

export const fetchMerchantSubscriptionsStart = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_START
  }
}

export const fetchMerchantSubscriptions = (account_id, page, per_page, search) => { 
  return dispatch => {
    dispatch(fetchMerchantSubscriptionsStart())
    let url = `accounts/${account_id}/merchant_subscriptions?page=${page + 1}&per_page=${per_page}&search=${search}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchantSubscriptions = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedMerchantSubscriptions.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(clearMerchantSubscription())
        dispatch(fetchMerchantSubscriptionsSuccess(fetchedMerchantSubscriptions, pagination))
      }
      else {
        dispatch(fetchMerchantSubscriptionsFail('Unable to load merchant subscriptions.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchant subscriptions.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchMerchantSubscriptionsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchMerchantSubscriptionSuccess = (merchant_subscription) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_SUCCESS,
    merchant_subscription: merchant_subscription
  }
}

export const fetchMerchantSubscriptionFail = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_FAIL,
    error: error
  }
}

export const fetchMerchantSubscriptionStart = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUBSCRIPTION_START
  }
}

export const fetchMerchantSubscription = (token, id) => {
  return dispatch => {
    dispatch(fetchMerchantSubscriptionStart())
    let url = `/merchant_subscriptions/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchantSubscription = {...response.data.data}
        dispatch(fetchMerchantSubscriptionSuccess(fetchedMerchantSubscription))
      } else {
        dispatch(fetchMerchantSubscriptionFail('An error occurred while attempting to fetch merchant subscription.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchant subscription.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchMerchantSubscriptionFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const addMerchantSubscriptionStart = () => {
  return {
    type: actionTypes.ADD_MERCHANT_SUBSCRIPTION_START
  }
}

export const addMerchantSubscriptionSuccess = (id, merchantSubscriptionData) => {
  return {
    type: actionTypes.ADD_MERCHANT_SUBSCRIPTION_SUCCESS,
    id: id,
    merchantSubscriptionData: merchantSubscriptionData
  }
}

export const addMerchantSubscriptionFail = (error) => {
  return {
    type: actionTypes.ADD_MERCHANT_SUBSCRIPTION_FAIL,
    error: error
  }
}

export const addMerchantSubscription = (fein, account_id, page, per_page, search) => {
  const modifiedMerchantSubscriptionData = {
    merchant_subscription: {
      fein: fein
    }
  }

  return dispatch => {
    dispatch(addMerchantSubscriptionStart())
    let url = `/accounts/${account_id}/merchant_subscriptions`
    let config = authHeaders()
    axios.post(url, modifiedMerchantSubscriptionData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(addMerchantSubscriptionSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Merchant subscription has been added to DataMerch.',
          severity: 'success'
        }))
        dispatch(fetchMerchantSubscriptions(account_id, page, per_page, search))
      } else {
        dispatch(addMerchantSubscriptionFail('An error occurred while attempting to create merchant subscription.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to create merchant subscription.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(addMerchantSubscriptionFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const updateMerchantSubscriptionStart = () => {
  return {
    type: actionTypes.UPDATE_MERCHANT_SUBSCRIPTION_START
  }
}

export const updateMerchantSubscriptionSuccess = (id, merchantSubscriptionData) => {
  return {
    type: actionTypes.UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS,
    id: id,
    merchantSubscriptionData: merchantSubscriptionData
  }
}

export const updateMerchantSubscriptionFail = (error) => {
  return {
    type: actionTypes.UPDATE_MERCHANT_SUBSCRIPTION_FAIL,
    error: error
  }
}

export const updateMerchantSubscription = (merchantSubscriptionData, id, token) => {
  const modifiedMerchantSubscriptionData = {
    merchant_subscription: {
      fein: merchantSubscriptionData.merchant.fein,
      legal_name: merchantSubscriptionData.merchant.legal_name,
      dba: merchantSubscriptionData.merchant.dba,
      city: merchantSubscriptionData.merchant.city,
      state: merchantSubscriptionData.merchant.state,
      business_phone: merchantSubscriptionData.merchant.business_phone,
      business_startdate: merchantSubscriptionData.merchant.business_startdate,
      industry: merchantSubscriptionData.merchant.industry
    }
  }
  
  return dispatch => {
    dispatch(updateMerchantSubscriptionStart())
    let url = `/merchant_subscriptions/${id}`
    let config = authHeaders()
    axios.put(url, modifiedMerchantSubscriptionData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateMerchantSubscriptionSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Merchant subscription has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(updateMerchantSubscriptionFail('An error occurred while attempting to update merchant subscription.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update merchant subscription.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateMerchantSubscriptionFail(error.response.data.errors))
    })
  }
}

export const deleteMerchantSubscriptionsStart = () => {
  return {
    type: actionTypes.DELETE_MERCHANT_SUBSCRIPTIONS_START,
    merchant_subscription_delete_modal_open: false,
    loading: true
  }
}

export const deleteMerchantSubscriptionsSuccess = () => {
  return {
    type: actionTypes.DELETE_MERCHANT_SUBSCRIPTIONS_SUCCESS,
    merchant_subscription_delete_modal_open: false,
    loading: false
  }
}

export const deleteMerchantSubscriptionsFail = (error) => {
  return {
    type: actionTypes.DELETE_MERCHANT_SUBSCRIPTIONS_FAIL,
    error: error,
    merchant_subscription_delete_modal_open: false,
    loading: false
  }
}

export const deleteMerchantSubscriptions = (account_id, page, per_page, search, merchantSubscriptionsData) => {
  const modifiedMerchantSubscriptionsData = {
    merchant_subscription_ids: merchantSubscriptionsData,
    page: 1,
    per_page: per_page,
    search: search
  }

  return dispatch => {
    dispatch(deleteMerchantSubscriptionsStart())
    let url = `accounts/${account_id}/merchant_subscriptions/delete_selected`
    let config = authHeaders()
    axios.post(url, modifiedMerchantSubscriptionsData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        const fetchedMerchantSubscriptions = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedMerchantSubscriptions.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(clearMerchantSubscription())
        dispatch(deleteMerchantSubscriptionsSuccess())
        dispatch(fetchMerchantSubscriptionsSuccess(fetchedMerchantSubscriptions, pagination))
        dispatch(addToast({
          message: 'Merchant subscriptions have been deleted.',
          severity: 'success'
        }))
      } else {
        dispatch(deleteMerchantSubscriptionsFail('An error occurred while attempting to delete merchant subscriptions.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to delete merchant subscriptions.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(deleteMerchantSubscriptionsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const deleteAllMerchantSubscriptionsStart = () => {
  return {
    type: actionTypes.DELETE_ALL_MERCHANT_SUBSCRIPTIONS_START,
    merchant_subscription_delete_modal_open: false,
    loading: true
  }
}

export const deleteAllMerchantSubscriptionsSuccess = () => {
  return {
    type: actionTypes.DELETE_ALL_MERCHANT_SUBSCRIPTIONS_SUCCESS,
    merchant_subscription_delete_modal_open: false,
    loading: false
  }
}

export const deleteAllMerchantSubscriptionsFail = (error) => {
  return {
    type: actionTypes.DELETE_ALL_MERCHANT_SUBSCRIPTIONS_FAIL,
    error: error,
    merchant_subscription_delete_modal_open: false,
    loading: false
  }
}

export const deleteAllMerchantSubscriptions = (account_id, page, per_page, search) => {
  const modifiedMerchantSubscriptionsData = {
    page: 1,
    per_page: per_page,
    search: search
  }

  return dispatch => {
    dispatch(deleteAllMerchantSubscriptionsStart())
    let url = `accounts/${account_id}/merchant_subscriptions/delete_all`
    let config = authHeaders()
    axios.post(url, modifiedMerchantSubscriptionsData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        const fetchedMerchantSubscriptions = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedMerchantSubscriptions.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(clearMerchantSubscription())
        dispatch(deleteAllMerchantSubscriptionsSuccess())
        dispatch(fetchMerchantSubscriptionsSuccess(fetchedMerchantSubscriptions, pagination))
        dispatch(addToast({
          message: 'Merchant subscriptions have been deleted.',
          severity: 'success'
        }))
      } else {
        dispatch(deleteAllMerchantSubscriptionsFail('An error occurred while attempting to delete merchant subscriptions.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to delete merchant subscriptions.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(deleteAllMerchantSubscriptionsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const openMerchantSubscriptionDeleteDialog = (id) => {
  return {
    type: actionTypes.OPEN_MERCHANT_SUBSCRIPTION_DELETE_MODAL,
    id: id
  }
}

export const closeMerchantSubscriptionDeleteDialog = () => {
  return {
    type: actionTypes.CLOSE_MERCHANT_SUBSCRIPTION_DELETE_MODAL
  }
}

export const openMerchantSubscriptionDialog = (id) => {
  return {
    type: actionTypes.OPEN_MERCHANT_SUBSCRIPTION_DIALOG,
    id: id
  }
}

export const closeMerchantSubscriptionDialog = () => {
  return {
    type: actionTypes.CLOSE_MERCHANT_SUBSCRIPTION_DIALOG
  }
}
