import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import StripeCheckout from 'react-stripe-checkout'

import * as actions from '../../../store/actions'
import validate from 'validate.js';
import queryString from 'query-string'

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';

const schema = {
  agree: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignUp  = (props) => {
  const { 
    onSubscriptionCreate,
    error,
    id,
    account_id
  } = props

  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (token, plan) => {
    onSubscriptionCreate(token, account_id, "DataMerch.com")
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Membership" />
          <Divider />
          <CardContent>
            <Typography
             variant="h5"
            >
              DataMerch is only…
              <p>&nbsp;</p>
            </Typography>
            <Typography
              variant="h3"
            >
              $695/month
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              With a fixed, predictable price, DataMerch is one of the best investments you’ll make in your business.
            </Typography>
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              token={handleSubmit}
              name="DataMerch" // the pop-in header title
              description="Monthly Subscription" // the pop-in header subtitle
              ComponentClass="div"
              panelLabel="Pay" // prepended to the amount in the bottom pay button
              amount={69500} // cents
              currency="USD"
              allowRememberMe={false}
            >
              <Button
                className={classes.signInButton}
                color="primary"
                size="large"
                type="button"
                variant="contained"
              >
                Start Membership
              </Button>
            </StripeCheckout>
            <Typography
              variant="body2"
              gutterBottom={false}
            >
              <strong>Included with your subscription:</strong>
            </Typography>
            <Typography
              variant="body2"
              
            >
              Unlimited Merchant Searches, Additions, and Updates
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              <u>Additional Details</u>
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
            >
              Do I have to pay more when my business grows?
            </Typography>
            <Typography
              variant="body2"
            >
              Nope! We only have one price. The total price is the same no matter how many employees you have now or later. The administrator on the account can give DataMerch access to as many employees as they see fit.
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
            >
              Is there any limit to the number of merchants we can view and update?
            </Typography>
            <Typography
              variant="body2"
            >
              No. You can view, add, or update as many merchants as you’d like. There’s never an additional charge for searching or adding more merchants.
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
            >
              Do I have to install anything or talk to IT?
            </Typography>
            <Typography
              variant="body2"
            >
              Nope! You can do all your DataMerch activity through your web browser. You are also welcome to set upautomated searches and additions to our system using our API if you choose.
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
            >
              What if I change my mind?
            </Typography>
            <Typography
              variant="body2"
            >
              No problem, you can cancel anytime, all on your own, no questions asked.
            </Typography>
            <Typography
              className={classes.title}
              variant="body2"
            >
              If you have any other questions, we’d be <a href="mailto:support@datamerch.com">happy to answer them.</a>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    id: state.auth.user.resource_id,
    account_id: state.auth.user.account_resource_id
  }
}

const mapDispatchToProps = dispatch => ({
  onSubscriptionCreate: (token, id) => dispatch(actions.subscriptionCreate(token, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
