import React from 'react';
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Button
} from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  changePasswordButton: {
    margin: '10px 0'
  }
}));

const UserInfo = props => {
  const { onChange, id, firstName, lastName, email, onChangePasswordClick, profile, mode } = props;

  const classes = useStyles();
  return (
    <React.Fragment>
      <CardHeader
        title={profile ? 'Profile' : 'Member Info'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="First Name"
              margin="dense"
              name="firstName"
              onChange={(event) => onChange(event, 'firstName')}
              required
              value={firstName || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Last Name"
              margin="dense"
              name="lastName"
              onChange={(event) => onChange(event, 'lastName')}
              required
              value={lastName || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Email Address"
              margin="dense"
              name="email"
              onChange={(event) => onChange(event, 'email')}
              required
              value={email || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            {mode !== 'add' ? (
              <Button className={classes.changePasswordButton} variant="contained" color="secondary" onClick={event => onChangePasswordClick(event, IDBFactory)}>
                Change Password
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
};

export default withErrorHandler(UserInfo, axios)