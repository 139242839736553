import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  merchant_subscription_imports: [],
  loading: false,
  error: null
}

const fetchMerchantSubscriptionImportsStart = (state, action) => {
  return updateObject(state, {loading: true, merchant_subscription_imports: []})
}

const fetchMerchantSubscriptionImportsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant_subscription_imports: action.merchant_subscription_imports
  })
}

const fetchMerchantSubscriptionImportsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_START:
      return fetchMerchantSubscriptionImportsStart(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_SUCCESS:
      return fetchMerchantSubscriptionImportsSuccess(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_FAIL:
      return fetchMerchantSubscriptionImportsFail(state, action)
    default:
      return state
  }
}
    
export default reducer
