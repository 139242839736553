import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {clearNotes} from './note'
import { history } from '../../Routes'
import {addToast} from './toasts'

export const addDisputeStart = () => {
  return {
    type: actionTypes.ADD_DISPUTE_START
  }
}

export const addDisputeSuccess = (id, disputeData) => {
  return {
    type: actionTypes.ADD_DISPUTE_SUCCESS,
    id: id,
    disputeData: disputeData
  }
}

export const addDisputeFail = (error) => {
  return {
    type: actionTypes.ADD_DISPUTE_FAIL,
    error: error
  }
}

export const addDispute = (disputeData, token) => {
  const modifiedDisputeData = {
    dispute: {
      first_name: disputeData.dispute.first_name,
      last_name: disputeData.dispute.last_name,
      email: disputeData.dispute.email,
      fein: disputeData.dispute.fein,
      legal_name: disputeData.dispute.legal_name,
      dba: disputeData.dispute.dba,
      city: disputeData.dispute.city,
      state: disputeData.dispute.business_state,
      reason: disputeData.dispute.reason
    }
  }
  
  return dispatch => {
    dispatch(addDisputeStart())
    let url = '/disputes'
    let config = authHeaders()
    axios.post(url, modifiedDisputeData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(addDisputeSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Dispute has been submitted to DataMerch.',
          severity: 'success'
        }))
        dispatch(initializeAddDispute())
      } else {
        dispatch(addDisputeFail('An error occurred while attempting to create dispute.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to create dispute.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(addDisputeFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const initializeAddDispute = () => {
  return {
    type: actionTypes.INITIALIZE_ADD_DISPUTE
  }
}
