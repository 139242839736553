import React from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'
import { makeStyles } from '@material-ui/styles';

import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog_text: {
    fontSize: '16px',
    lineHeight: '30px'
  }
}));

const AccountPaymentDialog = props => {
  const { 
    openDialog,
    onCloseDialog,
    payment_method_update_url
  } = props;
  const classes = useStyles();
  
  const handleDialogClose = () => {
    onCloseDialog()
  };

  const handleChooseContinue = () => {
    window.location.replace(payment_method_update_url)
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"DataMerch Payment Method Update"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialog_text}>
          DataMerch partners with Stripe to provide secure payments.  You will be redirected to Stripe's Secure Payment Method portal to update your payment method.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleChooseContinue}
          variant="contained"
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    openDialog: state.account.payment_method_update_dialog_open,
    payment_method_update_url: state.account.payment_method_update_url
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseDialog: () => dispatch(actions.closePaymentMethodUpdateDialog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPaymentDialog)
