import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import { NoteDisputeForm } from '../../../components/Note';

const useStyles = makeStyles(() => ({
  root: {}
}));

const NoteDisputeDialog = props => {
  const { className, merchantNote, open, mode, is_dm_admin, loading, accounts, onCancelClick, onSubmitClick } = props;
  const [id, setId] = useState(merchantNote.id)
  const [merchant_id] = useState(merchantNote.merchant_id)
  const [reason, setReason] = useState('')

  const classes = useStyles();

  useEffect(() => {
    setReason(null)
    setId(merchantNote.id)
  },[merchantNote,accounts])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'reason':
        setReason(event.target.value)
        break
      default:
        return null
    }
  };

  return (
    <Dialog
      className={clsx(classes.root, className)}
      open={open}
      onClose={onCancelClick}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Note or Record Deletion Request</DialogTitle>
      <Divider />
      <DialogContent>
        <form
          autoComplete="off"
        >
          <NoteDisputeForm
            reason={reason}
            onChange={(event, field) => handleInputChange(event, field)}
            is_dm_admin={is_dm_admin}
            loading={loading}
            accounts={accounts}
          />
          <DialogActions>
            <Button
              color="secondary"
              onClick={(event) => onCancelClick(event)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(event, note) => onSubmitClick(event, {
                id: id,
                reason: reason
              })}
              type="button"
            >
              SUBMIT DELETION REQUEST
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};


export default withErrorHandler(NoteDisputeDialog, axios)