import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';


import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  list: {
    paddingLeft: theme.spacing(3)
  }
}));

const Api  = (props) => {
  const [showGetMerchant, setShowGetMerchant] = useState(false)
  const [showPostMerchant, setShowPostMerchant] = useState(false)
  const [showPostNote, setShowPostNote] = useState(false)

  const classes = useStyles();

  const onShowClick = (event, api) => {
    switch(api){
      case 'GET_MERCHANT':
        setShowGetMerchant(!showGetMerchant)
        break
      case 'POST_MERCHANT':
        setShowPostMerchant(!showPostMerchant)
        break
      case 'POST_NOTE':
        setShowPostNote(!showPostNote)
        break
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="DataMerch Developer's Guide" />
          <Divider />
          <CardContent className={classes.terms}>
            <Typography
              variant="h4"
            >
              DATAMERCH API - Version 1 (Legacy)
              <Grid container justify="flex-end">
                <RouterLink to="/developers">
                  <Button variant="contained" color="primary">Go to Current Version</Button>
                </RouterLink>
              </Grid>
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              AUTHENTICATION
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              The DataMerch API uses basic authentication. Credentials are assigned to account members individually. If you know your API credentials, you can use the Authentication token as your user name and the authentication key as your password for basic authentication for API calls. Every API call must be authenticated.
            </Typography>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
              >
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      API Credentials
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Basic Authentication
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={5}
                    xl={5}
                    xs={5}
                  >
                    <Typography
                      variant="body2"
                    >
                      Authentication Token
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      =
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      User Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={5}
                    xl={5}
                    xs={5}
                  >
                    <Typography
                      variant="body2"
                    >
                      Authentication Key
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      =
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      Password
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              className={classes.title}
              variant="h4"
            >
              HOW DO I GET MY API CREDENTIALS?
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              To make use of the DataMerch API, your account must be granted access to the API by a DataMerch administrator. To request access, please contact <a href="mailto:support@datamerch.com">support@datamerch.com</a>. If your account has been granted API access and you are an account administrator, you can log in to DataMerch and go to the "Account" page. The account page is accessed through the side menu displayed on the left when you are logged in. Once on the “Account” page, click “Account Members.”  From there, you can grant or revoke API access as well as access a member's API credentials. Only member's that have been granted access to the API will be given API credentials to use for Authentication.  Once API access is granted, click the “Show” button to display each member’s unique API credentials.
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              <i>NOTE: If you do not see "Account" as a menu option, you are not an account administrator. Please contact your account administrator to request API access.</i>
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              AVAILABLE API ENDPOINTS
            </Typography>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      VERB
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Resource
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={5}
                    xl={5}
                    xs={5}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      URL
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    sm={2}
                    xl={2}
                    xs={2}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Output/Input
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      GET
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Search for a merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={5}
                    xl={5}
                    xs={5}
                  >
                    <Typography
                      variant="body2"
                    >
                      https://api.datamerch.com/api/v1/merchants/[last 7 digits of EIN]
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    sm={2}
                    xl={2}
                    xs={2}
                  >
                    { showGetMerchant ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showGetMerchant ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `[
  {
    "merchant": {
      "fein": "01-2345678",
      "legal_name": "ACME Company",
      "dba": "ACME",
      "address": "123 First Ave. S.",
      "street1": "",
      "street2": "",
      "city": "Los Angeles",
      "state": "CA",
      "business_phone": "123-456-7890",
      "business_startdate": "1/1/1985",
      "industry": "Combustables",
      "notes": [
        {
          "note": {
            "category": "Slow Pay",
            "note": "Merchant is a slow pay and/or default account.",
            "created_at": "2015-09-01 04:21:41 UTC",
            "added_by": "XYZ Funder"
          }
        }
      ]
    }
  }
]`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add a new merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={5}
                    xl={5}
                    xs={5}
                  >
                    <Typography
                      variant="body2"
                    >
                      https://api.datamerch.com/api/v1/merchants
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    sm={2}
                    xl={2}
                    xs={2}
                  >
                    { showPostMerchant ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostMerchant ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        INPUT
                      </Typography>
                      <pre>
                        {
  `{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME" //optional,
  "street1": "123 First St." //optional,
  "street2": "Suite 100" //optional,
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890" //optional,
  "business_startdate": "1/1/1985" //optional,
  "industry": "Combustables" //optional,
  "note_category": "Slow Pay",
  "note": "Merchant is a slow pay and/or default account."
}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        className={classes.title}
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `{
    "fein": "01-2345678",
    "legal_name": "ACME Company",
    "dba": "ACME",
    "address": "123 First Ave. S.",
    "street1": "",
    "street2": "",
    "city": "Los Angeles",
    "state": "CA",
    "business_phone": "123-456-7890",
    "business_startdate": "1/1/1985",
    "industry": "Combustables",
    "notes": [
      {
        "note": {
          "category": "Slow Pay",
          "note": "Merchant is a slow pay and/or default account.",
          "created_at": "2015-09-01 04:21:41 UTC",
          "added_by": "XYZ Funder"
        }
      }
    ]
  }`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Note
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add note to existing merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={5}
                    xl={5}
                    xs={5}
                  >
                    <Typography
                      variant="body2"
                    >
                      https://api.datamerch.com/api/v1/notes
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    sm={2}
                    xl={2}
                    xs={2}
                  >
                    { showPostNote ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_NOTE')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_NOTE')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostNote ? 
                    <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Typography
                      variant="h6"
                    >
                      INPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "note_category": "Slow Pay",
  "note": "Merchant is a slow pay and/or default account."
}`
                      }
                    </pre>
                    <br />
                    <Divider />
                    <Typography
                      className={classes.title}
                      variant="h6"
                    >
                      OUTPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME",
  "address": "123 First Ave. S.",
  "street1": "",
  "street2": "",
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890",
  "business_startdate": "1/1/1985",
  "industry": "Combustables",
  "notes": [
    {
      "note": {
        "category": "Slow Pay",
        "note": "Merchant is a slow pay and/or default account.",
        "created_at": "2015-09-01 04:21:41 UTC",
        "added_by": "XYZ Funder"
      }
    }
  ]
}`
                      }
                    </pre>
                  </Grid>
                    : null
                  }
                </Grid>
              </Grid>
            </Grid>
            <Typography
              className={classes.title}
              variant="body2"
            >
              *** Available Note Categories include:
              <ul className={classes.list}>
                <li>Suspicious Activity</li>
                <li>Slow Pay</li>
                <li>Split Payer</li>
                <li>Stacking History</li>
                <li>Default Account</li>
                {/* <li>Criminal History</li> */}
                <li>Covid-19 Hardship</li>
                {/* <li>IF - Client Default</li> */}
                {/* <li>IF - Vendor Slow/No Pay</li> */}
                <li>Other</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Api;
