import { all } from 'redux-saga/effects'
import { 
  loginFlow,
  logoutFlow,
  initializeCurrentUserFlow,
  otpVerify
} from './auth'
import {
  watchSearch,
  watchSearchResults,
  watchAgreedToTerms,
  watchSubscriptionCreated,
  watchAddMerchantRedirect,
  watchAddUserRedirect,
  watchAddNoteToMerchantRedirect,
  watchSubscriptionCanceled,
  watchAddDisputeRedirect
} from './watchers'

export default function * rootSaga () {
  yield all([
    loginFlow(),
    initializeCurrentUserFlow(),
    logoutFlow(),
    watchSearch(),
    watchSearchResults(),
    watchAgreedToTerms(),
    watchSubscriptionCreated(),
    watchAddMerchantRedirect(),
    watchAddUserRedirect(),
    watchAddNoteToMerchantRedirect(),
    watchSubscriptionCanceled(),
    watchAddDisputeRedirect(),
    otpVerify()
  ])
  // code after all-effect
}
