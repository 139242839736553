import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { store, sagaMiddleware } from './Store';
import { Router } from 'react-router-dom';
import rootSaga from './sagas'

import * as serviceWorker from './serviceWorker';
import App from './App';

sagaMiddleware.run(rootSaga)

store.dispatch({ type: 'APPLICATION_LOADED' })

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
