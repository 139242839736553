import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'
import {logout, authMfaResetSuccess} from './auth'

export const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: users
  }
}

export const fetchUsersFail = (error) => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    error: error
  }
}

export const fetchUsersStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START
  }
}

export const fetchUsers = (token, accountId) => {
  return dispatch => {
    dispatch(fetchUsersStart())
    let url = `accounts/${accountId}/users`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedUsers = []
        for(let key in response.data.data){
          fetchedUsers.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchUsersSuccess(fetchedUsers))
      } else {
        dispatch(fetchUsersFail('An error occurred while attempting to fetch members.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve members.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchUsersFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchUserSuccess = (user) => {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    user: user
  }
}

export const fetchUserFail = (error) => {
  return {
    type: actionTypes.FETCH_USER_FAIL,
    error: error
  }
}

export const fetchUserStart = () => {
  return {
    type: actionTypes.FETCH_USER_START
  }
}

export const fetchUser = (token, id) => {
  return dispatch => {
    dispatch(fetchUserStart())
    let url = `users/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedUser = {...response.data.data}
        dispatch(fetchUserSuccess(fetchedUser))
      } else {
        dispatch(fetchUserFail('An error occurred while attempting to fetch member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve member.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const addUserStart = () => {
  return {
    type: actionTypes.ADD_USER_START
  }
}

export const addUserSuccess = (id, userData) => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const addUserFail = (error) => {
  return {
    type: actionTypes.ADD_USER_FAIL,
    error: error
  }
}

export const addUser = (token, accountId, userData) => {
  const modifiedUserData = {
    user: {
      first_name: userData.user.firstName,
      last_name: userData.user.lastName,
      email: userData.user.email
    }
  }
  
  return dispatch => {
    dispatch(addUserStart())
    let url = `accounts/${accountId}/users`
    let config = authHeaders()
    axios.post(url, modifiedUserData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(addUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Member has been added.',
          severity: 'success'
        }))
      } else {
        dispatch(addUserFail('An error occurred while attempting to create member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to add member.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(addUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START
  }
}

export const updateUserSuccess = (id, userData) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const updateUserFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: error
  }
}

export const updateUser = (token, userData, id) => {
  const modifiedUserData = {
    user: {
      first_name: userData.user.firstName,
      last_name: userData.user.lastName,
      email: userData.user.email
    }
  }
  
  return dispatch => {
    dispatch(updateUserStart())
    let url = `users/${id}`
    let config = authHeaders()
    axios.put(url, modifiedUserData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Member has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(updateUserFail('An error occurred while attempting to update member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update member.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const restoreUserStart = () => {
  return {
    type: actionTypes.RESTORE_USER_START
  }
}

export const restoreUserSuccess = (id, userData) => {
  return {
    type: actionTypes.RESTORE_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const restoreUserFail = (error) => {
  return {
    type: actionTypes.RESTORE_USER_FAIL,
    error: error
  }
}

export const restoreUser = (token, id) => {
  return dispatch => {
    dispatch(restoreUserStart())
    let url = `users/${id}/restore`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(restoreUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Member has been restored.',
          severity: 'success'
        }))
      } else {
        dispatch(restoreUserFail('An error occurred while attempting to restore member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to restore member.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(restoreUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const archiveUserStart = () => {
  return {
    type: actionTypes.ARCHIVE_USER_START
  }
}

export const archiveUserSuccess = (id, userData) => {
  return {
    type: actionTypes.ARCHIVE_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const archiveUserFail = (error) => {
  return {
    type: actionTypes.ARCHIVE_USER_FAIL,
    error: error
  }
}

export const archiveUser = (token, id) => {
  return dispatch => {
    dispatch(archiveUserStart())
    let url = `users/${id}/archive`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(archiveUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Member has been archived.',
          severity: 'success'
        }))
      } else {
        dispatch(archiveUserFail('An error occurred while attempting to archive member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to archive member.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(archiveUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const toggleAdminUserStart = () => {
  return {
    type: actionTypes.TOGGLE_ADMIN_USER_START
  }
}

export const toggleAdminUserSuccess = (id, userData) => {
  return {
    type: actionTypes.TOGGLE_ADMIN_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const toggleAdminUserFail = (error) => {
  return {
    type: actionTypes.TOGGLE_ADMIN_USER_FAIL,
    error: error
  }
}

export const toggleAdminUser = (token, id) => {
  return dispatch => {
    dispatch(toggleAdminUserStart())
    let url = `users/${id}/toggle_admin`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(toggleAdminUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Member\'s admin status has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(toggleAdminUserFail('An error occurred while attempting to update member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update a member\'s admin status.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(toggleAdminUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const grantApiAccessUserStart = () => {
  return {
    type: actionTypes.GRANT_API_ACCESS_USER_START
  }
}

export const grantApiAccessUserSuccess = (id, userData) => {
  return {
    type: actionTypes.GRANT_API_ACCESS_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const grantApiAccessUserFail = (error) => {
  return {
    type: actionTypes.GRANT_API_ACCESS_USER_FAIL,
    error: error
  }
}

export const grantApiAccessUser = (token, id) => {
  return dispatch => {
    dispatch(grantApiAccessUserStart())
    let url = `users/${id}/grant_api_access`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(grantApiAccessUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'API access has been granted.',
          severity: 'success'
        }))
      } else {
        dispatch(grantApiAccessUserFail('An error occurred while attempting to update member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to grant API access to member.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(grantApiAccessUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const revokeApiAccessUserStart = () => {
  return {
    type: actionTypes.REVOKE_API_ACCESS_USER_START
  }
}

export const revokeApiAccessUserSuccess = (id, userData) => {
  return {
    type: actionTypes.REVOKE_API_ACCESS_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const revokeApiAccessUserFail = (error) => {
  return {
    type: actionTypes.REVOKE_API_ACCESS_USER_FAIL,
    error: error
  }
}

export const revokeApiAccessUser = (token, id) => {
  return dispatch => {
    dispatch(revokeApiAccessUserStart())
    let url = `users/${id}/revoke_api_access`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(revokeApiAccessUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'API access has been revoked.',
          severity: 'success'
        }))
      } else {
        dispatch(revokeApiAccessUserFail('An error occurred while attempting to update member.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to revoke API access.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(revokeApiAccessUserFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const openApiCredentialModal = (user) => {
  return {
    type: actionTypes.OPEN_API_CREDENTIAL_MODAL,
    user: user
  }
}

export const closeApiCredentialModal = () => {
  return {
    type: actionTypes.CLOSE_API_CREDENTIAL_MODAL
  }
}

export const openPasswordModal = (user) => {
  return {
    type: actionTypes.OPEN_PASSWORD_MODAL
  }
}

export const closePasswordModal = () => {
  return {
    type: actionTypes.CLOSE_PASSWORD_MODAL
  }
}

export const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START
  }
}

export const changePasswordSuccess = (id) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS
  }
}

export const changePasswordFail = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAIL
  }
}

export const changePassword = (token, passwordData) => {
  let url = `users/${passwordData.user_id}/change_password`
  if (passwordData.old_password !== ''){
    url += '?admin'
  }

  return dispatch => {
    dispatch(changePasswordStart())
    let config = authHeaders()
    axios.put(url, passwordData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(changePasswordSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Password has been updated.',
          severity: 'success'
        }))
      } else {
        const message = 'An error occurred while attempting to update password. Make sure you are not attempting to update the password to a previously used password.'
        dispatch(changePasswordFail(message))
        dispatch(addToast({
          message: message,
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(changePasswordFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const resetMfaStart = () => {
  return {
    type: actionTypes.RESET_MFA_START
  }
}

export const resetMfaSuccess = (id) => {
  return {
    type: actionTypes.RESET_MFA_SUCCESS
  }
}

export const resetMfaFail = (error) => {
  return {
    type: actionTypes.RESET_MFA_FAIL
  }
}

export const resetMfa = (token, id) => {
  return dispatch => {
    dispatch(resetMfaStart())
    let url = `users/${id}/reset_mfa`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(resetMfaSuccess(data.resource_id, data))
        const user = data.user || {
          account_resource_id: null,
          first_name: null,
          last_name: null,
          account_name: null,
          is_admin: false,
          is_dm_admin: false
        }
        dispatch(authMfaResetSuccess(user))
        dispatch(addToast({
          message: 'MFA has been reset.',
          severity: 'success'
        }))
        dispatch(logout())
      } else {
        dispatch(resetMfaFail('An error occurred while attempting to reset MFA.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to reset MFA.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(resetMfaFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const forgetDeviceStart = () => {
  return {
    type: actionTypes.FORGET_DEVICE_START
  }
}

export const forgetDeviceSuccess = (id, data) => {
  return {
    type: actionTypes.FORGET_DEVICE_SUCCESS
  }
}

export const forgetDeviceFail = (error) => {
  return {
    type: actionTypes.FORGET_DEVICE_FAIL
  }
}

export const forgetDevice = (token, id) => {
  return dispatch => {
    dispatch(forgetDeviceStart())
    let url = `users/${id}/forget_device`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(forgetDeviceSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Device has been forgotten.',
          severity: 'success'
        }))
      } else {
        dispatch(forgetDeviceFail('An error occurred while attempting to forget device.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to forget device.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(forgetDeviceFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}


export const mfaRequiredToggleUserStart = () => {
  return {
    type: actionTypes.MFA_REQUIRED_TOGGLE_USER_START
  }
}

export const mfaRequiredToggleUserSuccess = (id, userData) => {
  return {
    type: actionTypes.MFA_REQUIRED_TOGGLE_USER_SUCCESS,
    id: id,
    userData: userData
  }
}

export const mfaRequiredToggleUserFail = (error) => {
  return {
    type: actionTypes.MFA_REQUIRED_TOGGLE_USER_FAIL,
    error: error
  }
}

export const mfaRequiredToggleUser = (id) => {
  return dispatch => {
    dispatch(mfaRequiredToggleUserStart())
    let url = `users/${id}/mfa_required_toggle`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(mfaRequiredToggleUserSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'MFA Required has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(mfaRequiredToggleUserFail('An error occurred while attempting to update mfa required.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update mfa required.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(mfaRequiredToggleUserFail(error.response.data.errors))
    })
  }
}
