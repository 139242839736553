import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const clearDeletedNotes = () => {
  return {
    type: actionTypes.FETCH_DELETED_NOTES_SUCCESS,
    notes: []
  }
}

export const fetchDeletedNotesSuccess = (notes) => {
  return {
    type: actionTypes.FETCH_DELETED_NOTES_SUCCESS,
    notes: notes
  }
}

export const fetchDeletedNotesFail = (error) => {
  return {
    type: actionTypes.FETCH_DELETED_NOTES_FAIL,
    error: error
  }
}

export const fetchDeletedNotesStart = () => {
  return {
    type: actionTypes.FETCH_DELETED_NOTES_START
  }
}

export const fetchDeletedNotes = (token, merchantId) => {
  return dispatch => {
    dispatch(fetchDeletedNotesStart())
    let url = `deleted_merchants/${merchantId}/deleted_notes`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedNotes = []
        for(let key in response.data.data){
          fetchedNotes.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchDeletedNotesSuccess(fetchedNotes))
      } else {
        dispatch(fetchDeletedNotesFail('An error occurred while attempting to fetch notes.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve notes.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchDeletedNotesFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchDeletedNoteSuccess = (note) => {
  return {
    type: actionTypes.FETCH_DELETED_NOTE_SUCCESS,
    note: note
  }
}

export const fetchDeletedNoteFail = (error) => {
  return {
    type: actionTypes.FETCH_DELETED_NOTE_FAIL,
    error: error
  }
}

export const fetchDeletedNoteStart = () => {
  return {
    type: actionTypes.FETCH_DELETED_NOTE_START
  }
}

export const fetchDeletedNote = (token, id) => {
  return dispatch => {
    dispatch(fetchDeletedNoteStart())
    let url = `deleted_notes/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedNote = {...response.data.data}
        dispatch(fetchDeletedNoteSuccess(fetchedNote))
      } else {
        dispatch(fetchDeletedNoteFail('An error occurred while attempting to fetch note.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve note.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchDeletedNoteFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}