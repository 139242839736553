import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  merchant_subscriptions: [],
  merchant_subscription: {
    fein: '',
    legal_name: '',
    dba: '',
    city: '',
    state: '',
    business_phone: '',
    business_startdate: '',
    industry: '',
    category: '',
    note: ''
  },
  loading: false,
  id: null,
  dialog_id: null,
  saving: false,
  error: null,
  flash: null,
  pagination: {
    current_page: 0,
    per_page: 25,
    total_pages: 1,
    total_count: 0
  },
  searched: false,
  merchant_subscription_dialog_open: false,
  merchant_subscription_delete_modal_open: false,
  reason: ''
}

const fetchMerchantSubscriptionsStart = (state, action) => {
  return updateObject(state, {loading: true, merchant_subscriptions: [], merchant_subscription: null})
}

const fetchMerchantSubscriptionsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant_subscriptions: action.merchant_subscriptions,
    pagination: action.pagination
  })
}

const fetchMerchantSubscriptionsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchMerchantSubscriptionStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchMerchantSubscriptionSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant_subscription: action.merchant_subscription
  })
}

const fetchMerchantSubscriptionFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const addMerchantSubscriptionStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const addMerchantSubscriptionSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id,
      merchant_subscription_dialog_open: false
  } );
};

const addMerchantSubscriptionFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const updateMerchantSubscriptionStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateMerchantSubscriptionSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const updateMerchantSubscriptionFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const deleteMerchantSubscriptionsStart = (state, action) => {
  return updateObject(state, {loading: true, merchant_subscription_delete_modal_open: false, saving: true})
}

const deleteMerchantSubscriptionsSuccess = ( state, action ) => {
  const updatedMerchantSubscriptions = state.merchant_subscriptions.filter(merchant_subscription => merchant_subscription.resource_id !== action.id)
  
  return updateObject( state, {
      loading: false,
      saving: false,
      merchant_subscriptions: updatedMerchantSubscriptions,
      merchant_subscription_delete_modal_open: false
  } );
};

const deleteMerchantSubscriptionsFail = (state, action) => {
  return updateObject(state, {loading: false, saving: false})
}

const deleteAllMerchantSubscriptionsStart = (state, action) => {
  return updateObject(state, {loading: true, merchant_subscription_delete_modal_open: false, saving: true})
}

const deleteAllMerchantSubscriptionsSuccess = ( state, action ) => {
  const updatedMerchantSubscriptions = state.merchant_subscriptions.filter(merchant_subscription => merchant_subscription.resource_id !== action.id)
  
  return updateObject( state, {
      loading: false,
      saving: false,
      merchant_subscriptions: updatedMerchantSubscriptions,
      merchant_subscription_delete_modal_open: false
  } );
};

const deleteAllMerchantSubscriptionsFail = (state, action) => {
  return updateObject(state, {loading: false, saving: false})
}

const searchMerchantSubscriptionsStart = (state, action) => {
  return updateObject(state, {loading: true, merchant_subscriptions: [], merchant_subscription: null})
}

const searchMerchantSubscriptionsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant_subscriptions: action.merchant_subscriptions,
    searched: true
  })
}

const searchMerchantSubscriptionsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    merchant_subscriptions: [],
    searched: true
  })
}

const searchMerchantSubscriptionsReset = (state, action) => {
  return updateObject(state, {
    searched: false
  })
}

const openMerchantSubscriptionDialog = (state, action) => {
  return updateObject(state, {
    merchant_subscription_dialog_open: true
  })
}

const closeMerchantSubscriptionDialog = (state, action) => {
  return updateObject(state, {
    merchant_subscription_dialog_open: false
  })
}

const openMerchantSubscriptionDeleteDialog = (state, action) => {
  return updateObject(state, {
    merchant_subscription_delete_modal_open: true,
    dialog_id: action.id,
    reason: action.reason
  })
}

const closeMerchantSubscriptionDeleteDialog = (state, action) => {
  return updateObject(state, {
    merchant_subscription_delete_modal_open: false
  })
}

const initializeAddMerchantSubscription = (state, action) => {
  return updateObject(state, {
    merchant_subscription_dialog_open: false,
    merchant_subscription_delete_modal_open: false,
    merchant_subscription: {
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      state: '',
      business_phone: '',
      business_startdate: '',
      industry: '',
      category: '',
      note: ''
    },
    merchant_subscriptions: [],
    id: null,
    dialog_id: null,
    loading: false,
    saving: false
  })
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_START:
      return fetchMerchantSubscriptionsStart(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_SUCCESS:
      return fetchMerchantSubscriptionsSuccess(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTIONS_FAIL:
      return fetchMerchantSubscriptionsFail(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_START:
      return fetchMerchantSubscriptionStart(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_SUCCESS:
      return fetchMerchantSubscriptionSuccess(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_FAIL:
      return fetchMerchantSubscriptionFail(state, action)
    case actionTypes.UPDATE_MERCHANT_SUBSCRIPTION_START:
      return updateMerchantSubscriptionStart(state, action)
    case actionTypes.UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS:
      return updateMerchantSubscriptionSuccess(state, action)
    case actionTypes.UPDATE_MERCHANT_SUBSCRIPTION_FAIL:
      return updateMerchantSubscriptionFail(state, action)
    case actionTypes.ADD_MERCHANT_SUBSCRIPTION_START:
      return addMerchantSubscriptionStart(state, action)
    case actionTypes.ADD_MERCHANT_SUBSCRIPTION_SUCCESS:
      return addMerchantSubscriptionSuccess(state, action)
    case actionTypes.ADD_MERCHANT_SUBSCRIPTION_FAIL:
      return addMerchantSubscriptionFail(state, action)
    case actionTypes.DELETE_MERCHANT_SUBSCRIPTIONS_START:
      return deleteMerchantSubscriptionsStart(state, action)
    case actionTypes.DELETE_MERCHANT_SUBSCRIPTIONS_SUCCESS:
      return deleteMerchantSubscriptionsSuccess(state, action)
    case actionTypes.DELETE_MERCHANT_SUBSCRIPTIONS_FAIL:
      return deleteMerchantSubscriptionsFail(state, action)
    case actionTypes.DELETE_ALL_MERCHANT_SUBSCRIPTIONS_START:
      return deleteAllMerchantSubscriptionsStart(state, action)
    case actionTypes.DELETE_ALL_MERCHANT_SUBSCRIPTIONS_SUCCESS:
      return deleteAllMerchantSubscriptionsSuccess(state, action)
    case actionTypes.DELETE_ALL_MERCHANT_SUBSCRIPTIONS_FAIL:
      return deleteAllMerchantSubscriptionsFail(state, action)
    case actionTypes.SEARCH_MERCHANT_SUBSCRIPTIONS_START:
      return searchMerchantSubscriptionsStart(state, action)
    case actionTypes.SEARCH_MERCHANT_SUBSCRIPTIONS_SUCCESS:
      return searchMerchantSubscriptionsSuccess(state, action)
    case actionTypes.SEARCH_MERCHANT_SUBSCRIPTIONS_FAIL:
      return searchMerchantSubscriptionsFail(state, action)
    case actionTypes.SEARCH_MERCHANT_SUBSCRIPTIONS_RESET:
      return searchMerchantSubscriptionsReset(state, action)
    case actionTypes.OPEN_MERCHANT_SUBSCRIPTION_DIALOG:
      return openMerchantSubscriptionDialog(state, action)
    case actionTypes.CLOSE_MERCHANT_SUBSCRIPTION_DIALOG:
      return closeMerchantSubscriptionDialog(state, action)
    case actionTypes.INITIALIZE_ADD_MERCHANT_SUBSCRIPTION:
      return initializeAddMerchantSubscription(state, action)
    case actionTypes.OPEN_MERCHANT_SUBSCRIPTION_DELETE_MODAL:
      return openMerchantSubscriptionDeleteDialog(state, action)
    case actionTypes.CLOSE_MERCHANT_SUBSCRIPTION_DELETE_MODAL:
      return closeMerchantSubscriptionDeleteDialog(state, action)
    default:
      return state
  }
}
    
export default reducer
