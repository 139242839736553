import React, {useState} from 'react'
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Redirect } from 'react-router-dom'
import {addToast} from '../../../store/actions/toasts' 
import { store } from '../../../Store'
import { history } from '../../../Routes'

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { UserForm } from '../../../components/User';
import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const UserAdd = props => {
  const { onUserSave, onSaveError, account_resource_id, token, is_dm_admin, user_account_primary_email } = props;
  let { funder_id } = props.match.params
  let account_view = false
  if (!funder_id) {
    funder_id = account_resource_id
    account_view = true
  }
  const [user] = useState({
    firstName: '',
    lastName: '',
    email: ''
  })
  const classes = useStyles();

  const validateEmail = (email, user_account_primary_email_split) => {
    if (is_dm_admin){
      return true;
    }

    return email.includes(user_account_primary_email_split[1])
  }

  const handleSubmitClick = (event, user) => {
    event.preventDefault();
    let user_account_primary_email_split = user_account_primary_email.split('@')
    if (user_account_primary_email_split.length < 2){
      history.push(`/logout`)
    } else {

      if (validateEmail(user.email, user_account_primary_email_split)) {
        const userData = {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }
        onUserSave(token, funder_id, userData)
      } else {
        onSaveError({
          message: `Only email addresses ending in @${user_account_primary_email_split[1]} are valid.`,
          severity: 'error',
          duration: null
        })
        return false;
      }

      // console.log(user_account_primary_email)
      // const userData = {
      //   user: {
      //     firstName: user.firstName,
      //     lastName: user.lastName,
      //     email: user.email
      //   }
      // }
      // onUserSave(token, funder_id, userData)
    }
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving && !props.loading){
    spinner = null
  }

  // let redirect_url = `/funders/${funder_id}`
  // if (account_view) {
  //   redirect_url = `/account`
  // }
  // let userForm = <Redirect to={redirect_url} />
  
  // if ( !props.id ) {
  //   userForm = (
  //       <div className={classes.root}>
  //         <div className={classes.content}>
  //           <UserForm user={user} onSubmitClick={handleSubmitClick} mode='add' />
  //           {spinner}
  //         </div>
  //       </div>
  //     );
  // }

  const userForm = (
          <div className={classes.root}>
            <div className={classes.content}>
              <UserForm user={user} onSubmitClick={handleSubmitClick} mode='add' />
              {spinner}
            </div>
          </div>
        );
  
  return userForm;
};

const mapStateToProps = state => {
  return {
    saving: state.user.saving,
    loading: state.user.loading,
    // id: state.user.id,
    token: state.auth.token,
    account_resource_id: state.auth.user.account_resource_id,
    user_account_primary_email: state.auth.user.account_primary_email,
    is_dm_admin: state.auth.user.is_dm_admin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUserSave: (token, accountId, userData) => dispatch(actions.addUser(token, accountId, userData)),
    onSaveError: (options) => dispatch(actions.addToast(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(UserAdd, axios))