import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  selectContainer2: {
    padding: '0px 8px 4px 8px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '5px'
  },
  input: {
    background: theme.palette.light_blue
  }
}));

const MembersSelect = props => {
  const { 
    accounts,
    proxied_creator,
    onChange,
    label
  } = props;

  const classes = useStyles();

  // useEffect(() => {
  //   console.log('select')
  //   console.log(accounts)
  // },[accounts])

  
  
  return (
    <React.Fragment>
      <div className={classes.selectContainer}>
        <TextField
          fullWidth
          label={label}
          margin="dense"
          name="proxied_creator"
          onChange={(event) => onChange(event, 'proxied_creator')}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={proxied_creator}
          variant="outlined"
          InputProps={{ className: classes.input }}
        >
          <option value=""></option>
          {accounts.map(option => (
            <option
              key={option.id}
              value={option.member_id}
            >
              {option.name}
            </option>
          ))}
        </TextField>
      </div>
    </React.Fragment>
  );
};

export default MembersSelect;
