import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import MerchantDeleteInfo from '../MerchantDeleteInfo'

const useStyles = makeStyles(() => ({
  root: {}
}));

const MerchantDeleteDialog = React.memo(props => {
  const { className, merchantId, open, onCloseClick, onSubmitClick, merchant } = props;

  let schema = {};
  
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: false,
      errors: {},
      values: {
        reason: ''
      }
    }));
  }, [open])

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let reason = event.target.reason.value;
    
    const data = {
      merchant_id: merchantId,
      reason: reason
    };

    if(formState.isValid)
      onSubmitClick(event, data);
  }

  return (
    <Dialog
      className={clsx(classes.root, className)}
      open={open}
      onClose={onCloseClick}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Delete Merchant</DialogTitle>
      <Divider />
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <DialogContent>
          <MerchantDeleteInfo
            merchant_id={merchantId}
            onChange={(event) => handleChange(event)}
            formState={formState}
            hasError={(field) => hasError(field)}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Delete Merchant
          </Button>
          <Button
            color="secondary"
            onClick={(event) => onCloseClick(event)}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});


export default withErrorHandler(MerchantDeleteDialog, axios)