import { history } from '../Routes'
import { put, select, call } from 'redux-saga/effects'
import * as actions from '../store/actions'
import {store} from '../Store'

export function * addDisputeRedirect (payload) {
  try {
    yield history.push('/login')
  } catch (error) {
    console.log(error)
  }
}
