import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const DeletedMerchantSummary = props => {
  const { className, merchant, onSubmitClick, ...rest } = props;
  const [fein, setFein] = useState('')
  const [legal_name, setLegalName] = useState('')
  const [dba, setDba] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [business_phone, setBusinessPhone] = useState('')
  const [business_startdate, setBusinessStartDate] = useState('')
  const [industry, setIndustry] = useState('')
  const [reason, setReason] = useState('')
  
  const classes = useStyles();

  useEffect(() => {
    if(merchant && merchant.id){
      setFein(merchant.fein || '')
      setLegalName(merchant.legal_name || '')
      setDba(merchant.dba || '')
      setCity(merchant.city || '')
      setState(merchant.state || '')
      setBusinessPhone(merchant.business_phone || '')
      setBusinessStartDate(merchant.business_startdate || '')
      setIndustry(merchant.industry || '')
      setReason(merchant.reason || '')
    }
  },[merchant])

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Deleted Merchant Info"
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">EIN or BN:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{fein}</Typography>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">Legal Name:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{legal_name}</Typography>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">City, State:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{city}, {state}</Typography>
          </Grid>
          { dba !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Doing Business As:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{dba}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          { business_phone !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Business Phone:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{business_phone}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          { business_startdate !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Business Start Date:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{business_startdate}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          { industry !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Industry:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{industry}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">Reason for Deletion:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{reason}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};


export default DeletedMerchantSummary