import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../../store/actions'
import validate from 'validate.js';
import queryString from 'query-string'

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  newPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  confirmPassword: {
    equality: "newPassword",
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    },
  }
};

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const PasswordReset  = (props) => {
  const { 
    onPasswordResetSubmit,
    error
  } = props

  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [resetToken, setResetToken] = useState('')

  useEffect(() => {
    const values = queryString.parse(props.location.search)
    setResetToken(values.reset_token)
  },[])

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let email = event.target.email.value;
    let newPassword = event.target.newPassword.value;
    let confirmPassword = event.target.confirmPassword.value;
    
    const data = {
      email: email,
      reset_token: resetToken,
      new_password: newPassword,
      confirm_password: confirmPassword
    };

    if(formState.isValid)
    onPasswordResetSubmit(data);
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Set Your Password
      </Typography>
        <Grid
          container
        >
          <Grid item>
            <Typography variant="h5" color="secondary">{message}</Typography>
          </Grid>
        </Grid>
      <TextField
        className={classes.textField}
        error={hasError('email')}
        fullWidth
        helperText={
          hasError('email') ? formState.errors.email[0] : null
        }
        label="Email address"
        name="email"
        onChange={handleChange}
        required
        type="text"
        value={formState.values.email || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('newPassword')}
        fullWidth
        helperText={
          hasError('newPassword') ? formState.errors.newPassword[0] : null
        }
        label="New Password"
        name="newPassword"
        onChange={handleChange}
        required
        type="password"
        value={formState.values.newPassword || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        fullWidth
        label="Confirm Password"
        error={hasError('confirmPassword')}
        name="confirmPassword"
        onChange={handleChange}
        required
        value={formState.values.confirmPassword || ''}
        variant="outlined"
        type="password"
        helperText={
          hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
        }
      />
      <Button
        className={classes.signInButton}
        color="primary"
        disabled={!formState.isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Set Password
      </Button>
      <Typography
        color="textSecondary"
        variant="body1"
      >
        <Link
          component={RouterLink}
          to="/login"
          variant="h6"
        >
          Do you want to log in instead?
        </Link>
      </Typography>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error
  }
}

const mapDispatchToProps = dispatch => ({
  onPasswordResetSubmit: (data) => dispatch(actions.passwordReset(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
