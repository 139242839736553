import { history } from '../Routes'
import { take, call, put, fork, race, select } from 'redux-saga/effects'
import { Switch, Redirect, useHistory } from 'react-router-dom';
import { fetch, getDeviceInfo } from '../helpers'
import jwtDecode from 'jwt-decode'
import humps from 'humps'
import axios from '../httpClients/axios-datamerch'
import * as actions from '../store/actions'

import { setToken } from './helpers'
import { fetchAppSettings } from 'store/actions';

const { API_HOST } = process.env
// Actions

const getToken = (email, password, ip, ua) => {
  let url = 'auth/login'
  const authData = {auth: {
    email,
    password,
    ip,
    ua
  }}
  return axios.post(url, authData)
}



const getLoggedInUser = () => {
  const token = window.localStorage.getItem('token')
  if (token) {
    var decoded = jwtDecode(token)
    return decoded
  }
  return null
}

// Current User effect
export function * currentUser () {
  const user = yield call(getLoggedInUser)
  if (user) {
    yield put({ type: 'LOGIN_USER', user: user })
    yield put(actions.fetchAppSettings())
  }
}

// Attempted route effect
export function * recordAttemptedRoute () {
  const { from } = yield take('UNAUTHORIZED')
  yield put({ type: 'ATTEMPTED_ROUTE', from })
  window.scrollTo(0, 0)
  history.push(`/${window.location.pathname.split('/')[1]}/login`)
  yield put({ type: 'LOGOUT_USER' })
}

// direct logged in user effect
export function * agreedToTermsRedirect () {
  const auth = yield select((state) => state.auth)
  if (auth.attemptedRoute) {
    window.scrollTo(0, 0)
    history.push(auth.attemptedRoute)
    yield put({ type: 'ATTEMPTED_ROUTE', from: null })
  } else {
    window.scrollTo(0, 0)
    history.push(`/dashboard`)
  }
}

// direct logged in user effect
export function * subscriptionCreatedRedirect () {
  const auth = yield select((state) => state.auth)
  if (auth.attemptedRoute) {
    window.scrollTo(0, 0)
    history.push(auth.attemptedRoute)
    yield put({ type: 'ATTEMPTED_ROUTE', from: null })
  } else {
    window.scrollTo(0, 0)
    history.push(`/dashboard`)
  }
}

// direct logged in user effect
export function * loginRedirect () {
  yield take('LOGIN_USER')
  
  const user = yield select((state) => state.auth)
  if (user.attemptedRoute) {
    window.scrollTo(0, 0)
    history.push(user.attemptedRoute)
    yield put({ type: 'ATTEMPTED_ROUTE', from: null })
  }
}

// Login Effect
export function * authorize ({ email, password, ip, uag }) {
  yield put({ type: 'LOGIN_REQUEST', sending: true })
  try {
    const response = yield call(getToken, email, password, ip, uag)

    yield call(setToken, response)
    return response
  } catch (err) {
    yield put({ type: 'LOGIN_ERROR', errors: err.response.data.errors })
  } finally {
    yield put({ type: 'LOGIN_REQUEST', sending: false })
  }
}

// Log in saga
export function * loginFlow () {
  while (true) {
    const user = yield take('LOGIN_ATTEMPT')
    const { email, password } = user.credentials
    // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
    // lead to a race condition. This is unlikely, but just in case, we call `race` which
    // returns the "winner", i.e. the one that finished first
    const res = yield call(getDeviceInfo)
    const ip = res.ip
    const uag = res.uag

    const winner = yield race({
      auth: call(authorize, { email, password, ip, uag }),
      logout: take('LOGOUT')
    })
    
    if (winner.auth) {
      yield put({ type: 'LOGIN_USER', user: winner.auth.data.data.user })
      const auth = yield select((state) => state.auth)
      if (auth.isLoggedIn){
        // console.log(auth)
        if (auth.user.mfa_required && !auth.user.mfa_enabled){
          window.scrollTo(0, 0)
          history.push(`/mfa_registration`)
        } else {
          if (auth.user.mfa_required && auth.user.mfa_enabled && !auth.user.mfa_verified && !winner.auth.data.data.bypass_mfa){
            window.scrollTo(0, 0)
            history.push(`/mfa_verification`)
          } else {  
            if (auth.user.active_membership || auth.user.is_dm_admin) {
              if (auth.user.agreed_to_terms != null) {
                if (auth.attemptedRoute) {
                  window.scrollTo(0, 0)
                  history.push(auth.attemptedRoute)
                  yield put({ type: 'ATTEMPTED_ROUTE', from: null })
                } else {
                  window.scrollTo(0, 0)
                  history.push(`/dashboard`)
                }
              } else {
                window.scrollTo(0, 0)
                history.push(`/agree_to_terms`)
              }
            } else {
              window.scrollTo(0, 0)
              history.push(`/membership`)
            }
          }
        }
      } else {
        window.scrollTo(0, 0)
        history.push(`/login`)
      }
    }
  }
}

// OTP Verification Effect
export function * otpVerify () {
  while (true) {
    yield take('VERIFY_OTP_RESULT')

    const auth = yield select((state) => state.auth)
    if (auth.isLoggedIn && auth.user && auth.user.mfa_required && auth.user.mfa_enabled && auth.user.mfa_verified){
      if (auth.user.active_membership || auth.user.is_dm_admin) {
        if (auth.user.agreed_to_terms != null) {
          if (auth.attemptedRoute) {
            window.scrollTo(0, 0)
            history.push(auth.attemptedRoute)
            yield put({ type: 'ATTEMPTED_ROUTE', from: null })
          } else {
            window.scrollTo(0, 0)
            history.push(`/dashboard`)
          }
        } else {
          window.scrollTo(0, 0)
          history.push(`/agree_to_terms`)
        }
      } else {
        window.scrollTo(0, 0)
        history.push(`/membership`)
      }
    } else {
      window.scrollTo(0, 0)
      history.push(`/login`)
    }
  }
}

// Guard Saga
export function * initializeCurrentUserFlow () {
  yield take('APPLICATION_LOADED')
  yield fork(currentUser)
  yield fork(recordAttemptedRoute)
  yield fork(loginRedirect)
}

// logout saga
export function * logoutFlow () {
  while (true) {
    yield take('LOGOUT_USER')

    window.localStorage.removeItem('token')
    window.scrollTo(0, 0)
    history.push(`/login`)
  }
}



// direct logged in user effect
export function * subscriptionCanceledRedirect () {
  window.localStorage.removeItem('token')
  window.scrollTo(0, 0)
  history.push(`/login`)
}
