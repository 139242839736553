import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { UsersTable } from '../../User';
import { CircularProgress, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = props => {
  const classes = useStyles();

  const { 
    onFetchUsers,
    onUserRestore,
    onUserArchive,
    onUserAdminToggle,
    onUserMfaRequiredToggle,
    onUserGrantApiAccess,
    onUserRevokeApiAccess,
    token,
    accountId,
    className,
    loading,
    api_access,
    account_view,
    account,
    ...rest
  } = props
  useEffect(() => {
    onFetchUsers(token, accountId);
  }, [onFetchUsers, token, accountId])

  const handleRestoreClick = (event, userId) => {
    event.preventDefault();
  
    onUserRestore(token, userId)
  }

  const handleArchiveClick = (event, userId) => {
    event.preventDefault();
  
    onUserArchive(token, userId)
  }

  const handleToggleAdminClick = (event, userId) => {
    event.preventDefault();
  
    onUserAdminToggle(token, userId)
  }

  const handleToggleMfaRequiredClick = (event, userId) => {
    event.preventDefault();
  
    onUserMfaRequiredToggle(token, userId)
  }

  const handleGrantApiAccessClick = (event, userId) => {
    event.preventDefault();
  
    onUserGrantApiAccess(token, userId)
  }

  const handleRevokeApiAccessClick = (event, userId) => {
    event.preventDefault();
  
    onUserRevokeApiAccess(token, userId)
  }

  let active_users = <CircularProgress />
  let deleted_users = <CircularProgress />
  if(!loading){
    const active = props.users.filter(user => {
      return user.deleted_at === null
    })

    const deleted = props.users.filter(user => {
      return user.deleted_at !== null
    })
    
    active_users = (
      <UsersTable
        users={active}
        onArchiveClick={(event, userId) => handleArchiveClick(event, userId)}
        onUserAdminToggle={(event, userId) => handleToggleAdminClick(event, userId)}
        onUserMfaRequiredToggle={(event, userId) => handleToggleMfaRequiredClick(event, userId)}
        onUserGrantApiAccessClick={(event, userId) => handleGrantApiAccessClick(event, userId)}
        onUserRevokeApiAccessClick={(event, userId) => handleRevokeApiAccessClick(event, userId)}
        api_access={api_access ? 1 : 0}
        account_id={accountId}
        account_view={account_view}
        account={account}
      />
    )

    deleted_users = (
      <UsersTable users={deleted} deleted={true} onRestoreClick={(event, userId) => handleRestoreClick(event, userId)} />
    )
  }
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Members"
      />
      <Divider />
      {active_users}
      <CardHeader
        title="Archived Members"
      />
      <Divider />
      {deleted_users}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.account,
    users: state.user.users,
    loading: state.user.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsers: (token, accountId) => dispatch(actions.fetchUsers(token, accountId)),
    onUserRestore: (token, userId) => dispatch(actions.restoreUser(token, userId)),
    onUserArchive: (token, userId) => dispatch(actions.archiveUser(token, userId)),
    onUserAdminToggle: (token, userId) => dispatch(actions.toggleAdminUser(token, userId)),
    onUserMfaRequiredToggle: (token, userId) => dispatch(actions.mfaRequiredToggleUser(userId)),
    onUserGrantApiAccess: (token, userId) => dispatch(actions.grantApiAccessUser(token, userId)),
    onUserRevokeApiAccess: (token, userId) => dispatch(actions.revokeApiAccessUser(token, userId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(UserList, axios))