import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const clearNotes = () => {
  return {
    type: actionTypes.FETCH_NOTES_SUCCESS,
    notes: []
  }
}

export const fetchNotesSuccess = (notes) => {
  return {
    type: actionTypes.FETCH_NOTES_SUCCESS,
    notes: notes
  }
}

export const fetchNotesFail = (error) => {
  return {
    type: actionTypes.FETCH_NOTES_FAIL,
    error: error
  }
}

export const fetchNotesStart = () => {
  return {
    type: actionTypes.FETCH_NOTES_START
  }
}

export const fetchNotes = (token, merchantId) => {
  return dispatch => {
    dispatch(fetchNotesStart())
    let url = `merchants/${merchantId}/notes`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedNotes = []
        for(let key in response.data.data){
          fetchedNotes.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchNotesSuccess(fetchedNotes))
      } else {
        dispatch(fetchNotesFail('An error occurred while attempting to fetch notes.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve notes.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchNotesFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchNoteSuccess = (note) => {
  return {
    type: actionTypes.FETCH_NOTE_SUCCESS,
    note: note
  }
}

export const fetchNoteFail = (error) => {
  return {
    type: actionTypes.FETCH_NOTE_FAIL,
    error: error
  }
}

export const fetchNoteStart = () => {
  return {
    type: actionTypes.FETCH_NOTE_START
  }
}

export const fetchNote = (token, id) => {
  return dispatch => {
    dispatch(fetchNoteStart())
    let url = `notes/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedNote = {...response.data.data}
        dispatch(fetchNoteSuccess(fetchedNote))
      } else {
        dispatch(fetchNoteFail('An error occurred while attempting to fetch note.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve note.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchNoteFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const addNoteStart = () => {
  return {
    type: actionTypes.ADD_NOTE_START
  }
}

export const addNoteSuccess = (id, noteData) => {
  return {
    type: actionTypes.ADD_NOTE_SUCCESS,
    id: id,
    noteData: noteData
  }
}

export const addNoteFail = (error) => {
  return {
    type: actionTypes.ADD_NOTE_FAIL,
    error: error
  }
}

export const addNote = (noteData, token) => {
  const modifiedNoteData = {
    note: {
      merchant_id: noteData.note.merchant_id,
      category: noteData.note.category,
      note: noteData.note.note,
      submission_source: noteData.note.submission_source,
      broker_name: noteData.note.broker_name
    }
  }

  if (noteData.note.proxied_creator != null){
    modifiedNoteData.note.proxied_creator = noteData.note.proxied_creator
  }
  console.log(modifiedNoteData)
  
  return dispatch => {
    dispatch(addNoteStart())
    let url = `merchants/${noteData.note.merchant_id}/notes`
    let config = authHeaders()
    axios.post(url, modifiedNoteData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(addNoteSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Note added.',
          severity: 'success',
          duration: null
        }))
      } else {
        dispatch(addNoteFail('An error occurred while attempting to create note.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to add note.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(addNoteFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const updateNoteStart = () => {
  return {
    type: actionTypes.UPDATE_NOTE_START
  }
}

export const updateNoteSuccess = (id, noteData) => {
  return {
    type: actionTypes.UPDATE_NOTE_SUCCESS,
    id: id,
    noteData: noteData
  }
}

export const updateNoteFail = (error) => {
  return {
    type: actionTypes.UPDATE_NOTE_FAIL,
    error: error
  }
}

export const updateNote = (noteData, id, token) => {
  const modifiedNoteData = {
    note: {
      merchant_id: noteData.note.merchant_id,
      category: noteData.note.category,
      note: noteData.note.note,
      submission_source: noteData.note.submission_source,
      broker_name: noteData.note.broker_name
    }
  }
  
  return dispatch => {
    dispatch(updateNoteStart())
    let url = `notes/${id}`
    let config = authHeaders()
    axios.put(url, modifiedNoteData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateNoteSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Note updated.',
          severity: 'success'
        }))
      } else {
        dispatch(updateNoteFail('An error occurred while attempting to update note.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update note.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateNoteFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const archiveNoteStart = () => {
  return {
    type: actionTypes.ARCHIVE_NOTE_START
  }
}

export const archiveNoteSuccess = (id, noteData) => {
  return {
    type: actionTypes.ARCHIVE_NOTE_SUCCESS,
    id: id,
    noteData: noteData
  }
}

export const archiveNoteFail = (error) => {
  return {
    type: actionTypes.ARCHIVE_NOTE_FAIL,
    error: error
  }
}

export const archiveNote = (token, id) => {
  return dispatch => {
    dispatch(archiveNoteStart())
    let url = `notes/${id}/archive`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(archiveNoteSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Note has been archived.',
          severity: 'success'
        }))
      } else {
        dispatch(archiveNoteFail('An error occurred while attempting to archive note.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to archive note.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(archiveNoteFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const disputeNoteStart = () => {
  return {
    type: actionTypes.DISPUTE_NOTE_START
  }
}

export const disputeNoteSuccess = (id, noteData) => {
  return {
    type: actionTypes.DISPUTE_NOTE_SUCCESS,
    id: id,
    noteData: noteData
  }
}

export const disputeNoteFail = (error) => {
  return {
    type: actionTypes.DISPUTE_NOTE_FAIL,
    error: error
  }
}

export const disputeNote = (noteData, id, token) => {
  const modifiedNoteData = {
    note: {
      reason: noteData.note.reason
    }
  }
  
  return dispatch => {
    dispatch(disputeNoteStart())
    let url = `notes/${id}/dispute`
    let config = authHeaders()
    axios.put(url, modifiedNoteData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(disputeNoteSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Note/Record Delete Request Submitted.',
          severity: 'success'
        }))
      } else {
        dispatch(updateNoteFail('An error occurred while attempting to send deletion request.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to send deletion request.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(disputeNoteFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const openNoteModal = () => {
  return {
    type: actionTypes.OPEN_NOTE_MODAL
  }
}

export const closeNoteModal = () => {
  return {
    type: actionTypes.CLOSE_NOTE_MODAL
  }
}

export const openNoteDisputeModal = () => {
  return {
    type: actionTypes.OPEN_NOTE_DISPUTE_MODAL
  }
}

export const closeNoteDisputeModal = () => {
  return {
    type: actionTypes.CLOSE_NOTE_DISPUTE_MODAL
  }
}