import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format'

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';

import CategoryChip from '../../UI/CategoryChip'

import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '50%'
  },
  invoice_paid: {
    color: theme.palette.green
  },
  invoice_open: {
    color: theme.palette.red
  }
}));

const InvoicesTable = props => {
  const { 
    invoices
  } = props;
  
  const classes = useStyles();

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Paid?</TableCell>
                  <TableCell>Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map(invoice => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={invoice.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {moment.unix(invoice.date).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <NumberFormat value={invoice.amount_due/100} displayType={'text'} prefix={'$'} thousandSeparator={true} />
                    </TableCell>
                    <TableCell>
                      {invoice.status === 'paid' ? <CheckCircleOutlineIcon className={classes.invoice_paid} />
                        : <HighlightOffIcon className={classes.invoice_open} />
                      }
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          <a href={invoice.invoice_pdf}><GetAppIcon /></a>
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
    </React.Fragment>
  );
};

export default InvoicesTable;
