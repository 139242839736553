import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  users: [],
  user: {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    authentication_token: '',
    authentication_key: ''
  },
  search: '',
  loading: false,
  saving: false,
  apiCredentialModalOpen: false,
  passwordModalOpen: false
}

const fetchUsersStart = (state, action) => {
  return updateObject(state, {loading: true, users:[], user: null})
}

const fetchUsersSuccess = (state, action) => {
  return updateObject(state, {
    loading: false, users: action.users
  })
}

const fetchUsersFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchUserStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.user
  })
}

const fetchUserFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const addUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const addUserSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const addUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const updateUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateUserSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const updateUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const restoreUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const restoreUserSuccess = ( state, action ) => {
  const updatedUsers = state.users.map(user => {
    if (user.resource_id === action.id) {
      return {
        ...user,
        deleted_at: null
      }
    } else {
      return user
    }
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      users: updatedUsers
  } );
};

const restoreUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const archiveUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const archiveUserSuccess = ( state, action ) => {
  const updatedUsers = state.users.map(user => {
    if (user.resource_id === action.id) {
      return {
        ...user,
        deleted_at: action.userData.deleted_at
      }
    } else {
      return user
    }
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      users: updatedUsers
  } );
};

const archiveUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const toggleAdminUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const toggleAdminUserSuccess = ( state, action ) => {
  const updatedUsers = state.users.map(user => {
    if (user.resource_id === action.id) {
      return {
        ...user,
        is_admin: action.userData.is_admin
      }
    } else {
      return user
    }
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      users: updatedUsers
  } );
};

const toggleAdminUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const grantApiAccessUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const grantApiAccessUserSuccess = ( state, action ) => {
  const updatedUsers = state.users.map(user => {
    if (user.resource_id === action.id) {
      return {
        ...user,
        api_granted: action.userData.api_granted,
        api_revoked: action.userData.api_revoked,
        authentication_token: action.userData.authentication_token,
        authentication_key: action.userData.authentication_key
      }
    } else {
      return user
    }
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      users: updatedUsers
  } );
};

const grantApiAccessUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const revokeApiAccessUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const revokeApiAccessUserSuccess = ( state, action ) => {
  const updatedUsers = state.users.map(user => {
    if (user.resource_id === action.id) {
      return {
        ...user,
        api_granted: action.userData.api_granted,
        api_revoked: action.userData.api_revoked,
        authentication_token: action.userData.authentication_token,
        authentication_key: action.userData.authentication_key
      }
    } else {
      return user
    }
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      users: updatedUsers
  } );
};

const revokeApiAccessUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const apiCredentialModalOpen = (state, action) => {
  return updateObject( state, {
    apiCredentialModalOpen: true,
    user: action.user
  } );
}

const apiCredentialModalClose = (state, action) => {
  return updateObject( state, {
    apiCredentialModalOpen: false
  } );
}

const passwordModalOpen = (state, action) => {
  return updateObject( state, {
    passwordModalOpen: true
  } );
}

const passwordModalClose = (state, action) => {
  return updateObject( state, {
    passwordModalOpen: false
  } );
}

const changePasswordStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const changePasswordSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      passwordModalOpen: false
  } );
};

const changePasswordFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    saving: false
  })
}

const forgetDeviceStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const forgetDeviceSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false
  } );
};

const forgetDeviceFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    saving: false
  })
}

const resetMfaStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const resetMfaSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false
  } );
};

const resetMfaFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    saving: false
  })
}

const mfaRequiredToggleUserStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const mfaRequiredToggleUserSuccess = ( state, action ) => {
  const updatedUsers = state.users.map(user => {
    if (user.resource_id === action.id) {
      return {
        ...user,
        mfa_required: action.userData.mfa_required
      }
    } else {
      return user
    }
  })

  return updateObject( state, {
      loading: false,
      saving: false,
      users: updatedUsers,
      user: action.userData
  } );
};

const mfaRequiredToggleUserFail = (state, action) => {
  return updateObject(state, {saving: false})
}



const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state, action)
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action)
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action)
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state, action)
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action)
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action)
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state, action)
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action)
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action)
    case actionTypes.ADD_USER_START:
      return addUserStart(state, action)
    case actionTypes.ADD_USER_SUCCESS:
      return addUserSuccess(state, action)
    case actionTypes.ADD_USER_FAIL:
      return addUserFail(state, action)
    case actionTypes.RESTORE_USER_START:
      return restoreUserStart(state, action)
    case actionTypes.RESTORE_USER_SUCCESS:
      return restoreUserSuccess(state, action)
    case actionTypes.RESTORE_USER_FAIL:
      return restoreUserFail(state, action)
    case actionTypes.ARCHIVE_USER_START:
      return archiveUserStart(state, action)
    case actionTypes.ARCHIVE_USER_SUCCESS:
      return archiveUserSuccess(state, action)
    case actionTypes.ARCHIVE_USER_FAIL:
      return archiveUserFail(state, action)
    case actionTypes.TOGGLE_ADMIN_USER_START:
      return toggleAdminUserStart(state, action)
    case actionTypes.TOGGLE_ADMIN_USER_SUCCESS:
      return toggleAdminUserSuccess(state, action)
    case actionTypes.TOGGLE_ADMIN_USER_FAIL:
      return toggleAdminUserFail(state, action)
    case actionTypes.GRANT_API_ACCESS_USER_START:
      return grantApiAccessUserStart(state, action)
    case actionTypes.GRANT_API_ACCESS_USER_SUCCESS:
      return grantApiAccessUserSuccess(state, action)
    case actionTypes.GRANT_API_ACCESS_USER_FAIL:
      return grantApiAccessUserFail(state, action)
    case actionTypes.REVOKE_API_ACCESS_USER_START:
      return revokeApiAccessUserStart(state, action)
    case actionTypes.REVOKE_API_ACCESS_USER_SUCCESS:
      return revokeApiAccessUserSuccess(state, action)
    case actionTypes.REVOKE_API_ACCESS_USER_FAIL:
      return revokeApiAccessUserFail(state, action)
    case actionTypes.OPEN_API_CREDENTIAL_MODAL:
      return apiCredentialModalOpen(state, action)
    case actionTypes.CLOSE_API_CREDENTIAL_MODAL:
      return apiCredentialModalClose(state, action)
    case actionTypes.OPEN_PASSWORD_MODAL:
      return passwordModalOpen(state, action)
    case actionTypes.CLOSE_PASSWORD_MODAL:
      return passwordModalClose(state, action)
    case actionTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state, action)
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action)
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action)
    case actionTypes.FORGET_DEVICE_START:
      return forgetDeviceStart(state, action)
    case actionTypes.FORGET_DEVICE_SUCCESS:
      return forgetDeviceSuccess(state, action)
    case actionTypes.FORGET_DEVICE_FAIL:
      return forgetDeviceFail(state, action)
    case actionTypes.RESET_MFA_START:
      return resetMfaStart(state, action)
    case actionTypes.RESET_MFA_SUCCESS:
      return resetMfaSuccess(state, action)
    case actionTypes.RESET_MFA_FAIL:
      return resetMfaFail(state, action)
    case actionTypes.MFA_REQUIRED_TOGGLE_USER_START:
      return mfaRequiredToggleUserStart(state, action)
    case actionTypes.MFA_REQUIRED_TOGGLE_USER_SUCCESS:
      return mfaRequiredToggleUserSuccess(state, action)
    case actionTypes.MFA_REQUIRED_TOGGLE_USER_FAIL:
      return mfaRequiredToggleUserFail(state, action)
    default:
      return state
  }
}
    
export default reducer
