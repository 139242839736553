import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {formatFEIN} from '../../../helpers';
import moment from 'moment';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography,
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import MerchantInfo from '../MerchantInfo'
import NoteInfo from '../../Note/NoteInfo'


const useStyles = makeStyles(() => ({
  root: {}
}));

const MerchantForm = props => {
  const { className, merchant, onSubmitClick, onFEINBlur, accounts, loading, is_dm_admin, is_admin, account_merchant_subscription_enabled, merchant_add, onMerchantSubscriptionToggle, plan_level, ...rest } = props;
  const [id, setId] = useState(null)
  const [fein, setFein] = useState(null)
  const [legal_name, setLegalName] = useState(null)
  const [dba, setDba] = useState('')
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [business_phone, setBusinessPhone] = useState('')
  const [business_startdate, setBusinessStartDate] = useState('')
  const [industry, setIndustry] = useState('')
  const [category, setCategory] = useState(null)
  const [note, setNote] = useState(null)
  const [submission_source, setSubmissionSource] = useState(null)
  const [broker_name, setBrokerName] = useState('')
  const [proxied_creator, setProxiedCreator] = useState('')
  const [has_merchant_subscription, setHasMerchantSubscription] = useState(false)
  const [show_broker_name, setShowBrokerName] = useState(false)

  const classes = useStyles();

  useEffect(() => {
    if(merchant) {
      setId(merchant.id)
      setFein(merchant.fein)
      setLegalName(merchant.legal_name)
      setDba(merchant.dba)
      setCity(merchant.city)
      setState(merchant.state)
      setBusinessPhone(merchant.business_phone)
      setBusinessStartDate(merchant.business_startdate)
      setIndustry(merchant.industry)
      setProxiedCreator('')
      setCategory(merchant.category)
      setNote(merchant.note)
      setSubmissionSource(merchant.submission_source)
      setBrokerName(merchant.broker_name)
      setHasMerchantSubscription(merchant.has_merchant_subscription)
      if (merchant.submission_source === 'broker'){
        setShowBrokerName(true)
      } else {
        setShowBrokerName(false)
      }
    } else {
      setId(null)
      setFein(null)
      setLegalName(null)
      setDba(null)
      setCity(null)
      setState(null)
      setBusinessPhone(null)
      setBusinessStartDate(null)
      setIndustry(null)
      setProxiedCreator(null)
      setCategory(null)
      setNote(null)
      setSubmissionSource(null)
      setBrokerName(null)
      setHasMerchantSubscription(false)
    }
    
  },[merchant, accounts])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'fein':
        setFein(formatFEIN(event.target.value))
        break
      case 'legal_name':
        setLegalName(event.target.value)
        break
      case 'dba':
        setDba(event.target.value)
        break
      case 'city':
        setCity(event.target.value)
        break
      case 'state':
        setState(event.target.value)
        break
      case 'business_phone':
        setBusinessPhone(event.target.value)
        break
      case 'business_startdate':
        setBusinessStartDate(event.target.value)
        break
      case 'industry':
        setIndustry(event.target.value)
        break
      case 'proxied_creator':
        setProxiedCreator(event.target.value)
        break
      case 'category':
        setCategory(event.target.value)
        break
      case 'note':
        setNote(event.target.value)
        break
      case 'submission_source':
        setSubmissionSource(event.target.value)
        setBrokerName(null)
        if (event.target.value === 'broker'){
          setShowBrokerName(true)
        } else {
          setShowBrokerName(false)
        }
        break
      case 'broker_name':
        setBrokerName(event.target.value)
        break
      default:
        return null
    }
  };

  const handleMerchantSubscriptionToggle = (event) => {
    
  };

  let note_form = null
  if(!id){
    note_form = (
      <React.Fragment>
        <CardHeader
          title="Note"
        />
        <Divider />
        <CardContent>
          <NoteInfo
            note={note}
            category={category}
            submission_source={submission_source}
            broker_name={broker_name}
            is_dm_admin={is_dm_admin}
            merchant_add={merchant_add}
            loading={loading}
            accounts={accounts}
            onChange={(event, field) => handleInputChange(event, field)}
            plan_level={plan_level}
            show_broker_name={show_broker_name}
          />
        </CardContent>
        <Divider />
      </React.Fragment>
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
      >
        <MerchantInfo
          id={id}
          fein={fein}
          legal_name={legal_name}
          dba={dba}
          city={city}
          state={state}
          business_phone={business_phone}
          business_startdate={business_startdate}
          industry={industry}
          proxied_creator = {proxied_creator}
          onChange={(event, field) => handleInputChange(event, field)}
          onFEINBlur={onFEINBlur}
          accounts={accounts}
          loading={loading}
          is_dm_admin={is_dm_admin}
          is_admin={is_admin}
          account_merchant_subscription_enabled={account_merchant_subscription_enabled}
          merchant_add={merchant_add}
          has_merchant_subscription = {has_merchant_subscription}
          onMerchantSubscriptionToggle={onMerchantSubscriptionToggle}
        />
        <Divider />
        {note_form}
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              md={6}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={(event, merchant) => onSubmitClick(event, {
                  id: id,
                  fein: fein,
                  legal_name: legal_name,
                  dba: dba,
                  city: city,
                  state: state,
                  business_phone: business_phone,
                  business_startdate: business_startdate,
                  industry: industry,
                  proxied_creator: proxied_creator,
                  category: category,
                  note: note,
                  submission_source: submission_source,
                  broker_name: broker_name
                })}
                >
                  Save details
                </Button>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
              >
                { is_dm_admin && merchant && merchant.id ? 
                    <Typography style={{ textAlign: 'right' }}>
                      Added by {merchant.added_by_user} - {merchant.added_by}, on {moment(merchant.created_at).format('MM/DD/YYYY')}
                      <Tooltip title="Only admin users for your company can see who entered this record.  This is not displayed to any other DataMerch user.">
                        <IconButton>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  : null
                }
              </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    is_dm_admin: state.auth.user.is_dm_admin,
    is_admin: state.auth.user.is_admin,
    account_merchant_subscription_enabled: state.auth.user.account_merchant_subscription_enabled
  }
}


export default withErrorHandler(MerchantForm, axios)