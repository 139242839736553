import React, {useState, useEffect} from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../../store/actions'
import validate from 'validate.js';
import queryString from 'query-string'

import {Terms} from '../../../components'

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from '@material-ui/core';

const schema = {
  agree: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  terms: {
    height: '400px',
    overflow: 'scroll'
  }
}));

const AgreeToTerms  = (props) => {
  const { 
    onAgreeToTermsSubmit,
    error,
    id
  } = props

  // const isSuccess = token ? true : false;
  const message = error;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let agree = event.target.agree.checked;
    
    if(agree)
      onAgreeToTermsSubmit(id);
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Terms of Use Agreement" />
          <Divider />
          <CardContent className={classes.terms}>
            <Terms />
          </CardContent>
          <Divider />
          <CardActions>
            <form
              onSubmit={handleSubmit}
            >
              <FormGroup row>
              <FormControlLabel
                control={
                    <Checkbox
                      onChange={handleChange}
                      value="agree"
                      name="agree"
                      color="primary"
                    />
                  }
                  label="I Agree to the DateMerch Terms of Use"
                />
              </FormGroup>
              <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Agree to Terms
              </Button>
            </form>
          </CardActions>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    id: state.auth.user.resource_id
  }
}

const mapDispatchToProps = dispatch => ({
  onAgreeToTermsSubmit: (id) => dispatch(actions.agreeToTerms(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AgreeToTerms);
