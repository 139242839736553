import React, { useState } from 'react';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { store } from '../../Store'
import { withRouter } from 'react-router-dom';

import withErrorHandler from '../../hocs/withErrorHandler'
import axios from '../../httpClients/axios-datamerch'
import * as actions from '../../store/actions'

import { Sidebar, Topbar, Footer } from './components';

import { MerchantNotFoundDialog } from '../../components/Merchant';
import { Timeout } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = React.memo(props => {
  const { children, token, user, account } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const merchantSearch = (search, token, user) => {
    return { type: 'SEARCH_MERCHANTS', search: search, token: token, user: user }
  };

  const handleMerchantSearchClick = (event, search) => {
    event.preventDefault();
    store.dispatch(merchantSearch(search, token, user))
  }

  const logoutUser = () => {
    return { type: 'LOGOUT_USER' }
  };

  const handleLogoutClick = () => {
    store.dispatch(logoutUser())
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  let main = (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Timeout />
      <Topbar 
        onSidebarOpen={handleSidebarOpen}
        onMerchantSearchClick={(event, search) => handleMerchantSearchClick(event, search)} 
        onLogoutClick={handleLogoutClick}
        user={user}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        onMerchantSearchClick={(event, search) => handleMerchantSearchClick(event, search)}
        onLogoutClick={handleLogoutClick}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  )

  // if (searched){
  //   main = (
  //     <Redirect to="/search" />
  //   )
  // }

  return (
    <React.Fragment>
      {main}
      <MerchantNotFoundDialog />
    </React.Fragment>
  );
});

Main.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => {
  return {
    //account: state.auth.user.account,
    // saving: state.account.saving,
    user: state.auth.user,
    token: state.auth.token,
    openAddMerchantDialog: state.merchant.openAddMerchantDialog
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onMerchantSearch: (token, search, user) => dispatch(actions.searchMerchants(token, search, user)),
    onMerchantSearchReset: (token) => dispatch(actions.searchMerchantsReset(token)),
    onLogoutClick: () => dispatch(actions.logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main))
