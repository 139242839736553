import { history } from '../Routes'
import { put, select, call } from 'redux-saga/effects'
import * as actions from '../store/actions'
import {store} from '../Store'

export function * searchFlow (payload) {
  try {
    yield put(actions.searchMerchants(payload.token, payload.search, payload.user))
  } catch (error) {
    console.log(error)
  }
}

export function * searchResultsFlow (payload) {
  try {
    let url = `/search`
    if (payload.merchants && payload.merchants.length === 1){
      yield put({ type: 'FETCH_MERCHANT_SUCCESS', merchant: payload.merchants[0] })
      url = `/merchants/${payload.merchants[0].resource_id}`
    }
    if (payload.merchants && payload.merchants.length > 1){
      yield put({ type: 'FETCH_MERCHANT_SUCCESS', merchants: payload.merchants })
      url = `/search`
    }
    yield history.push(url)
  } catch (error) {
    console.log(error)
  }
}