import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { asPercent } from '../../../helpers';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  button_delete_selected: {
    marginLeft: '10px'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '40%'
  },
  membership_status: {
    justifyContent: 'flex-end'
  },
  membership_trialing: {
    color: theme.palette.orange,
  },
  membership_active: {
    color: theme.palette.green
  },
  membership_inactive: {
    color: theme.palette.red
  },
  contribution_container: {
    display: 'flex'
  },
  contribution_caution: {
    color: theme.palette.orange,
    fontWeight: 'bold'
  },
  contribution_ok: {
    color: theme.palette.green,
    fontWeight: 'bold'
  },
  contribution_low: {
    color: theme.palette.red,
    fontWeight: 'bold'
  },
  contribution_indicator_override: {
    backgroundColor: theme.palette.green,
    padding: '6px',
    borderRadius: '20px',
    margin: '5px 0 5px 20px'
  },
  contribution_indicator_locked: {
    backgroundColor: theme.palette.red,
    padding: '6px',
    borderRadius: '20px',
    margin: '5px 0 5px 20px'
  },
  contribution_indicator_warning: {
    backgroundColor: theme.palette.orange,
    padding: '6px',
    borderRadius: '20px',
    margin: '5px 0 5px 20px'
  }

}));

const MerchantSubscriptionsTable = props => {
  const { 
    merchant_subscriptions,
    // onAccountApiAccessToggle,
    onArchiveClick,
    pagination,
    onPageChange,
    onPerPageChange,
    onSearchClick,
    onSearchCancelClick,
    currentSearch,
    // onMembershipFilterChange,
    isFiltered,
    // currentMembershipFilter,
    // onAccountMfaRequiredToggle,
    // onSelectAllMerchantSubscriptionsToggle
    onSelectAllMerchantSubscriptionsToggle,
    onDeselectMerchantSubscription,
    onDeleteSelectedMerchantSubscriptionsClick,
    onDeleteMerchantSubscriptionClick,
    onDeleteAllMerchantSubscriptionsClick,
    onSelectMerchantSubscription,
    onAddMerchantSubscriptionClick
  } = props;

  // let merchant_subscriptions = props.merchant_subscriptions
  
  const classes = useStyles();
  const [search, setSearch] = useState(currentSearch)
  const [table_merchant_subscriptions, setTableMerchantSubscriptions] = useState(merchant_subscriptions)
  
  const handleSearchChange = event => {
    setSearch(event.target.value)
  }


  const onSelectMerchantSubscriptionToggle = (event, merchant_subscription) => {
    // if (merchant_subscription.selected){
    //   onDeselectMerchantSubscription(merchant_subscription)
    // } else {
    //   onSelectMerchantSubscription(merchant_subscription)
    // }
    setTableMerchantSubscriptions(table_merchant_subscriptions.map(item => { 
      if (item.resource_id === merchant_subscription.resource_id){
        return { ...item, selected: !item.selected } 
      } else {
        return { ...item, selected: item.selected } 
      }
    }))
  }

  useEffect(() => {
    setTableMerchantSubscriptions(merchant_subscriptions)
  }, [merchant_subscriptions])

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  const TablePaginationActions = (props) => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = event => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = event => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = event => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = event => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const MarkedEIN = (props) => {
    const classes = useStyles1();
    const theme = useTheme();
    const { ein } = props;

    const stripText = (val) => {
      val = val.replace(/[^0-9]/, '')
      return val
    }

    const markSearch = (ein, search) => {
      return ein.replace(search, `<mark>${search}</mark>`)
    }

    const formattedEIN = (ein, search) => {
      ein = stripText(ein)
      search = stripText(search)
      if (search){
        ein = markSearch(ein, search)
        switch(ein.indexOf('<mark>')){
          case 0:
            if (search.length > 2){
              ein =  ein.replace(/(.{8})/, '$1-')
            } else {
              ein =  ein.replace(/(.{15})/, '$1-')
            }
            break
          case 1:
            ein =  ein.replace(/(.{8})/, '$1-')
            break
          case 2:
            ein =  ein.replace(/(\d{2})/, '$1-')
            break
          default:
            ein =  ein.replace(/(\d{2})/, '$1-')
            break
        }

        return ein
      }
      return ein.replace(/(\d{2})(\d{7})/, '$1-$2')
    }

    return (
      <p dangerouslySetInnerHTML={{__html: formattedEIN(ein, search) }} />
    );
  }

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TextField
                      className={classes.searchInput}
                      placeholder="Search by EIN"
                      inputProps={{ 'aria-label': 'Search by EIN' }}
                      value={search}
                      onChange={(event) => handleSearchChange(event)}
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      onClick={(event) => onSearchClick(event, search)}
                      aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    { isFiltered ?
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        onClick={(event) => onSearchCancelClick(event, search)}
                        aria-label="clear search">
                        <CloseIcon />
                      </IconButton>
                    : null }
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align={'right'}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={() => onAddMerchantSubscriptionClick()}
                    >
                      Add Merchant Subscriptions
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                        color="primary"
                        onChange={event => onSelectAllMerchantSubscriptionsToggle(event)}
                      />
                  </TableCell>
                  <TableCell>EIN</TableCell>
                  <TableCell align={'right'}>
                    {table_merchant_subscriptions.length > 0 && <Button
                        variant="contained"
                        className={classes.button}
                        color="secondary"
                        onClick={(event) => onDeleteAllMerchantSubscriptionsClick(event)}
                      >
                        Delete ALL Subscriptions
                      </Button>
                    }
                    {
                      table_merchant_subscriptions.filter((item)=> { 
                          return item.selected
                        }).length > 0 && <Button
                        variant="contained"
                        className={classes.button_delete_selected}
                        onClick={(event) => onDeleteSelectedMerchantSubscriptionsClick(event, table_merchant_subscriptions.filter((item)=>{
                          return item.selected
                        }).map(item => item.resource_id))}
                      >
                        Delete Selected ({table_merchant_subscriptions.filter((item)=> { 
                          return item.selected
                        }).length})
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table_merchant_subscriptions && table_merchant_subscriptions.map(merchant_subscription => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={merchant_subscription.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Checkbox
                          color="primary"
                          onChange={event => onSelectMerchantSubscriptionToggle(event, merchant_subscription)}
                          value={merchant_subscription.id}
                          checked={merchant_subscription.selected}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          <MarkedEIN ein={merchant_subscription.fein} />
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align={'right'}>
                      <IconButton aria-label="delete" onClick={event => onDeleteMerchantSubscriptionClick(event, merchant_subscription.resource_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pagination ? pagination.total_count : 0}
          onPageChange={onPageChange}
          onChangeRowsPerPage={onPerPageChange}
          page={pagination ? pagination.current_page : 0}
          rowsPerPage={pagination ? pagination.per_page : 25}
          rowsPerPageOptions={[25, 50, 100]}
          ActionsComponent={TablePaginationActions}
        />
      </CardActions>
    </React.Fragment>
  );
};

export default MerchantSubscriptionsTable;
