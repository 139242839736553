import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  merchant_subscription_webhooks: [],
  loading: false,
  error: null
}

const fetchMerchantSubscriptionWebhooksStart = (state, action) => {
  return updateObject(state, {loading: true, merchant_subscription_webhooks: []})
}

const fetchMerchantSubscriptionWebhooksSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant_subscription_webhooks: action.merchant_subscription_webhooks
  })
}

const fetchMerchantSubscriptionWebhooksFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_START:
      return fetchMerchantSubscriptionWebhooksStart(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_SUCCESS:
      return fetchMerchantSubscriptionWebhooksSuccess(state, action)
    case actionTypes.FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_FAIL:
      return fetchMerchantSubscriptionWebhooksFail(state, action)
    default:
      return state
  }
}
    
export default reducer
