import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'

import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Divider } from '@material-ui/core';

const MerchantNotFoundDialog = props => {
  const { 
    openDialog,
    onCloseDialog,
    onChooseAdd,
    search,
    onFetchSearchCounts,
    search_counts,
    plan_level
  } = props;
  
  const handleDialogClose = () => {
    onCloseDialog()
  };

  const handleChooseAdd = () => {
    onChooseAdd()
  };

  useEffect(() => {
    if (is_fein(search)){
      onFetchSearchCounts(search)
    }
  }, [search])

  const is_fein = (val) => {
    if (val && (val.length === 9 || val.length === 10)) {
      val = val.replace(/[^0-9]/, '')
      return val.length === 9
    } else {
      return false
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"No Merchants Found"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          No merchants were found matching your search.  Would you llike to add a merchant into DataMerch?
        </DialogContentText>
        {search_counts && search_counts.length > 0 && (plan_level === 'PRO') ?
        <DialogContentText id="alert-dialog-description2">
          <b>{search}</b> has been search <b>{search_counts.length}</b> times in the last six months.
        </DialogContentText>
        : null }
        {(plan_level !== 'PRO') ?
        <DialogContentText id="alert-dialog-description2">
          <b>{search}</b> - search count available to PRO members only
        </DialogContentText>
        : null }
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          No
        </Button>
        <Button
          onClick={handleChooseAdd}
          variant="contained"
          color="primary"
          autoFocus
        >
          Yes, I want to add a merchant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    openDialog: state.merchant.merchant_not_found_dialog_open,
    search: state.merchant.search,
    search_counts: state.searchCount.search_counts,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseDialog: () => dispatch(actions.closeMerchantNotFoundDialog()),
    onChooseAdd: () => dispatch(actions.initializeAddMerchant()),
    onFetchSearchCounts: (search) => dispatch(actions.fetchSearchCounts(search))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantNotFoundDialog)
