import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../hocs/withErrorHandler'
import axios from '../../httpClients/axios-datamerch'
import * as actions from '../../store/actions'

import { UserForm } from '../../components/User';

import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  },
  members: {
    marginTop: '30px'
  }
}));

const Profile = props => {
  const classes = useStyles();

  const { user, onProfileSave, token} = props
  
  const handleSubmitClick = (event, user) => {
    event.preventDefault();
  
    const userData = {
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
      }
    }
    onProfileSave(token, userData, user.id)
  };

  let spinner = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!props.saving && !props.loading){
    spinner = null
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <UserForm user={user} onSubmitClick={handleSubmitClick} profile={true} />
        {spinner}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    saving: state.auth.saving,
    loading: state.auth.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onProfileSave: (token, userData, user_id) => dispatch(actions.updateProfile(token, userData, user_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Profile, axios))