import React from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  list: {
    paddingLeft: theme.spacing(3)
  }
}));

const DisputeProcess  = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="Dispute Process" />
          <Divider />
          <CardContent className={classes.terms}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              Research DataMerch Record and Information
            </Typography>
            <Typography
              variant="subtitle2"
            >
              <ul className={classes.list}>
                <li>
                  Business owner completes <RouterLink to="/dispute">dispute form</RouterLink>
                </li>
                <li>
                  DataMerch internally finds the entry record and reviews
                </li>
                <li>
                  DataMerch informs business owner what information is listed and directs business owner to contact funder who entered the record or note
                </li>
                <li>
                  DataMerch contacts funder who entered the record (if necessary)
                </li>
                <li>
                  DataMerch gathers information from both sides - business owner and funder
                </li>
              </ul>
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              Supplement Record
            </Typography>
            <Typography
              variant="subtitle2"
            >
              <ul className={classes.list}>
                <li>
                  Funder can amend or request to remove the record (if necessary)
                </li>
                <li>
                  Allow business owner to add a statement to the record
                </li>
              </ul>
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              Close Dispute Case
            </Typography>
            <Typography
              variant="subtitle2"
            >
              <ul className={classes.list}>
                <li>
                  Inform business owner and funder on resolution
                </li>
                <li>
                  Close dispute case
                </li>
              </ul>
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              Other Information
            </Typography>
            <Typography
              variant="subtitle2"
            >
              <ul className={classes.list}>
                <li>
                  DataMerch will only remove a record if it was entered in error and requested to be removed by funder
                </li>
                <li>
                  Default records will NOT be removed if a collection is paid in full. An additional note can be added to update the record to reflect the collection has a zero balance.
                </li>
                <li>
                  DataMerch will remove any record if requested directly by the business owner after 7 years
                </li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default DisputeProcess;
