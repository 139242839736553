import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import CategoryChip from '../../UI/CategoryChip'

import {
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '50%'
  }
}));

const MerchantsTable = props => {
  const { 
    merchants,
    onArchiveClick,
    pagination,
    onPageChange,
    onPerPageChange,
    onSearchClick,
    onSearchCancelClick,
    currentSearch,
    isFiltered
  } = props;
  
  useEffect(() => {
    setSearch(currentSearch)
  }, [currentSearch])
  
  const classes = useStyles();

  const [search, setSearch] = useState(currentSearch)
  
  const handleSearchChange = event => {
    setSearch(event.target.value)
  }

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  
  const TablePaginationActions = (props) => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <form action="#!">
                      <TextField
                        className={classes.searchInput}
                        placeholder="Search by company name, EIN, or BN"
                        value={search}
                        onChange={(event) => handleSearchChange(event)}
                        size={'medium'}
                      />
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        onClick={(event) => onSearchClick(event, search)}
                        aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      { isFiltered ?
                        <IconButton
                          type="submit"
                          className={classes.iconButton}
                          onClick={(event) => onSearchCancelClick(event, search)}
                          aria-label="clear search">
                          <CloseIcon />
                        </IconButton>
                      : null }
                    </form>
                  </TableCell>
                  <TableCell colSpan={3} align={'right'}>
                    <Link to="/merchants/new">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddIcon />}
                      >
                        Add Merchant
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>EIN/BN</TableCell>
                  <TableCell>Added By</TableCell>
                  <TableCell>Added</TableCell>
                  <TableCell>Latest Note Category</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchants.map(merchant => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={merchant.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          <Link to={`/merchants/${merchant.resource_id}`}>{merchant.legal_name}</Link>
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      {merchant.fein}
                    </TableCell>
                    <TableCell>
                      {merchant.added_by} - {merchant.added_by_user}
                    </TableCell>
                    <TableCell>
                      {moment(merchant.created_at).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      <CategoryChip category={merchant.last_note_category} />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" onClick={event => onArchiveClick(event, merchant.resource_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pagination ? pagination.total_count : 0}
          onChangePage={onPageChange}
          onChangeRowsPerPage={onPerPageChange}
          page={pagination ? pagination.current_page : 0}
          rowsPerPage={pagination ? pagination.per_page : 25}
          rowsPerPageOptions={[25, 50, 100]}
          ActionsComponent={TablePaginationActions}
        />
      </CardActions>
    </React.Fragment>
  );
};

export default MerchantsTable;
