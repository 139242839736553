import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  merchants: [],
  merchant: {
    fein: '',
    legal_name: '',
    dba: '',
    city: '',
    state: '',
    business_phone: '',
    business_startdate: '',
    industry: '',
    category: '',
    note: '',
    reason: ''
  },
  id: null,
  dialog_id: null,
  saving: false,
  error: null,
  flash: null,
  pagination: {
    current_page: 0,
    per_page: 25,
    total_pages: 1,
    total_count: 0
  },
  searched: false,
  merchant_not_found_dialog_open: false,
  merchant_exists_dialog_open: false,
  merchant_delete_modal_open: false,
  reason: ''
}

const fetchDeletedMerchantsStart = (state, action) => {
  return updateObject(state, {loading: true, merchants: [], merchant: null})
}

const fetchDeletedMerchantsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchants: action.merchants,
    pagination: action.pagination
  })
}

const fetchDeletedMerchantsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchDeletedMerchantStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchDeletedMerchantSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchant: action.merchant
  })
}

const fetchDeletedMerchantFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const searchDeletedMerchantsStart = (state, action) => {
  return updateObject(state, {loading: true, merchants: [], merchant: null})
}

const searchDeletedMerchantsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    merchants: action.merchants,
    searched: true
  })
}

const searchDeletedMerchantsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    merchants: [],
    searched: true
  })
}

const searchDeletedMerchantsReset = (state, action) => {
  return updateObject(state, {
    searched: false
  })
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_DELETED_MERCHANTS_START:
      return fetchDeletedMerchantsStart(state, action)
    case actionTypes.FETCH_DELETED_MERCHANTS_SUCCESS:
      return fetchDeletedMerchantsSuccess(state, action)
    case actionTypes.FETCH_DELETED_MERCHANTS_FAIL:
      return fetchDeletedMerchantsFail(state, action)
    case actionTypes.FETCH_DELETED_MERCHANT_START:
      return fetchDeletedMerchantStart(state, action)
    case actionTypes.FETCH_DELETED_MERCHANT_SUCCESS:
      return fetchDeletedMerchantSuccess(state, action)
    case actionTypes.FETCH_DELETED_MERCHANT_FAIL:
      return fetchDeletedMerchantFail(state, action)
    case actionTypes.SEARCH_DELETED_MERCHANTS_START:
      return searchDeletedMerchantsStart(state, action)
    case actionTypes.SEARCH_DELETED_MERCHANTS_SUCCESS:
      return searchDeletedMerchantsSuccess(state, action)
    case actionTypes.SEARCH_DELETED_MERCHANTS_FAIL:
      return searchDeletedMerchantsFail(state, action)
    case actionTypes.SEARCH_DELETED_MERCHANTS_RESET:
      return searchDeletedMerchantsReset(state, action)
    default:
      return state
  }
}
    
export default reducer
