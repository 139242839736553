import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { asPercent } from '../../../helpers';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IconButton, TableContainer, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Chip
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '40%'
  },
  membership_status: {
    justifyContent: 'flex-end'
  },
  membership_trialing: {
    color: theme.palette.orange,
  },
  membership_active: {
    color: theme.palette.green
  },
  membership_inactive: {
    color: theme.palette.red
  },
  contribution_container: {
    display: 'flex'
  },
  contribution_caution: {
    color: theme.palette.orange,
    fontWeight: 'bold'
  },
  contribution_ok: {
    color: theme.palette.green,
    fontWeight: 'bold'
  },
  contribution_low: {
    color: theme.palette.red,
    fontWeight: 'bold'
  },
  contribution_indicator_override: {
    backgroundColor: theme.palette.green,
    padding: '6px',
    borderRadius: '20px',
    margin: '5px 0 5px 20px'
  },
  contribution_indicator_locked: {
    backgroundColor: theme.palette.red,
    padding: '6px',
    borderRadius: '20px',
    margin: '5px 0 5px 20px'
  },
  contribution_indicator_warning: {
    backgroundColor: theme.palette.orange,
    padding: '6px',
    borderRadius: '20px',
    margin: '5px 0 5px 20px'
  },
  chip: {
    verticalAlign: 'top',
    marginLeft: '10px'
  },
  filter_label: {
    display: 'inline-block',
    padding: '13px 5px'
  }

}));

const AccountsTable = props => {
  const { 
    accounts,
    onAccountApiAccessToggle,
    onArchiveClick,
    pagination,
    onPageChange,
    onPerPageChange,
    onSearchClick,
    onSearchCancelClick,
    currentSearch,
    onMembershipFilterChange,
    onPlanLevelFilterChange,
    isFiltered,
    currentMembershipFilter,
    currentPlanLevelFilter,
    onAccountMfaRequiredToggle,
  } = props;
  
  const classes = useStyles();

  const [search, setSearch] = useState(currentSearch)
  const [membership_filter, setMembershipFilter] = useState(currentMembershipFilter)
  const [plan_level_filter, setPlanLevelFilter] = useState(currentPlanLevelFilter)
  
  const handleSearchChange = event => {
    setSearch(event.target.value)
  }

  const handleMembershipFilterChange = event => {
    setMembershipFilter(event.target.value)
    onMembershipFilterChange(event, event.target.value)
  }

  const handlePlanLevelFilterChange = event => {
    setPlanLevelFilter(event.target.value)
    onPlanLevelFilterChange(event, event.target.value)
  }

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  const membership_filter_options = [
    {
      label: 'Active Membership',
      value: 'active'
    },
    {
      label: 'Inactive/Deleted',
      value: 'canceled'
    },
    {
      label: 'All',
      value: ''
    }
  ];

  const plan_level_filter_options = [
    {
      label: 'BASIC',
      value: 'BASIC'
    },
    {
      label: 'PRO',
      value: 'PRO'
    },
    {
      label: 'Any',
      value: ''
    }
  ];
  
  const TablePaginationActions = (props) => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const ContributionPercentage = (props) => {
    const { val, locked, warning, override } = props;
    const percent = asPercent(val);
    let cssName = classes.contribution_caution;

    if (percent > 1)
      cssName = classes.contribution_ok;
    if (percent < 0.5)
      cssName = classes.contribution_low;

    return (
      <div className={cssName}>
        { percent } % 
      </div>
    );
  }

  const ContributionPercentageIndicator = (props) => {
    const { locked, warning, override } = props;
    
    if (override) {
      return (
        <div className={classes.contribution_indicator_override}></div>
      )
    }

    if (locked && !override) {
      return (
        <div className={classes.contribution_indicator_locked}></div>
      )
    }

    if (warning && !locked && !override) {
      return (
        <div className={classes.contribution_indicator_warning}></div>
      )
    }

    return (
      null
    );
  }


  const PlanLevelPill = (props) => {
    const { plan_level } = props;
    if (plan_level === "BASIC"){
      return (
        <Chip label="BASIC" size="small" variant="outline" className={classes.chip} />
      );
    } else {
      return (
        <Chip label="PRO" size="small" color="primary" className={classes.chip} />
      );
    }
  }




  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <TextField
                      className={classes.searchInput}
                      placeholder="Search by Funder name"
                      inputProps={{ 'aria-label': 'Search by Funder name' }}
                      value={search}
                      onChange={(event) => handleSearchChange(event)}
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      onClick={(event) => onSearchClick(event, search)}
                      aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    { isFiltered ?
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        onClick={(event) => onSearchCancelClick(event, search)}
                        aria-label="clear search">
                        <CloseIcon />
                      </IconButton>
                    : null }
                  </TableCell>
                  <TableCell colSpan={2}>
                    <span className={classes.filter_label}>Membership Status</span>
                    <TextField
                      className={classes.searchInput}
                      label=""
                      margin="dense"
                      name="membership_filter"
                      value={membership_filter || ''}
                      onChange={handleMembershipFilterChange}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {membership_filter_options.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <span className={classes.filter_label}>Plan Level</span>
                    <TextField
                      className={classes.searchInput}
                      label=""
                      margin="dense"
                      name="plan_level_filter"
                      value={plan_level_filter || ''}
                      onChange={handlePlanLevelFilterChange}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {plan_level_filter_options.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell colSpan={1} align={'right'}>
                    <Link to="/funders/new">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddIcon />}
                      >
                        Add Funder
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Merchants Added</TableCell>
                  <TableCell>Last Added</TableCell>
                  <TableCell>90 Day Cont. %</TableCell>
                  <TableCell>API Access</TableCell>
                  <TableCell>MFA Required</TableCell>
                  <TableCell>Membership?</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map(account => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={account.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          <Link to={`/funders/${account.resource_id}`}>{account.name}</Link>
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      {account.merchants_added}
                    </TableCell>
                    <TableCell>
                      {moment(account.last_merchant_added_at).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      <div className={classes.contribution_container}>
                        <ContributionPercentage
                          component="div"
                          val = { account.total_ninety_days_contribution_percentage }
                          locked = { account.contribution_locked }
                          warning = { account.contribution_warning_date }
                          override = { account.contribution_override }
                        />
                        <ContributionPercentageIndicator
                          component="div"
                          locked = { account.contribution_locked }
                          warning = { account.contribution_warning_date }
                          override = { account.contribution_override }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={account.can_access_api}
                        color="primary"
                        onChange={event => onAccountApiAccessToggle(event, account)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={account.mfa_required}
                        color="primary"
                        onChange={event => onAccountMfaRequiredToggle(event, account)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell className={classes.membership_status}>
                      {account.membership_status ? 
                        account.membership_status === 'trialing' ? <PanoramaFishEyeIcon className={classes.membership_trialing} /> : <span><CheckCircleOutlineIcon className={classes.membership_active} /><PlanLevelPill plan_level={account.plan_level} /></span>
                        : <HighlightOffIcon className={classes.membership_inactive} />
                      }
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" onClick={event => onArchiveClick(event, account.resource_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={ pagination ? pagination.total_count : 0}
          onChangePage={onPageChange}
          onChangeRowsPerPage={onPerPageChange}
          page={pagination ? pagination.current_page : 0}
          rowsPerPage={pagination ? pagination.per_page : 25}
          rowsPerPageOptions={[5, 10, 25]}
          ActionsComponent={TablePaginationActions}
        />
      </CardActions>
    </React.Fragment>
  );
};

export default AccountsTable;
