import React from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Divider, CardHeader } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { store } from '../../Store'

import * as actions from '../../store/actions'


import {RecentMerchantsByCategory} from '../../components'
import {SearchesByDay} from '../../components'
import {AdditionsByDay} from '../../components'
import {SearchesBySource} from '../../components'
import {HitsByCategory} from '../../components'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    padding: '13px 5px 13px 5px'
  },
  divider: {
    margin: '40px 0'
  },
  dividerMinHeight: {
    margin: '0px 0 10px 0'
  },
  noUnderline: {
    textDecoration: 'none'
  }
}));

const Analytics = (props) => {
  const { 
    token,
    is_dm_admin,
    account_id
  } = props
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      > 
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <AdditionsByDay account={account_id} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <SearchesByDay account={account_id} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={12}
          xl={3}
          xs={12}
        >
          <SearchesBySource account={account_id} />
        </Grid>
        <Grid
          item
          lg={9}
          sm={12}
          xl={9}
          xs={12}
        >
          <HitsByCategory account={account_id} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    is_admin: state.auth.user.is_admin,
    is_dm_admin: state.auth.user.is_dm_admin,
    account_id: state.auth.user.account_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onMerchantSearch: (token, search) => dispatch(actions.searchMerchants(token, search)),
    onMerchantSearchReset: (token) => dispatch(actions.searchMerchantsReset(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)

