import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { InvoicesTable } from '../../Account';
import { CircularProgress, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InvoicesList = props => {
  const classes = useStyles();

  const { 
    onFetchInvoices,
    token,
    accountId,
    className,
    loading,
    account_view,
    ...rest
  } = props
  useEffect(() => {
    onFetchInvoices(token, accountId);
  }, [onFetchInvoices, token, accountId])

  let invoices = <CircularProgress />
  if(!loading){
    invoices = (
      <InvoicesTable
        invoices={props.invoices}
        account_id={accountId}
        account_view={account_view}
      />
    )
  }
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Invoices"
      />
      <Divider />
      {invoices}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    invoices: state.stripe.invoices,
    loading: state.user.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchInvoices: (token, accountId) => dispatch(actions.fetchInvoices(token, accountId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(InvoicesList, axios))