import React, {useState} from 'react'
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  
}));

const Toast = props => {
  const { id, severity, duration, title, message, horizontal, vertical, onDismissClick } = props;
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  
  return (
    <Snackbar
      key={id}
      open={open}
      autoHideDuration={duration}
      onClose={onDismissClick}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert variant="filled" onClose={onDismissClick} severity={severity}>
        { title ? 
          <AlertTitle>{title}</AlertTitle>
          : null
        }
        {message}
      </Alert>
    </Snackbar>
  )
};

export default Toast