import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  notes: [],
  note: {
    id: null,
    category: '',
    note: ''
  },
  loading: false,
  saving: false,
  modalOpen: false
}

const fetchDeletedNotesStart = (state, action) => {
  return updateObject(state, {loading: true, notes:[], note: null})
}

const fetchDeletedNotesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false, notes: action.notes
  })
}

const fetchDeletedNotesFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchDeletedNoteStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchDeletedNoteSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    note: action.note
  })
}

const fetchDeletedNoteFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_DELETED_NOTES_START:
      return fetchDeletedNotesStart(state, action)
    case actionTypes.FETCH_DELETED_NOTES_SUCCESS:
      return fetchDeletedNotesSuccess(state, action)
    case actionTypes.FETCH_DELETED_NOTES_FAIL:
      return fetchDeletedNotesFail(state, action)
    case actionTypes.FETCH_DELETED_NOTE_START:
      return fetchDeletedNoteStart(state, action)
    case actionTypes.FETCH_DELETED_NOTE_SUCCESS:
      return fetchDeletedNoteSuccess(state, action)
    case actionTypes.FETCH_DELETED_NOTE_FAIL:
      return fetchDeletedNoteFail(state, action)
    default:
      return state
  }
}
    
export default reducer
