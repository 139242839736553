  
import React from 'react';  
import {connect} from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom';
import { store } from '../../Store'
import { checkToken } from '../../helpers/token';
import NotFound from '../../views'

const AdminRoute = ({ layout: Layout, component: Component, ...rest }) => {  
  const user = store.getState().auth
  if (!user.isLoggedIn || !user.user.is_admin) {
    store.dispatch({
      type: 'UNAUTHORIZED',
      from: window.location.pathname
    })
  }
  return (
    <Route { ...rest } render={props => (
      // checkToken() !== null && is_admin ? (
        <Layout>
          <Component { ...props } />
        </Layout>
      // ) : (
      //   // <Redirect to={{
      //   //     pathname: '/login',
      //   //     state: { from: props.location }
      //   //   }}
      //   // />
      //   <Route component={NotFound} />
      // )
    )} />
  )
};

export default AdminRoute
