import axios from '../httpClients/axios-default'

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}


export const authHeaders = () => {
  let headers = {}
  const token = window.localStorage.getItem('token')
  if (token) {
    headers = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  }

  return headers
}

export const formatFEIN = (value) => {
  let val = value.replace(/\D/g, '')
  let newVal = ''
  // if (val.length === 2) {
  //   newVal += val.substr(0, 2) + '-'
  //   val = val.substr(2)
  // }
  if (val.length > 3)
    value = val
  if (val.length > 2) {
    newVal += val.substr(0, 2) + '-'
    val = val.substr(2)
  }
  newVal += val
  value = newVal
  return newVal
}

export const numbersOnly = (value) => {
  let val = value.replace(/\D/g, '')
  return val
}

export const numberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const roundToTwo = (value) => {
  return  +(Math.round(value + "e+2")  + "e-2")
}

export const asPercent = (value) => {
  return  roundToTwo(value*100)
}

const ipRegex = /ip=[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
// const uaRegex = /(?<=uag=)(.*)(?=colo)/s
const uaRegex = /(?:uag=)(.*)(?=colo)/s

export async function getDeviceInfo() {
  let ip, uag
  try {
    const response = await axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
      ip = data.data.match(ipRegex)[0].slice(3)
      uag = data.data.match(uaRegex)[0]
    })
    return {
      ip: ip,
      uag: uag
    }
  } catch (error) {
    return {
      ip: ip,
      uag: uag
    }
  }
}